import React from 'react';
import styled from 'styled-components';
import { Icon } from '@ffn/ui';
import { Reverse } from '@ffn/icons';
import { css } from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { query } from 'atomic-layout';
import { Currency } from 'shared-components';
import { useAppData, useTranslation } from 'lib/hooks';
import { useLocale } from 'lib/hooks';
import { abbrMonthWithLongYear } from 'lib/utils';

export function Summary() {
  css(``);
  const { t } = useTranslation();
  const { locale } = useLocale();
  const { fetchModels } = useAppData();
  const { suipOpportunity } = useSelector((state) => state.models);
  const data = useSelector((state) => state.userRecord);

  if (!suipOpportunity) {
    return null;
  }

  const name = `${data.firstName} ${data.lastName}`;

  const {
    estimatedTotalDebt,
    estimatedCosttoClient,
    estimatedProgramLength,
    draftAmount,
    estimatedProgramEndDate
  } = suipOpportunity;

  const gradDateForDisplay = estimatedProgramEndDate
    ? abbrMonthWithLongYear(new Date(estimatedProgramEndDate))
    : t('onboarding.content.summary.pending');

  const renderProgramSummary = () => {
    if (locale === 'es') {
      return `${t('onboarding.content.summary.programSummary')} ${name}`;
    } else {
      return `${name}${t('onboarding.content.summary.programSummary')}`;
    }
  };

  return (
    <FullWidthPanel primary="#29324a">
      <h1>
        {renderProgramSummary()}{' '}
        <span
          css={`
            :hover {
              cursor: pointer;
            }
          `}
          onClick={() => {
            fetchModels();
          }}
        >
          <Icon icon={Reverse} size={15} />
        </span>
      </h1>
      <div
        css={`
          display: flex;
          flex-direction: column;
          @media ${query({ from: 'md' })} {
            flex-direction: row;
            justify-content: space-between;
          }
        `}
      >
        <SummaryItem
          title={t('onboarding.content.summary.enrolledDebtTotal')}
          value={estimatedTotalDebt}
        />
        <SummaryItem
          title={t('onboarding.content.summary.estTotalProgramCost')}
          value={estimatedCosttoClient}
        />
        <SummaryItem
          title={t('onboarding.content.summary.estProgramLength')}
          value={estimatedProgramLength}
          isCurrency={false}
        />
        <SummaryItem
          title={t('onboarding.content.summary.programDeposit')}
          value={draftAmount}
        />
        <SummaryItem
          title={t('onboarding.content.summary.estGraduationDate')}
          value={gradDateForDisplay}
          isCurrency={false}
        />
      </div>
    </FullWidthPanel>
  );
}

function SummaryItem({ title, value, isCurrency = true }) {
  return (
    <div
      css={`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        @media ${query({ from: 'md' })} {
          flex-direction: column;
          align-items: center;
        }
      `}
    >
      <div>
        <span
          css={`
            font-weight: 400;
            @media ${query({ from: 'md' })} {
              font-size: 0.8em;
            }
          `}
        >
          {title}
        </span>
      </div>
      <div
        css={`
          font-weight: 600;
          font-size: 1.4em;
          @media ${query({ from: 'md' })} {
            font-size: 1.9em;
          }
        `}
      >
        {isCurrency ? (
          <Currency value={value} decimalScale={0} />
        ) : (
          <span>{value}</span>
        )}
      </div>
    </div>
  );
}

const FullWidthPanel = styled.section`
  ${({ theme }) =>
    `background: linear-gradient(0deg, ${theme.colors.secondaryNavy} 0%, ${theme.colors.secondaryBlue} 100%)`};
  color: white;
  padding: 1rem;
`;
