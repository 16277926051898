import axios from 'axios';

import envConfig from 'envConfig';

export const leadDocOobCodeValidate = async (oobCode) => {
  const apiUrl = `${envConfig.FIREBASE_FUNCTION_HOSTNAME}/leadDocUploadValidate`;
  try {
    const response = await axios.get(`${apiUrl}?code=${oobCode}`, {
      headers: {
        'Content-type': 'application/json'
      },
      validateStatus: (status) => status === 200
    });

    if (response.status !== 200) {
      const message = 'LEAD_DOC_VALIDATION_ERROR';
      throw new Error(message);
    }

    return await response.data;
  } catch (error) {
    throw error;
  }
};

export default leadDocOobCodeValidate;
