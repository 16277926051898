import { format } from 'date-fns/fp';
import { ChevronThinRight } from '@styled-icons/entypo/ChevronThinRight';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'lib/hooks';
import { Button, Icon, Text } from '@ffn/ui';
import { Box } from 'atomic-layout';

// used as either an option in a dropdown or as a card in...
// packages/web/src/shared-pages/dashboard/CustomerService/components/OnlineServices/DocumentUpload/index.js
// packages/web/src/shared-pages/dashboard/CustomerService/components/OnlineServices/DocumentUpload/shared-components/DropDownOptions.js
// packages/web/src/shared-pages/dashboard/DocumentVault/components/DropDownOptions.js

export const UploadOptions = ({ card } = { card: false }) => {
  const { t } = useTranslation();
  const uploadOptionsArray = [
    {
      title: t('docUpload.accountStatementS'),
      description: t('docUpload.billOrStatementFromACreditor'),
      path: '/customer-service/document-upload/account-statements',
      testId: 'account-statements'
    },
    {
      title: t('docUpload.pastDueNoticeS'),
      description: t('docUpload.notificationAboutMissedPayments'),
      path: '/customer-service/document-upload/past-due-notices',
      testId: 'past-due-notices'
    },
    {
      title: t('docUpload.collectionLetterS'),
      description: t('docUpload.documentFromA_3rdPartyCollector'),
      path: '/customer-service/document-upload/collection-letters',
      testId: 'collection-letters'
    },
    {
      title: t('docUpload.legalDocumentS'),
      description: t('docUpload.documentsRegardingLawsuits'),
      path: '/customer-service/document-upload/legal-documents',
      testId: 'legal-documents'
    },
    {
      title: t('docUpload.miscellaneousDocumentS'),
      description: t('docUpload.anyTypeOfDocumentNotListedAbove'),
      path: '/customer-service/document-upload/miscellaneous-documents',
      testId: 'miscellaneous-documents'
    }
  ];
  let uploadOptionsList = [];
  if (card) {
    uploadOptionsList = uploadOptionsArray.map(
      ({ title, description, path, testId }) => (
        <Box
          flex
          flexDirection="column"
          justifyContent="space-around"
          padding="16px 8%"
          key={title}
          style={{ backgroundColor: '#ffffff' }}
        >
          <NavLink to={path} style={{ textDecoration: 'none', width: '100%' }}>
            <Button
              data-testid={`${testId}-upload-option-button`}
              variant="tertiary"
              height={40}
              paddingRight={24}
              width="100%"
              rightEnhancer={<Icon icon={ChevronThinRight} size={14} />}
            >
              {title}
            </Button>
          </NavLink>
          <Text>
            <em>{description}</em>
          </Text>
        </Box>
      )
    );
  } else {
    uploadOptionsList = uploadOptionsArray.map(
      ({ title, path, description }) => (
        <option
          key={title}
          data-title={title}
          data-path={path}
          data-description={description}
        >
          {title}
        </option>
      )
    );
  }
  return uploadOptionsList;
};

const getStringOfUploadedDocs = (files) =>
  files.reduce((acc, curr) => {
    const {
      currentBalance,
      newCreditorName,
      originalPrevCreditor,
      newAccountNumber,
      sameNumberAsOriginal,
      document_type,
      methodOfService,
      dateOfService
    } = curr.metadata;
    const { path } = curr.document;
    const newAccountNumberInfo = sameNumberAsOriginal?.length
      ? '\n----Account number did not change'
      : newAccountNumber
        ? `\n----New Account Number: ${newAccountNumber}`
        : '';
    const newCreditorNameInfo = newCreditorName
      ? `\n----New Creditor Name: ${newCreditorName}`
      : '';
    const originalPrevCreditorInfo = originalPrevCreditor
      ? `\n----Original/Previous Creditor: ${originalPrevCreditor}`
      : '';
    const formattedDate = dateOfService?.toLocaleDateString('en-us', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric'
    });
    const serviceDateInfo =
      methodOfService === 'Mail'
        ? `\n----Date Mail Received: ${formattedDate}`
        : `\n----LIT Service Date: ${formattedDate}`;
    const docString =
      document_type === 'collection_letter'
        ? `${path} - ${document_type}
        ----Current Balance: ${currentBalance}${newCreditorNameInfo}${originalPrevCreditorInfo}${newAccountNumberInfo}`
        : document_type === 'summons_and_complaints'
          ? `${path} - ${document_type}
        ----Method of Service: ${methodOfService}${serviceDateInfo}`
          : `${path} - ${document_type}`;

    acc = acc.length ? `${acc}\n${docString}` : docString;
    return acc;
  }, '');

export const craftEmailMessage = ({
  doctype,
  fullName,
  email,
  clientId,
  values
}) => {
  const { files, notes } = values;

  const docListText = files.reduce(
    (acc, curr) => acc.concat(`\n${curr.document.path}`),
    ''
  );

  const clientSFLink =
    process.env.NODE_ENV !== 'production'
      ? 'https://test.salesforce.com/' + clientId
      : 'https://login.salesforce.com/' + clientId;

  if (doctype === 'legal') {
    return `Time sensitive - Dashboard legal document upload from user: ${fullName}
    (${email})

    Client ID: ${clientId}
    Client Record: ${clientSFLink.trim()}

    Document(s) Uploaded:
    ${getStringOfUploadedDocs(files)}

    Client Comments: ${notes}

    Note: Required Action. Please review the uploaded legal documents in this client’s document widget to ensure the correct legal document type has been assigned. Also, take action on any time sensitive documents and follow up with client if needed.`;
  }
  if (doctype === 'collection_letter') {
    return `Dashboard collection letter document upload from user: ${fullName}
    (${email})

    Client ID: ${clientId}
    Client Record: ${clientSFLink.trim()}

    Document(s) Uploaded:
    ${getStringOfUploadedDocs(files)}

    Client Comments: ${notes}

    Note: Required Action. Please review the uploaded collection letter(s) in this client’s document widget to ensure the correct creditor has been assigned or needs update to new creditor.`;
  }

  if (doctype === 'enroll_new_debt') {
    const {
      creditorName,
      currentBalance,
      accountNumber,
      lastStatementDate,
      accountType,
      lastPaymentDate
    } = values;

    return `Dashboard "Enroll New Debt" request from user: ${fullName} ${email} \n

    Creditor Name: ${creditorName}
    Current Balance: ${currentBalance}
    Account Number: ${accountNumber}
    Last Statement Date: ${format('yyyy-MM-dd', lastStatementDate)}
    Account Type: ${accountType}
    Last Payment Date: ${format('yyyy-MM-dd', lastPaymentDate)}
    Document(s) Uploaded:${docListText}
  
    Note: Required action. Please review the uploaded documents in this client’s document widget and set their respective document types.`;
  }

  return `Dashboard document upload from user: ${fullName} ${email}

  Client ID: ${clientId}
  Client Record: ${clientSFLink.trim()}

  Document(s) Uploaded:${docListText}

  Client Comments: ${notes}

  Note: Required Action. Please review the uploaded documents in this client’s document widget and set their respective document types.`;
};
