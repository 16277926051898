import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth0Vars } from './utils';

export function Auth0ProviderWithNavigation({ children, subdomain }) {
  const { domain, clientId, redirectUri, audience } = getAuth0Vars(subdomain);
  const navigate = useNavigate();

  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  if (!domain || !clientId || !redirectUri) {
    throw new Error('Missing Auth0 environment variables');
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      audience={audience}
      scope="openid profile email name"
      redirectUri={redirectUri}
      useRefreshTokens={true}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
}
