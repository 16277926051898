import { createSlice } from '@reduxjs/toolkit';
import { ptsDataLoaded, rootReset } from '../store/actions';

const initialState = {};

const modelsSlice = createSlice({
  name: 'fdrModels',
  initialState,
  reducers: {
    updateModels: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },
    messageActioned: (state, action) => {
      const { messageId } = action?.payload;
      state.notifications.messages.forEach((message) => {
        if (message.id === messageId) {
          message.isActioned = true;
        }
      });
    },
    messageRead: (state, action) => {
      const { messageId } = action?.payload;
      const startingMessagesCount = state.notifications.messages.length;

      // Update is read value for message
      state.notifications.messages.forEach((message) => {
        if (message.id === messageId) {
          message.isRead = true;
        }
      });

      if (state.notifications.readMessages?.length > 0) {
        if (state.notifications.readMessages.indexOf(messageId) === -1) {
          state.notifications.readMessages.push(messageId);
        }
      } else {
        state.notifications.readMessages = [messageId];
      }

      state.notifications.totalMessages = startingMessagesCount - 1;
    },
    messageTrashed: (state, action) => {
      const { messageId } = action?.payload;
      const updatedMessages = [];
      const startingMessagesCount = state.notifications.messages.length;

      // Construct a new array of valid messages so we can remove the 'deleted' message
      state.notifications.messages.forEach((message) => {
        if (message.id !== messageId) {
          updatedMessages.push(message);
        }
      });

      state.notifications.messages = updatedMessages;

      if (state.notifications.trashedMessages?.length > 0) {
        if (state.notifications.trashedMessages.indexOf(messageId) === -1) {
          state.notifications.trashedMessages.push(messageId);
        }
      } else {
        state.notifications.trashedMessages = [messageId];
      }

      state.notifications.totalMessages = startingMessagesCount - 1;
    },
    resetModels: () => {
      return { ...initialState };
    }
  },
  extraReducers: {
    [rootReset]: () => {
      return { ...initialState };
    },
    [ptsDataLoaded]: (state, action) => {
      return { ...state, pts: action.payload };
    }
  }
});

export const {
  updateModels,
  messageActioned,
  messageRead,
  messageTrashed,
  resetModels
} = modelsSlice.actions;

export default modelsSlice.reducer;
