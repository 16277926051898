import React, { Fragment } from 'react';
import styled, { css } from 'styled-components/macro';
import { List, ListItem } from '@ffn/ui';
import { useBrand } from 'lib/hooks';
import { translateAbbrDateRange } from 'lib/utils';
import { useTranslation } from 'lib/hooks';

const LeftColumn = styled.span`
  display: inline-block;
  width: 40%;
  font-weight: 100;
  font-size: 12px;
  vertical-align: top;
`;

const RightColumn = styled.span`
  font-weight: 600;
  font-size: 12px;
  vertical-align: top;
  width: 60%;
`;

export function CustomerServiceHours() {
  const { t } = useTranslation();
  const brand = useBrand();
  const phoneHours = brand('contact.customer-service.phone-hours');
  css(``); // Disables warning about css import not being used when it is below...
  return (
    <div
      css={`
        display: inline-block;
        vertical-align: top;
        width: 90%;
      `}
    >
      <List
        noBullets
        css={`
          margin-top: 0;
          margin-left: 10px;
        `}
      >
        <ListItem cols={4}>
          {phoneHours.map((item) => {
            return (
              <Fragment key={`${item.dateRange}_${item.hours}`}>
                <LeftColumn>
                  {translateAbbrDateRange(item.dateRange)}
                </LeftColumn>
                <RightColumn>
                  {item.hours === 'Closed' || item.hours === 'closed'
                    ? t('customerService.closed')
                    : item.hours}
                </RightColumn>
              </Fragment>
            );
          })}
        </ListItem>
      </List>
    </div>
  );
}

export default CustomerServiceHours;
