import { Box } from '@achieve/sunbeam';
import { getAppSubDomain } from 'shared-components';

import AppStoreBadge from '../../shared-components/RailSidebar/AppPromoSidebar/assets/apple_app_store_badge.png';
import PlayStoreBadge from '../../shared-components/RailSidebar/AppPromoSidebar/assets/google_play_store_badge.png';

const APP_STORE_BADGE_MAP = {
  iOS: AppStoreBadge,
  Android: PlayStoreBadge,
  unknown: null
};

const APP_DOWNLOAD_URL_MAP = {
  fdr: {
    iOS: 'https://apps.apple.com/us/app/freedom-debt-relief/id6576817794',
    Android:
      'https://play.google.com/store/apps/details?id=com.fdrmobiledashboard'
  },
  turnbull: {
    iOS: 'https://apps.apple.com/us/app/turnbull-law-group/id6742853206',
    Android:
      'https://play.google.com/store/apps/details?id=com.turnbulldashboard'
  }
};

/**
 * Renders an App Store or Google Play badge button based on device type
 * @param {Function} analyticsCallback - Function called when button is clicked
 * @param {string} deviceType - 'iOS', 'Android', or 'unknown'
 * @param {string} promoType - Type of promotion (default: 'modal')
 */
export const AppStoreBadgeButton = ({
  analyticsCallback,
  deviceType,
  promoType = 'modal'
}) => {
  const badgeSrc = APP_STORE_BADGE_MAP[deviceType];
  const brand = getAppSubDomain();

  // Handle fallback for unsupported brand or device type
  const downloadUrl =
    brand && deviceType && APP_DOWNLOAD_URL_MAP[brand]
      ? APP_DOWNLOAD_URL_MAP[brand][deviceType] || ''
      : '';

  const testId = `app-promo-badge-${deviceType}-${promoType}`;

  if (!badgeSrc) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'end',
        width: '167px'
      }}
    >
      <Box
        as="button"
        aria-label={
          deviceType === 'iOS'
            ? 'Download on the App Store'
            : 'Get it on Google Play'
        }
        data-testid={testId}
        onClick={() => {
          analyticsCallback('download');
          window.open(downloadUrl, '_blank');
        }}
        sx={{
          padding: 0,
          border: 'none',
          background: 'none',
          width: '100%',
          cursor: 'pointer'
        }}
      >
        <Box as="img" width="100%" src={badgeSrc} alt="" aria-hidden="true" />
      </Box>
    </Box>
  );
};
