import { FDRRoutes } from './fdr';
import { TLGRoutes } from './tlg';
import { AchieveRoutes } from './achieve';

// Set the brand-specific routes based on the subdomain
const getRoutes = (subDomain) => {
  switch (subDomain) {
    case 'achieve':
      return <AchieveRoutes />;
    case 'fdr':
      return <FDRRoutes />;
    case 'turnbull':
      return <TLGRoutes />;
    default:
      return null;
  }
};

const BrandSpecificRoutes = ({ subDomain }) => getRoutes(subDomain);

export { FDRRoutes };

export default BrandSpecificRoutes;
