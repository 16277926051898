import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Icon } from '@ffn/ui';
import { Link as RouterLink } from 'react-router-dom';

import { getAlertRibbonNotifications } from 'shared-selectors/alerts';
import { analyticsTrackEvent } from 'lib/utils/analytics';
import { useTranslation } from 'lib/hooks';
import { AlertTriangle, ChevronUp, ChevronDown } from 'react-feather';

// All alert titles and names need to be included in alerts.json and the key should
// be the camelized version of the entire string

const AlertRibbonContainer = styled.section`
  background-color: ${(props) => props.theme.colors.secondaryRed};
  color: white;
`;

const ContentContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

const AlertRibbonHeader = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: ${(props) =>
    props.isOpen === true ? `1px solid white` : 'none'};
  span {
    font-weight: bold;
    font-size: 16px;
    margin-left: 24px;
  }
`;

const AlertLink = styled(RouterLink)`
  color: white;
  font-weight: bold;
  text-decoration: none;
  &:hover {
    opacity: 0.7;
  }
`;

const AlertListItem = styled.li`
  padding: 6px 0;
  &:hover {
    &:before {
      content: ' ';
      background-color: #80191e;
    }
    background-color: #80191e;
  }
`;

export const AlertRibbon = () => {
  const [isOpen, setIsOpen] = useState(true);

  const notificationsForAlertRibbon = useSelector(getAlertRibbonNotifications);
  const { tCount, alertsTitleNameTranslator } = useTranslation();

  const totalAlerts = notificationsForAlertRibbon?.length;

  // This logic will likely need to be changed in the future, probably with a shared selector
  // to determine which alerts we want to render in the alert ribbon.
  if (totalAlerts > 0) {
    return (
      <AlertRibbonContainer data-testid="alert-ribbon-container">
        <ContentContainer data-testid="alert-ribbon-content">
          <AlertRibbonHeader
            isOpen={isOpen}
            onClick={isOpen ? () => setIsOpen(false) : () => setIsOpen(true)}
            data-testid="alert-ribbon-header"
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Icon icon={AlertTriangle} size={30} />
              <span>
                {/* need to make plural work here */}
                {totalAlerts} {tCount('alerts.alert', totalAlerts)}
              </span>{' '}
            </div>
            <Icon
              icon={isOpen ? ChevronDown : ChevronUp}
              size={30}
              onClick={isOpen ? () => setIsOpen(false) : () => setIsOpen(true)}
              data-testid="alert-ribbon-arrow"
            />
          </AlertRibbonHeader>
          {isOpen ? (
            <ul>
              {notificationsForAlertRibbon.map((notification) => {
                return (
                  <AlertLink
                    to={notification.link}
                    key={notification.id}
                    onClick={({ target: { innerText } }) => {
                      analyticsTrackEvent(
                        {
                          category: 'alert',
                          action: 'action_ribbon',
                          label: notification.trackingLabel,
                          innerText
                        },
                        'Alert Ribbon Notification Clicked'
                      );
                    }}
                  >
                    <AlertListItem data-testid="alert-ribbon-list-item">
                      {alertsTitleNameTranslator(notification.title, 'title')}
                    </AlertListItem>
                  </AlertLink>
                );
              })}
            </ul>
          ) : null}
        </ContentContainer>
      </AlertRibbonContainer>
    );
  } else {
    return null;
  }
};
