import { axiosInstance as axiosWithToken } from 'lib/axios';
import axiosWithoutToken from 'axios';

import getApiUrl from 'lib/utils/getApiUrl';

export const getPageContent = async (config) => {
  const uri = _buildApiURI();
  let url = `${uri}`;
  const axios = config.useOpenEntry ? axiosWithoutToken : axiosWithToken;

  if (config.useOpenEntry) {
    url += '/open-entry';
    delete config.useOpenEntry;
  } else {
    url += '/entry';
  }
  let stateSpecificTag = null;
  if (config.stateSpecificTag) {
    stateSpecificTag = config.stateSpecificTag;
    config.stateSpecificTag = undefined;
  }
  const params = _buildApiParams(config);
  const { data } = await axios.get(`${url}`, {
    params
  });

  if (!data) return null;
  const { items } = data;
  // although items should always be an array, just in case we check for it
  let item = null;
  if (Array.isArray(items)) {
    // validate the page queried for was returned
    if (stateSpecificTag) {
      item = items.find((item) => {
        const hasPageUrl = item.fields?.pageUrl === config.pageUrl;
        const tags = item.metadata?.tags.map((tag) => tag?.sys?.id);
        if (hasPageUrl && tags.includes(stateSpecificTag)) {
          return item;
        }
        return null;
      });
      if (item === undefined) {
        item = items.find((item) => item.fields?.pageUrl === config.pageUrl);
      }
    } else {
      item = items.find((item) => item.fields?.pageUrl === config.pageUrl);
    }
    return item ? item : null;
  }
  return null;
};

export const findContent = async (config) => {
  const uri = _buildApiURI();
  const params = _buildApiParams(config);
  const axios = axiosWithToken;

  const { data } = await axios.get(`${uri}/entry`, {
    params
  });
  if (!data) return [];
  const { items } = data;
  if (Array.isArray(items)) {
    return items;
  }
  return [];
};

export const getRandomContent = async (config) => {
  const uri = _buildApiURI();
  const params = _buildApiParams(config);
  const axios = axiosWithToken;

  const { data } = await axios.get(`${uri}/random-entry`, {
    params
  });
  return data;
};

export const getPermalinkMedia = async (config) => {
  const uri = _buildApiURI();
  const params = _buildApiParams(config);
  const axios = axiosWithoutToken;

  const { data } = await axios.get(`${uri}/permalink-media`, {
    params
  });
  if (!data) return null;
  const { url } = data;
  return url ? url : null;
};

/**
 * This passes in an any other object parameters to the API that are not destructured
 * The attempt is here is to replicate Contentful getEntries method.
 * When querying for Entries and involving fields you need to limit your query to a specific Content Type (content_type)
 * @param {String} pageUrl - the pageUrl field of the content
 * @param {String} brand - technically a tag, gets added to tagsIn
 * @param {Array} tagsIn - array of tags to filter by
 * @param {Integer} include - number of levels in hierarchy to retrieve, default to 5
 */
const _buildApiParams = ({
  pageUrl,
  brand,
  tagsIn = [],
  tagsAll = [],
  include = 5,
  ...query
}) => {
  // setup params that always exist
  const params = {
    include,
    ...query
  };

  // tagsIn
  if (tagsIn && !Array.isArray(tagsIn)) {
    throw new Error('tagsIn must be an array');
  }

  const tagsInArray = [...tagsIn];
  if (brand) tagsInArray.push(brand);
  if (tagsInArray.length) {
    params.tagsIn = tagsInArray.join(',');
  }

  // tagsAll
  if (tagsAll && !Array.isArray(tagsAll)) {
    throw new Error('tagsAll must be an array');
  }

  const tagsAllArray = [...tagsAll];
  if (tagsAllArray.length) {
    if (brand) tagsAllArray.push(brand);
    params.tagsAll = tagsAllArray.join(',');
  }

  // pageUrl
  if (pageUrl) {
    params['fields.pageUrl'] = pageUrl;
  }

  return params;
};

export const _buildApiURI = () => {
  const apiUrl = getApiUrl();
  return `${apiUrl}/content`;
};
