import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronThinLeft } from '@styled-icons/entypo/ChevronThinLeft';
import { Box } from 'atomic-layout';
import { Icon } from '@ffn/ui';
import 'styled-components/macro';

export function Heading({ title, resetDocumentVault }) {
  const navigate = useNavigate();

  const handleClick = () => {
    if (resetDocumentVault) {
      resetDocumentVault();
    } else navigate('/customer-service/document-upload');
  };

  return (
    <Box flex alignItems="center">
      <div
        onClick={handleClick}
        css={`
          color: ${({ theme }) => theme.colors.primaryGray};
          display: flex;
        `}
      >
        <Icon icon={ChevronThinLeft} size={16} />
      </div>
      <Box marginLeft={8}>{title}</Box>
    </Box>
  );
}

export default Heading;
