import { TourStepContent } from './components/TourStepContent';
import sampleSettlementAuthImageDesktop from './Desktop_Sett_Auth_Example.jpg';
import sampleSettlementAuthImageMobile from './Mobile_Sett_Auth_Example.jpg';

/***
 * Controls the order of steps in the tour.
 * The string here must match the key in the STEPS object above,
 * which defines the content and URL for each step.
 */
const STEP_ORDER_SHORT = [
  'depositStatus',
  'firstSettlement',
  'sampleSettlement',
  'navigationMenu'
];
const STEP_ORDER_EXTENDED = [
  'depositStatus',
  'firstDeposit',
  'enrolledDebtNav',
  'enrolledDebtStatus',
  'dedicatedAccountNav',
  'dedicatedAccount',
  'programResourcesNav',
  'programResources',
  'notifications',
  'sampleSettlement',
  'customerServiceNav'
];

/***
 * Defines the content and URL for each step in the tour.
 * {
 *  analyticsKey: 'deposit-status', --> Value used to construct analytics event
 *  url: '/enrolled-debt', --> URL for the correct page where the step resides
 *  step: {  } --> Metadata for the step, as defined by the reactour library (https://github.com/elrumordelaluz/reactour/tree/main/packages/tour#steps-steptype)
 * }
 */
export const STEPS = {
  depositStatus: {
    analyticsKey: 'deposit_status',
    url: '/',
    step: {
      selector: "[data-tour='deposit-schedule']",
      content: (
        <TourStepContent
          heading="Track your deposit schedule"
          body={
            <p>
              Use your <span>Program Progress Bar</span> to track your debt-free
              journey! Check your current Dedicated Account balance and deposit
              schedule to stay on track.
            </p>
          }
        />
      ),
      position: 'bottom'
    }
  },
  firstDeposit: {
    analyticsKey: 'first_deposit',
    url: '/',
    step: {
      selector: "[data-tour='next-deposit-date']",
      content: (
        <TourStepContent
          heading="Get your first deposit on schedule"
          body={
            <p>
              Reach your milestones on schedule by making your first deposit.
              The sooner you make your first deposit, the sooner we can start
              negotiating your first settlement!
            </p>
          }
        />
      ),
      Position: 'top'
    }
  },
  firstSettlement: {
    analyticsKey: 'first_settlement',
    url: '/',
    step: {
      selector: "[data-tour='first-deposit-date']",
      content: (
        <TourStepContent
          heading="Make your first deposit on schedule"
          body={
            <p>
              Reach your milestones on schedule by making your first deposit.
              The sooner you make your first deposit, the sooner we can start
              negotiating your first settlement!
            </p>
          }
        />
      ),
      Position: 'top'
    }
  },
  navigationMenu: {
    analyticsKey: 'navigation_menu',
    url: '/',
    step: {
      selector: "[data-tour='navigation-menu']",
      content: (
        <TourStepContent
          isLastStep // this will require a solution if we want to use this exact step in two different tours
          heading="Explore your dashboard"
          body={
            <p>
              Use the menu to check on the progress of your{' '}
              <span>Enrolled Debt</span>, manage your{' '}
              <span>Dedicated Account</span>, read important{' '}
              <span>Program Resources</span>, and contact{' '}
              <span>Customer Service</span> for help.
            </p>
          }
          /*** This will run when the TourStepContent component is mounted */
          prepareUI={toggleMobileNav}
          /*** This will run when the TourStepContent component is unmounted */
          cleanupUI={toggleMobileNav}
        />
      ),
      Position: 'bottom'
    }
  },
  enrolledDebtNav: {
    analyticsKey: 'enrolled_debt_nav',
    url: '/',
    step: {
      selector: "[data-tour='enrolled-debt-nav']",
      content: (
        <TourStepContent
          heading="Let's go to your enrolled debt"
          body={
            <p>
              Your <span>Enrolled Debt</span> page is where you can check the
              status and details of your enrolled debt.
            </p>
          }
          /*** This will run when the TourStepContent component is mounted */
          prepareUI={toggleMobileNav}
          /*** This will run when the TourStepContent component is unmounted */
          cleanupUI={toggleMobileNav}
        />
      ),
      position: 'bottom'
    }
  },
  enrolledDebtStatus: {
    analyticsKey: 'enrolled_debt_status',
    url: '/enrolled-debt',
    step: {
      selector: "[data-tour='static-tour-target']",
      content: (
        <TourStepContent
          heading="Check the status of your enrolled debt"
          body={
            <p>
              On <span>Enrolled Debt</span>, check the negotiation and
              settlement status for your enrolled accounts. It&apos;s
              recommended to voluntarily stop paying creditors and using these
              cards, so you can succeed in your program.
            </p>
          }
        />
      ),
      highlightedSelectors: ["[data-tour='enrolled-debt-status']"],
      mutationObservables: ["[data-tour='enrolled-debt-status']"],
      position: 'bottom'
    }
  },
  dedicatedAccountNav: {
    analyticsKey: 'dedicated_account_nav',
    url: '/enrolled-debt',
    step: {
      selector: "[data-tour='dedicated-account-nav']",
      content: (
        <TourStepContent
          heading="Let's review your dedicated account"
          body={
            <p>
              Your <span>Dedicated Account</span> holds the deposits that are
              automatically withdrawn from your bank account.
            </p>
          }
          /*** This will run when the TourStepContent component is mounted */
          prepareUI={toggleMobileNav}
          /*** This will run when the TourStepContent component is unmounted */
          cleanupUI={toggleMobileNav}
        />
      ),
      position: 'bottom'
    }
  },
  dedicatedAccount: {
    analyticsKey: 'dedicated_account',
    url: '/dedicated-account',
    step: {
      selector: "[data-tour='static-tour-target']",
      content: (
        <TourStepContent
          heading="Build up your dedicated account"
          body={
            <p>
              Once enough funds have built up in your{' '}
              <span>Dedicated Account</span>, our expert negotiators contact
              your creditors to reduce what you owe through settlements we
              negotiate.
            </p>
          }
        />
      ),
      highlightedSelectors: ["[data-tour='dedicated-account']"],
      mutationObservables: ["[data-tour='dedicated-account']"],
      position: 'bottom'
    }
  },
  programResourcesNav: {
    analyticsKey: 'program_resources_nav',
    url: '/dedicated-account',
    step: {
      selector: "[data-tour='program-resources-nav']",
      content: (
        <TourStepContent
          heading="Let's explore Program Resources"
          body={
            <p>
              As you grow your Dedicated Account funds, you can explore{' '}
              <span>Program Resources</span> for guidance and support on your
              journey.
            </p>
          }
          /*** This will run when the TourStepContent component is mounted */
          prepareUI={toggleMobileNav}
          /*** This will run when the TourStepContent component is unmounted */
          cleanupUI={toggleMobileNav}
        />
      ),
      position: 'bottom'
    }
  },
  programResources: {
    analyticsKey: 'program_resources',
    url: '/program-resources',
    step: {
      selector: "[data-tour='static-tour-target']",
      content: (
        <TourStepContent
          heading="Learn about your program from experts"
          body={
            <p>
              Get tips and guides on how your program works, how to handle
              creditor calls, and helpful FAQs.
            </p>
          }
        />
      ),
      highlightedSelectors: ["[data-tour='program-resources']"],
      mutationObservables: ["[data-tour='program-resources']"],
      position: 'bottom'
    }
  },
  notifications: {
    analyticsKey: 'notifications',
    url: '/program-resources',
    step: {
      selector: "[data-tour='static-tour-target']",
      content: (
        <TourStepContent
          heading="Check your notifications"
          body={
            <p>
              After we negotiate with your creditors, you&apos;ll receive{' '}
              <span>Notifications</span> for settlements.
            </p>
          }
          /*** This will run when the TourStepContent component is mounted */
          prepareUI={() => {
            const node = document.getElementById(
              'tour-trigger-profile-dropdown'
            );
            const mobileNode = document.getElementById(
              'tour-trigger-profile-mobile'
            );

            if (node) {
              // Triggering the mouseenter event will open the dropdown on desktop
              node.firstElementChild.dispatchEvent(
                new Event('mouseenter', { bubbles: true, composed: true })
              );

              // Triggering the focus event will open the dropdown on mobile, in some cases
              node.firstElementChild.focus();
            }

            if (mobileNode) {
              // Triggering the click event will toggle the dropdown appearance
              mobileNode.parentElement.click();
            }
          }}
          /*** This will run when the TourStepContent component is unmounted */
          cleanupUI={() => {
            const node = document.getElementById(
              'tour-trigger-profile-dropdown'
            );

            if (node) {
              // Triggering the mouseleave event will close the dropdown on desktop
              node.firstElementChild.dispatchEvent(
                new Event('mouseleave', { bubbles: true, composed: true })
              );
              // Triggering the blur event will close the dropdown on mobile, in some cases
              node.firstElementChild.blur();
              // This is required to close the dropdown when emulating mobile in Chrome
              node.parentElement.dispatchEvent(
                new Event('click', {
                  bubbles: true,
                  composed: true
                })
              );
            }
            /***
             * No need for the mobileNode business here because a click outside the dropdown
             * will hide/dismiss it. This is the default behavior of the dropdown.
             */
          }}
        />
      ),
      highlightedSelectors: ["[data-tour='notifications']"],
      mutationObservables: ["[data-tour='notifications']"],
      position: 'bottom'
    }
  },
  sampleSettlement: {
    analyticsKey: 'sample_settlement',
    url: '/notifications',
    step: {
      selector: '#sample-settlement-target',
      content: (
        <TourStepContent
          heading="Authorize settlements ASAP"
          body={
            <p>
              After you authorize, payments are automatically sent to your
              creditors to resolve your debt. Don&apos;t worry, your deposit
              schedule and amount is fixed so there&apos;s no extra costs to
              settle. <span>NOTE: This is a sample authorization only.</span>
            </p>
          }
          prepareUI={() => {
            const node = document.getElementById('sample-settlement-target');

            // Appending a sample settlement authorization image to the DOM
            // This doesn't exist in the app, it's just for the tour
            if (node) {
              const div = document.createElement('div');
              div.id = 'sample-settlement';
              // node.style.zIndex = 3;
              const img = document.createElement('img');
              if (window.innerWidth > 768) {
                img.src = sampleSettlementAuthImageDesktop;
              } else {
                img.src = sampleSettlementAuthImageMobile;
              }

              img.setAttribute('data-testid', 'product-tour-sample-settlement');
              img.setAttribute('style', 'height: 95vh');
              img.setAttribute(
                'alt',
                'Sample Settlement Authorization screenshot'
              );
              if (window.innerWidth < 768) {
                // Override the base styles on the container to make this
                // image full width on mobile
                img.setAttribute('style', 'width: 100%; height: auto;');
                node.style.right = 0;
                node.style.top = 0;
              }
              div.appendChild(img);
              node.appendChild(div);
            }
          }}
          /*** This will run when the TourStepContent component is unmounted */
          cleanupUI={() => {
            // Remove the sample settlement authorization image from the DOM
            const node = document.getElementById('sample-settlement');
            if (node) {
              node.remove();
            }
          }}
        />
      ),
      mutationObservables: ["[data-tour='sample-settlement']"],
      position: 'bottom'
    }
  },
  customerServiceNav: {
    analyticsKey: 'customer_service_nav',
    url: '/',
    step: {
      selector: "[data-tour='customer-service-nav']",
      content: (
        <TourStepContent
          isLastStep
          heading="We're here to support you!"
          body={
            <p>
              Finally, keep us up-to-date on any changes to your contact info or
              your accounts. Our <span>Customer Service</span> team is always
              here to support you along your debt-free journey!
            </p>
          }
          /*** This will run when the TourStepContent component is mounted */
          prepareUI={toggleMobileNav}
          /*** This will run when the TourStepContent component is unmounted */
          cleanupUI={toggleMobileNav}
        />
      ),
      position: 'bottom'
    }
  }
};

const getTourSteps = ({ isExtended }) => {
  if (isExtended === true) {
    return STEP_ORDER_EXTENDED.map((stepKey) => STEPS[stepKey]);
  } else {
    return STEP_ORDER_SHORT.map((stepKey) => STEPS[stepKey]);
  }
};

export { getTourSteps };

/***
 * This function will open the mobile nav menu in the UI so that we can highlight
 * nav items on mobile. It's called lots of places, thus this function.
 */
function toggleMobileNav() {
  const node = document.getElementById('open-menu');
  if (node) {
    // Triggering the click event will open the menu nav
    // Bubbles true and composed true are needed to trigger the event on the shadow DOM
    node.dispatchEvent(new Event('click', { bubbles: true, composed: true }));
  }
}
