import { Breadcrumbs, BreadcrumbsItem, Container, Link } from '@ffn/ui';
import { Link as RouterLink } from 'react-router-dom';
import { Composition } from 'atomic-layout';
import * as R from 'ramda';
import React from 'react';

import 'styled-components/macro';

export function RightRail({ children, wrapper = React.Fragment, ...props }) {
  const areasMobileAndTabletSidebar = `
  content auto
  sidebar auto
  / 1fr
`;

  const areasDesktopSidebar = `
  content sidebar 1fr 
  / 1fr 300px
`;

  const Wrapper = wrapper;
  return (
    <Composition
      gap={30}
      template={areasMobileAndTabletSidebar}
      templateXl={areasDesktopSidebar}
      css={`
        background: ${({ theme }) => theme.colors.grayLight};
      `}
      {...props}
    >
      {(Areas) => {
        function SidebarInternal(props) {
          return <Areas.Sidebar as={Sidebar} {...props} />;
        }

        return (
          <Wrapper>{children({ ...Areas, Sidebar: SidebarInternal })}</Wrapper>
        );
      }}
    </Composition>
  );
}

export function Sidebar(props) {
  return (
    <Composition
      templateCols="1fr"
      templateColsMd="repeat(2, 1fr)"
      templateColsLg="repeat(3, 1fr)"
      templateColsXl="auto"
      gap={24}
      {...props}
    />
  );
}

const basicPage = `
  title
  pagecontent
`;

export function BasicPage({ title, children, ...props }) {
  return (
    <Composition
      areas={basicPage}
      css={`
        background: ${({ theme }) => theme.colors.grayLight};
        padding-bottom: 40px;
      `}
      {...props}
    >
      {({ Title, Pagecontent }) => (
        <Container>
          <Title marginVertical={24}>{title}</Title>
          <Pagecontent>{children}</Pagecontent>
        </Container>
      )}
    </Composition>
  );
}

const pageWithBreadcrumbs = `
  title
  crumbs
  pagecontent
`;

export function PageWithBreadCrumbs({
  title,
  breadcrumbs,
  children,
  ...props
}) {
  return (
    <Composition
      areas={pageWithBreadcrumbs}
      css={`
        background: ${({ theme }) => theme.colors.grayLight};
        padding-bottom: 40px;
      `}
      {...props}
    >
      {({ Title, Crumbs, Pagecontent }) => (
        <Container>
          <Title marginVertical={24}>{title}</Title>
          <Crumbs>
            <Breadcrumbs>
              {R.init(breadcrumbs).map((crumb, i) => (
                <BreadcrumbsItem key={i}>
                  <Link as={RouterLink} to={crumb.to}>
                    {crumb.label}
                  </Link>
                </BreadcrumbsItem>
              ))}
              {R.last(breadcrumbs) && (
                <BreadcrumbsItem>{R.last(breadcrumbs).label}</BreadcrumbsItem>
              )}
            </Breadcrumbs>
          </Crumbs>
          <Pagecontent>{children}</Pagecontent>
        </Container>
      )}
    </Composition>
  );
}
