import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Tooltip, Button } from '@achieve/sunbeam';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { analyticsTrackEvent } from 'lib/utils/analytics';

const WrappedCopyTo = React.forwardRef(function MyComponent(props, ref) {
  return (
    <CopyToClipboard text={props.code}>
      <Button
        data-testid={props.taskName}
        color="primary"
        size="small"
        code={props.code}
        ref={ref}
        startIcon={<ContentCopyIcon />}
        onClick={() => {
          props.toggleTooltip(true);
          analyticsTrackEvent(
            {
              category: 'edu',
              action: 'task_click',
              label: `client_edu_v1_1${props.taskName}`
            },
            `task clicked`
          );
          setTimeout(() => {
            props.toggleTooltip(false);
          }, 2500);
        }}
        variant="text"
      >
        {props.btnName}
      </Button>
    </CopyToClipboard>
  );
});

function CopyTextPhoneCode(props) {
  const [open, setOpen] = useState(false);
  const toggleTooltip = (bool) => {
    setOpen(bool);
  };
  const taskName = props.btnName?.toLowerCase().replaceAll(' ', '_') || 'copy';
  return (
    <Tooltip open={open} title="Copied" placement="top" enterTouchDelay={0}>
      <div>
        <WrappedCopyTo
          btnName={props.btnName}
          code={props.code}
          taskName={taskName}
          toggleTooltip={toggleTooltip}
        />
      </div>
    </Tooltip>
  );
}
export default CopyTextPhoneCode;
