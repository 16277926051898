/**
 *  This serves as a map between the lawFirmId field on content in Contentful and the lawFirmId from the brand config in Firestore
 * When adding a new law firm, the lawFirmNames map must be updated.
 */
const lawFirmNames = {
  1001: 'mxBrandTurnbullDefault1001',
  '1001-OH': 'mxBrandTurnbullDefault1001Oh',
  1002: 'mxBrandTurnbullSc1002',
  1003: 'mxBrandTurnbullNj1003',
  1059: 'mxBrandFdr1059',
  3001: 'mxBrandAr3001'
};

export function getLawFirmName(lawFirmId) {
  const name = lawFirmNames[lawFirmId];
  if (name) {
    return name;
  }
  return null;
}
