import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import 'styled-components/macro';
import { useSelector } from 'react-redux';
import { Composition, Only, Box } from 'atomic-layout';
import { DashInput, DashSelect } from 'shared-components';
import ReadyToUploadComponent from '../shared-components/ReadyToUploadComponent';
import { AdditionalMetadataFields } from './AdditionalMetadataFields';
import { getDebtsInNegotiation } from 'shared-selectors/enrolled-debt';
import { useTranslation } from 'lib/hooks';

export function MetadataForm({ currentFile, fileIndex }) {
  const { t } = useTranslation();
  const { errors, isValid, isSubmitting, setFieldValue } = useFormikContext();
  const accounts = useSelector(getDebtsInNegotiation);
  const { fdr_sf_debt_account_id, creditor } = currentFile.metadata;
  const fileWasRejected = currentFile.status === 'rejected';
  const fileWasSubmitted = currentFile.status === 'submitted';
  const creditorIsNotListed = creditor === t('docUpload.otherNotListed');
  const originalPrevCreditor =
    currentFile.metadata.originalPrevCreditor ?? null;
  const showReadyToUpload =
    !isSubmitting && !fileWasSubmitted && (isValid || !errors.files[fileIndex]);

  useEffect(() => {
    // store fdr_sf_debt_account_id from selected 'Previous Creditor' option
    if (originalPrevCreditor && creditorIsNotListed) {
      const selector = `select[name='files.${fileIndex}.metadata.originalPrevCreditor']`;
      const { accountId } =
        document.querySelector(selector).selectedOptions[0].dataset;
      setFieldValue(
        `files.${fileIndex}.metadata.fdr_sf_debt_account_id`,
        accountId
      );
      return;
    }
    // reset fdr_sf_debt_account_id if a valid account from 'Creditor Name' was selected and then changed to 'Other (Not Listed)'
    if (
      creditorIsNotListed &&
      !originalPrevCreditor &&
      fdr_sf_debt_account_id
    ) {
      setFieldValue(`files.${fileIndex}.metadata.fdr_sf_debt_account_id`, '');
      return;
    }
    // store fdr_sf_debt_account_id from selected 'Creditor Name' option
    if (creditor) {
      if (creditorIsNotListed) return;
      const selector = `select[name='files.${fileIndex}.metadata.creditor']`;
      const { accountId } =
        document.querySelector(selector).selectedOptions[0].dataset;
      setFieldValue(
        `files.${fileIndex}.metadata.fdr_sf_debt_account_id`,
        accountId
      );
      // clear all additional metadata fields if 'Other (Not Listed)' was originally selected and then changed
      if (originalPrevCreditor) {
        setFieldValue(`files.${fileIndex}.metadata.originalPrevCreditor`, '');
        setFieldValue(`files.${fileIndex}.metadata.newCreditorName`, '');
        setFieldValue(`files.${fileIndex}.metadata.newAccountNumber`, '');
      }
    }
  }, [
    fdr_sf_debt_account_id,
    creditor,
    creditorIsNotListed,
    originalPrevCreditor,
    fileIndex,
    setFieldValue
  ]);
  return (
    <Composition
      templateCols="24px 1fr 24px"
      templateColsSmDown="1fr"
      autoFlowSmDown="row"
      gap={12}
      alignItems="center"
      paddingTopSmDown={8}
      paddingHorizontal={16}
      paddingBottom="16px"
      css={`
        background-color: ${(props) => props.theme.colors.plain};
      `}
    >
      <Only from="md" />
      <Box style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
        <Box>
          <DashSelect
            id={`files.${fileIndex}.metadata.creditor`}
            name={`files.${fileIndex}.metadata.creditor`}
            label={t('docUpload.creditorName')}
            disabled={isSubmitting || fileWasSubmitted}
            style={{ paddingRight: '32px' }}
          >
            {!fdr_sf_debt_account_id && (
              <option>{t('docUpload.selectCreditor')}</option>
            )}
            {accounts.map((obj) => (
              <option key={obj.accountId} data-account-id={obj.accountId}>
                {`${obj.originalCreditor} ${obj.accountNumber}`}
              </option>
            ))}
            <option>{t('docUpload.otherNotListed')}</option>
          </DashSelect>
          {showReadyToUpload && !creditorIsNotListed ? (
            <ReadyToUploadComponent
              fileWasRejected={fileWasRejected}
              from="md"
            />
          ) : null}
        </Box>
        <Box>
          <DashInput
            id={`files.${fileIndex}.metadata.currentBalance`}
            name={`files.${fileIndex}.metadata.currentBalance`}
            label={t('docUpload.currentBalance')}
            disabled={isSubmitting || fileWasSubmitted}
          />
          {showReadyToUpload && !creditorIsNotListed ? (
            <ReadyToUploadComponent fileWasRejected={fileWasRejected} to="md" />
          ) : null}
        </Box>
        {creditorIsNotListed ? (
          <AdditionalMetadataFields
            accounts={accounts}
            creditorIsNotListed={creditorIsNotListed}
            fileIndex={fileIndex}
            originalPrevCreditor={originalPrevCreditor}
            setFieldValue={setFieldValue}
            currentFile={currentFile}
            showReadyToUpload={showReadyToUpload}
            fileWasRejected={fileWasRejected}
            isSubmitting={isSubmitting}
            fileWasSubmitted={fileWasSubmitted}
          />
        ) : null}
      </Box>
      <Only from="md" />
    </Composition>
  );
}
