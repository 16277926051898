import React, { useState } from 'react';
import { Box, Dialog, Divider, Typography } from '@achieve/sunbeam';
import { LoadingButton } from '@ffn/ui';
import { analyticsTrackEvent } from 'lib/utils/analytics';
import { useAuth, useChangePasswordModal, useTranslation } from 'lib/hooks';
import { BrandLogo, getAppSubDomain, logger } from 'shared-components';
import { requestPasswordResetEmail } from 'lib/api';

import styles from './ChangePasswordModal.module.scss';

/**
 * WORKAROUND: Tracks change password analytics events with a safety timeout.
 *
 * NOTE: This is a targeted workaround for an issue in the analytics system where
 * promises occasionally fail to resolve. The root cause appears to be in analytics.js
 * but after extensive debugging, we've implemented this defensive pattern as an
 * immediate solution to prevent the UI flow from hanging.
 *
 * TODO: Investigate and fix the root cause in analytics.js to properly resolve promises
 * and remove this workaround.
 *
 * @param {string} action - The analytics action to track ('clicked', 'success', 'failure')
 * @returns {Promise<object>} - A promise that always resolves, either with analytics result or timeout
 */
const changePasswordAnalyticsEvent = async (action) => {
  try {
    // Set up the analytics call
    const analyticsPromise = analyticsTrackEvent(
      {
        category: 'change_password',
        action: action,
        label: 'mobile_nav_change_password_continue'
      },
      `Change Password ${action.charAt(0).toUpperCase() + action.slice(1)}`
    );

    // Apply a safety timeout to prevent indefinite hangs
    // This ensures UI flows continue even if analytics has issues
    const result = await Promise.race([
      analyticsPromise,
      new Promise((resolve) =>
        setTimeout(() => {
          logger.warn(
            `Analytics safety timeout triggered for 'change_password.${action}' event. ` +
              `This may indicate an issue with analytics promise resolution.`
          );
          resolve({ source: 'timeout' });
        }, 3000)
      )
    ]);

    return result;
  } catch (error) {
    logger.error(`Failed to track change_password.${action} event`, error);
    return { error: true };
  }
};

export const ChangePasswordModal = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { logout } = useAuth();
  const subdomain = getAppSubDomain();
  const { showChangePasswordModal, closeChangePasswordModal } =
    useChangePasswordModal();

  const handleContinue = async () => {
    setLoading(true);

    try {
      // Track click event, with guaranteed promise resolution
      await changePasswordAnalyticsEvent('clicked');

      // Proceed with password reset
      await requestPasswordResetEmail();

      // Track success, with guaranteed promise resolution
      await changePasswordAnalyticsEvent('success');

      if (subdomain === 'achieve') {
        logout('/signin/check-email');
      } else {
        logout('/login/check-email');
      }
    } catch (error) {
      // Track failure
      changePasswordAnalyticsEvent('failure').catch((err) =>
        logger.error('Failed to track failure event', err)
      );

      setError('Password reset failed. Please try again.');
      setLoading(false);
    }
  };

  const { t } = useTranslation();

  return (
    <Box data-testid="change-password-modal" className={styles.modalWrapper}>
      <Dialog open={showChangePasswordModal}>
        <Box className={styles.contentWrapper}>
          <Box className={styles.logo} data-testid="change-password-modal-logo">
            <BrandLogo />
          </Box>
          <Box
            className={styles.modalHeading}
            data-testid="change-password-modal-heading"
          >
            <Typography fontWeight="bold" variant="bodyS40">
              {t('nav.youLlBeSignedOutSoon')}
            </Typography>
          </Box>
          <Box
            className={styles.modalMessage}
            data-testid="change-password-modal-message"
          >
            <Typography variant="bodyS30">
              {t('nav.afterYouChangeYourPassword')}
            </Typography>
          </Box>
          <Divider />
          <Box className={styles.buttonGroupWrapper}>
            {error && (
              <Box
                className={styles.error}
                data-testid="change-password-modal-error"
              >
                <Typography variant="bodyS30">{error}</Typography>
              </Box>
            )}
            <Box className={styles.buttonWrapper}>
              <LoadingButton
                variant="secondary"
                disabled={loading}
                data-testid="change-password-modal-cancel-button"
                onClick={() => {
                  setError(null);
                  closeChangePasswordModal();
                }}
              >
                {t('nav.Cancel')}
              </LoadingButton>
            </Box>
            <Box className={styles.buttonWrapper}>
              <LoadingButton
                isLoading={loading}
                disabled={loading}
                variant="primary"
                data-testid="change-password-modal-continue-button"
                onClick={handleContinue}
              >
                {t('nav.Continue')}
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};

export default ChangePasswordModal;
