import React, { useEffect } from 'react';
import { formatDateSafely, USD } from 'lib/utils';
import { useNotificationTracking } from 'lib/hooks';
import { OneTimeDeposit } from 'shared-pages/dashboard/CustomerService/components/OnlineServices/OneTimeDeposit';
import { ContactQuestions } from 'shared-components';
import {
  ContactQuestionsWrapper,
  CreditorInfo,
  CreditorInfoText,
  Title
} from './AcceleratedSettlementOpportunity.styles';
import { Box } from 'atomic-layout';
import { useTranslation } from 'lib/hooks';

export function AcceleratedSettlementOpportunity({
  notification,
  viewLocation,
  isVisible
}) {
  const { trackingLabel, payload } = notification;
  const {
    originalCreditor,
    accountNumber,
    currentBalance,
    nextMonthDifferential,
    fr0AmountNeeded,
    dueDate
  } = payload;
  const trackNotificationView = useNotificationTracking();
  const { t } = useTranslation();

  useEffect(() => {
    trackNotificationView({
      trackingLabel,
      viewLocation,
      isVisible
    });
  }, [trackingLabel, viewLocation, isVisible, trackNotificationView]);

  return (
    <Box
      paddingVertical={8}
      paddingHorizontal={18}
      style={{
        background: (props) => props.theme.colors.plain
      }}
    >
      <Title data-testid="creditorInfoTitle">
        {originalCreditor}{' '}
        {t('alerts.accelerateSettlementOpportunity.hasInformedUsTheyMayBe')}
      </Title>
      <span>
        {t('alerts.accelerateSettlementOpportunity.toTakeAdvantageOfThis')}{' '}
        {USD.format(nextMonthDifferential)}{' '}
        {t('alerts.accelerateSettlementOpportunity.amountOnYourSettlement')}{' '}
        {USD.format(fr0AmountNeeded)}{' '}
        {t('alerts.accelerateSettlementOpportunity.toYourDedicatedAccountBy')}{' '}
        {formatDateSafely('MM/dd/yy')(dueDate)}.
      </span>
      <CreditorInfo data-testid="creditorInfo">
        <CreditorInfoText>
          <span>
            {t('alerts.accelerateSettlementOpportunity.originalCreditor')}
          </span>
          <span>{originalCreditor}</span>
        </CreditorInfoText>
        <CreditorInfoText>
          <span>
            {t('alerts.accelerateSettlementOpportunity.accountNumber')}
          </span>
          <span>*****-****-{accountNumber}</span>
        </CreditorInfoText>
        <CreditorInfoText>
          <span>
            {t('alerts.accelerateSettlementOpportunity.currentBalance')}
          </span>
          <span>{USD.format(currentBalance)}</span>
        </CreditorInfoText>
        <CreditorInfoText>
          <span>
            {t(
              'alerts.accelerateSettlementOpportunity.additionalDepositAmount'
            )}
          </span>
          <span>{USD.format(fr0AmountNeeded)}</span>
        </CreditorInfoText>
      </CreditorInfo>
      <OneTimeDeposit
        close={false}
        dmfReason={'Accelerated Settlement Opportunity'}
        dueDate={dueDate}
        minimumDepositAmount={fr0AmountNeeded}
      />
      <span>
        {t('alerts.accelerateSettlementOpportunity.thisIsnTAFirmOffer')}
      </span>
      <span>
        {t(
          'alerts.accelerateSettlementOpportunity.ifYouReUnableToAddAdditional'
        )}
      </span>
      <ContactQuestionsWrapper>
        <ContactQuestions />
      </ContactQuestionsWrapper>
    </Box>
  );
}
