import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { Tooltip, Link, Icon } from '@ffn/ui';
import { FeatureFlag } from 'shared-components/FeatureFlag';
import { useTranslation } from 'lib/hooks';
import { Info } from 'react-feather';
export function PhaseTooltip({ tipKey }) {
  const { t } = useTranslation();

  const phase = useMemo(() => {
    return {
      Upcoming: {
        text: t('tooltip.upcoming'),
        link: '/enrolled-debt/negotiation-overview/upcoming'
      },
      Current: {
        text: t('tooltip.current'),
        link: '/enrolled-debt/negotiation-overview/current'
      },
      'In Process': {
        text: t('tooltip.inProcess'),
        link: '/enrolled-debt/negotiation-overview/in-process'
      },
      'In Progress': {
        text: t('tooltip.inProgress'),
        link: '/enrolled-debt/negotiation-overview/in-process'
      },
      Resolved: {
        text: t('tooltip.resolved'),
        link: '/enrolled-debt/negotiation-overview/resolved'
      }
    };
  }, [t]);

  if (!phase[tipKey]) {
    return null;
  }
  return (
    <>
      <Icon
        icon={Info}
        color="primaryBlue"
        size="15"
        data-tip
        data-for={tipKey}
        onClick={(ev) => {
          ev.stopPropagation();
        }}
      />
      <Tooltip id={tipKey} place="bottom" delayHide={3000}>
        {phase[tipKey].text}
        <FeatureFlag pathKey="/program-resources/negotiation-overview/*">
          {' '}
          {t('tooltip.see')}{' '}
          <Link as={NavLink} to={phase[tipKey].link}>
            {t('tooltip.here')}
          </Link>
          .
        </FeatureFlag>
      </Tooltip>
    </>
  );
}
