import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Row, Column } from '@ffn/ui';
import { ScheduleCallForm } from './ScheduleCallForm';
import { SuccessMessage } from './ScheduleSuccessMessage';
import { useNotificationTracking } from 'lib/hooks';
import { useTranslation } from 'lib/hooks';

const Message = styled.p`
  font-weight: bold;
`;

const ContentBox = styled.div`
  padding: 16px;
  background-color: white;
`;

const FormContainer = styled.div`
  margin-top: 16px;
`;

const PlainBox = styled.div`
  background-color: ${(props) => props.theme.colors.plain};
  padding: ${(props) => (props.isResolved ? '0px' : '16px')};
  padding-top: 0;
  border: ${(props) =>
    props.isResolved && !props.isFromAlertBox
      ? `1px solid ${props.theme.colors.secondaryGreen}`
      : 'none'};
`;

export function MilestoneCallAlert({
  notification,
  setIsResolved,
  isResolved,
  isFromAlertBox,
  viewLocation,
  isVisible
}) {
  const { t } = useTranslation();
  const trackNotificationView = useNotificationTracking();
  const [isSuccess, setIsSuccess] = useState(false);
  const [scheduledTime, setScheduledTime] = useState(null);
  const { payload, trackingLabel } = notification;
  const { availableTimes, alertId } = payload;

  useEffect(() => {
    trackNotificationView({
      trackingLabel: trackingLabel,
      viewLocation,
      isVisible
    });
  }, [viewLocation, isVisible, trackNotificationView, trackingLabel]);

  const successCallback = (dateTimeObj) => {
    if (setIsResolved && typeof setIsResolved === 'function') {
      setIsResolved(true);
      setScheduledTime(dateTimeObj);
    }

    setIsSuccess(true);
  };

  return (
    <PlainBox
      isFromAlertBox={isFromAlertBox}
      isResolved={isResolved || isSuccess}
    >
      {isSuccess ? (
        <SuccessMessage
          isFromAlertBox={isFromAlertBox}
          scheduledTime={scheduledTime}
        />
      ) : (
        <>
          <Message>
            {t('alerts.milestoneCalls.donTForgetTheNextStepToEnsuring')}
          </Message>
          <Message>
            {t('alerts.milestoneCalls.inThis_10MinuteCallYouLlGet')}
          </Message>
          <ContentBox>
            <FormContainer>
              <Row>
                <Column cols={12} colsMd={6}>
                  <ScheduleCallForm
                    availableTimes={availableTimes}
                    alertId={alertId}
                    successCallback={successCallback}
                    trackingLabel={trackingLabel}
                  />
                </Column>
              </Row>
            </FormContainer>
          </ContentBox>
        </>
      )}
    </PlainBox>
  );
}
