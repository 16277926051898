import { css } from 'styled-components/macro';
import { useSelector } from 'react-redux';
import React, { useState } from 'react';
import { Formik } from 'formik';

import { ChevronThinRight } from '@styled-icons/entypo/ChevronThinRight';
import { DashInput, DashTextArea, DashForm } from 'shared-components';
import { CheckCircle } from '@styled-icons/material/CheckCircle';
import { Close } from '@styled-icons/material/Close';
import { getTestIds } from 'lib/utils/dataTestIds';
import { sendGeneralCsRequest } from 'lib/api';
import { useBrand } from 'lib/hooks';
import { analyticsTrackEvent } from 'lib/utils/analytics';
import { useTranslation } from 'lib/hooks';

import { Box } from 'atomic-layout';
import {
  Panel,
  PanelTitle,
  LoadingButton,
  PanelTitleHeading,
  Icon,
  Button,
  Text,
  Container,
  Row,
  Column
} from '@ffn/ui';
import { formatPhoneNumberSafe } from 'lib/utils';

const testIds = getTestIds('csEmailUsForm');

const SuccessAlert = ({ handleReset }) => {
  const { t } = useTranslation();
  const brand = useBrand();
  const phoneNumber = brand('contact.customer-service.phone');
  return (
    <Container
      css={`
        display: flex;
        flex-direction: column;
        border: 1px solid yellowgreen;
        margin: 1em;
        padding: 1em;
      `}
    >
      <Container
        css={`
          align-self: flex-start;
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0.8rem;
          margin: 0;
        `}
      >
        <CheckCircle size={32} style={{ color: 'yellowgreen' }} />
        <Text
          css={`
            margin-left: 1rem;
            font-size: 20px;
          `}
        >
          {t('customerService.yourMessageWasSent')}
        </Text>
      </Container>
      <Container>
        <Text>{t('customerService.yourMessageHasBeenSentToCS')}</Text>
        <Text>
          {t('customerService.yourMessageHasBeenSentToCSLong')}{' '}
          <a href={`tel:${phoneNumber}`}>
            {formatPhoneNumberSafe(phoneNumber)}
          </a>{' '}
          {t('customerService.toContactUs')}
        </Text>
        <Button variant="primary" onClick={handleReset}>
          {t('customerService.sendAnotherRequest')}
        </Button>
      </Container>
    </Container>
  );
};

export function EmailUs() {
  css(``);
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const clientState = useSelector((state) => state.models.client);
  const handleSubmit = async (values, actions) => {
    try {
      handleReset();
      actions.setSubmitting(true);
      const { subject, message } = values;
      await sendGeneralCsRequest({ subject, message, generalEmail: true });
      handleSuccess({ actions, analyticsTrackEvent });
    } catch (e) {
      console.error(e);
      handleFailure({ e, analyticsTrackEvent });
    }
  };

  const validateSubject = (value) => {
    if (value === '' || !value) {
      return t('common.form.validationsAndErrors.pleaseEnterASubject');
    }
    if (value.length > 100) {
      return t(
        'common.form.validationsAndErrors.subjectMustBeLessThan_100Characters'
      );
    }
    return null;
  };

  const validateMessage = (value) => {
    if (value === '' || !value) {
      return t('common.form.validationsAndErrors.pleaseEnterAMessage');
    }
    if (value.length > 1000) {
      return t(
        'common.form.validationsAndErrors.messageMustBeLessThan_1000Characters'
      );
    }
    return null;
  };

  const handleSuccess = ({ actions, handleSuccess }) => {
    actions.setSubmitting(false);
    actions.resetForm();
    _handleSuccessEvent(analyticsTrackEvent);
    setSuccess(true);
  };

  const handleFailure = ({ e, analyticsTrackEvent }) => {
    handleReset();
    const message =
      e.message || t('common.form.validationsAndErrors.submissionFailed');
    _handleFailureEvent(analyticsTrackEvent);
    setError(message);
  };

  const handleReset = () => {
    setSuccess(false);
    setError(null);
  };

  return (
    <Panel data-testid="csEmailUsPanel">
      <PanelTitle>
        <PanelTitleHeading>{t('customerService.emailUs')}</PanelTitleHeading>
        {error && (
          <Container
            css={`
              border: 1px solid red;
              color: red;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
            `}
          >
            <Text>{error}</Text>
            <Close size={20} onClick={() => setError(null)} />
          </Container>
        )}
      </PanelTitle>
      {success ? (
        <SuccessAlert handleReset={handleReset} />
      ) : (
        <Formik
          initialValues={{
            email: clientState?.clientEmail,
            subject: '',
            message: ''
          }}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <DashForm>
              <Row>
                <Column cols={12} colsLg={6}>
                  <Box marginBottom={16}>
                    <DashInput
                      id="email"
                      name="email"
                      label={t('customerService.from')}
                      disabled={true}
                      value={clientState?.clientEmail}
                    />
                  </Box>
                  <Box marginBottom={16}>
                    <DashInput
                      data-testid={testIds.csSubjectInput}
                      id="subject"
                      name="subject"
                      label={t('customerService.enterSubject')}
                      disabled={isSubmitting}
                      validate={validateSubject}
                    />
                  </Box>
                  <Box marginBottom={16}>
                    <DashTextArea
                      data-testid={testIds.csMessageInput}
                      id="message"
                      name="message"
                      label={t('customerService.enterYourQuestionHere')}
                      disabled={isSubmitting}
                      validate={validateMessage}
                    />
                  </Box>
                  <Text
                    css={`
                      color: ${(props) => props.theme.colors.black};
                      font-size: 13px;
                    `}
                  >
                    {t('customerService.typicalResponseTimeIsOneToTwo')}
                  </Text>

                  <LoadingButton
                    data-testid={testIds.csSendButton}
                    height={40}
                    type="submit"
                    variant="primary"
                    rightEnhancer={<Icon icon={ChevronThinRight} size={14} />}
                    isLoading={isSubmitting}
                    css={`
                      width: 60%;
                    `}
                  >
                    {t('customerService.send')}
                  </LoadingButton>
                </Column>
              </Row>
            </DashForm>
          )}
        </Formik>
      )}
    </Panel>
  );
}

const _handleSuccessEvent = (analyticsTrackEvent) => {
  analyticsTrackEvent(
    {
      category: 'csr',
      action: 'success',
      label: 'client_request'
    },
    'Email Form Submission Success'
  );
};

const _handleFailureEvent = (analyticsTrackEvent) => {
  analyticsTrackEvent(
    {
      category: 'csr',
      action: 'failure',
      label: 'client_request'
    },
    'Email Form Submission Failure'
  );
};
