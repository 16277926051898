import axios from 'lib/axios';

import envConfig from 'envConfig';

export const getAppData = async () => {
  const apiUrl = `${envConfig.FIREBASE_FUNCTION_HOSTNAME}/bootstrap`;
  const { data } = await axios.get(apiUrl);

  return data.models;
};
