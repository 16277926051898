import React from 'react';
import { useTheme } from '@achieve/sunbeam';
export const RetrySVG = () => {
  const theme = useTheme();
  const blue = theme?.palette?.primary?.main ?? '#154199';

  return (
    <svg
      aria-hidden="true"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M76.6673 13.3342V33.3342M76.6673 33.3342H56.6673M76.6673 33.3342L61.2007 18.8009C57.6182 15.2166 53.1861 12.5983 48.318 11.1901C43.4498 9.78204 38.3043 9.63007 33.3616 10.7484C28.4189 11.8668 23.84 14.219 20.0522 17.5856C16.2644 20.9522 13.3912 25.2235 11.7007 30.0009M3.33398 66.6676V46.6676M3.33398 46.6676H23.334M3.33398 46.6676L18.8007 61.2009C22.3831 64.7852 26.8152 67.4036 31.6833 68.8117C36.5515 70.2198 41.697 70.3717 46.6397 69.2534C51.5824 68.135 56.1613 65.7828 59.9491 62.4162C63.7369 59.0496 66.6101 54.7783 68.3007 50.0009"
        stroke={blue}
        strokeWidth="6.53696"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
