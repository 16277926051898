import React, { useLayoutEffect } from 'react';
import {
  NavLink,
  Outlet,
  useLocation,
  useMatch,
  useNavigate,
  useParams
} from 'react-router-dom';
import { Box, Composition, useResponsiveQuery } from 'atomic-layout';
import { isNotNil } from 'lib/utils';
import { NavItem } from './components/NavItem';
import 'styled-components/macro';
import { AlertTriangle, Mail } from 'react-feather';
import { Badge, Heading, Icon } from '@ffn/ui';
import { NotificationListItem } from './components/NotificationListItem';
import { useSelector } from 'react-redux';
import { getTestIds } from 'lib/utils/dataTestIds';
import { ChevronThinLeft } from '@styled-icons/entypo/ChevronThinLeft';
import { useTranslation } from 'lib/hooks';

const notifyTestIDs = getTestIds('notifications');

export function NotificationsList() {
  const { t, tCount, alertsTitleNameTranslator } = useTranslation();
  const isMd = useResponsiveQuery({ from: 'md' });
  const { notificationType } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const match = useMatch('/notifications/:type');
  const endPath = location.pathname.replace(
    `/notifications/${notificationType}`,
    ''
  );

  useLayoutEffect(() => {
    if (!['alerts', 'messages'].includes(notificationType)) {
      navigate('/notifications/alerts', { replace: true });
    }
  }, [navigate, notificationType]);

  const notifications = useSelector(
    (state) => state?.models?.notifications || {}
  );

  return (
    <>
      {!isMd && endPath.trim() !== '' && (
        <Box marginBottom={16}>
          <NavLink
            to={`/notifications/${notificationType}`}
            css={`
              text-decoration: none;
              color: ${(props) => props.theme.colors.black};
            `}
          >
            <Icon icon={ChevronThinLeft} size={14} /> {t('common.back')}
          </NavLink>
        </Box>
      )}
      <Composition
        templateColsMd="350px 1px 1fr"
        templateCols="1fr"
        css={`
          border: 1px solid ${(props) => props.theme.colors.grayRegular};
          min-height: 500px;
        `}
      >
        {(isNotNil(match) || isMd) && (
          <Composition templateRows="min-content 1fr">
            <Composition templateCols="1fr 1fr" marginBottom={8}>
              <NavItem
                to="../alerts"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Badge count={notifications?.totalAlerts ?? 0}>
                  <AlertTriangle size={18} />
                </Badge>
                <span
                  style={{ marginLeft: 8 }}
                  data-testid={notifyTestIDs.notifications_list_alerts_button}
                >
                  {tCount('alerts.alert', 2)}
                </span>
              </NavItem>
              <NavItem
                to="../messages"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Badge count={notifications?.totalMessages ?? 0}>
                  <Mail size={18} />
                </Badge>
                <span
                  style={{ marginLeft: 8 }}
                  data-testid={notifyTestIDs.notifications_list_messages_button}
                >
                  {t('alerts.messages')}
                </span>
              </NavItem>
            </Composition>
            <Box data-testid={notifyTestIDs.notifications_list_container}>
              {notifications[notificationType]?.length > 0 ? (
                notifications[notificationType].map((notification) => (
                  <NotificationListItem
                    key={notification.id}
                    referenceIds={notification.referenceIds}
                    unread={
                      notificationType === 'alert' || !notification.isRead
                    }
                    date={notification.createdAt}
                    title={alertsTitleNameTranslator(
                      notification.title,
                      'title'
                    )}
                  />
                ))
              ) : (
                <Box
                  flex
                  alignItems="center"
                  justifyContent="center"
                  height="100%"
                >
                  <Heading as="h2">
                    {notificationType === 'alerts' && t('alerts.noAlerts')}
                    {notificationType === 'messages' && t('alerts.noMessages')}
                  </Heading>
                </Box>
              )}
            </Box>
          </Composition>
        )}
        {isMd && <Box style={{ background: '#dcdcdc', width: 1 }} />}
        <Outlet />
      </Composition>
    </>
  );
}
