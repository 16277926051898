import { createSelector } from 'reselect';
import { parseISO, differenceInDays } from 'date-fns/fp';
import { filter, head, map, pipe, prop, propEq, reject, sort } from 'ramda';
import { compareDesc, compareAsc, parseISO as parseString } from 'date-fns';

import { getFutureDrafts } from './allDraftFees';

export const getTransactions = ({ models }) =>
  Array.isArray(models.transactions) ? models.transactions : [];

const today = new Date();

// Returns all deposits, sorted by oldest to newest
export const getAllDeposits = createSelector(
  getTransactions,
  (transactions) => {
    const deposits = transactions?.filter((t) => {
      if (['D'].includes(t.transactionClass)) {
        return true;
      } else {
        return false;
      }
    });

    deposits.sort((a, b) =>
      compareAsc(parseString(a.transactionDate), parseString(b.transactionDate))
    );

    return deposits;
  }
);

// Return the oldest deposit from all transactions
export const getFirstDeposit = createSelector(
  getAllDeposits,
  getFutureDrafts,
  (allClearedDeposits, futureDrafts) => {
    if (allClearedDeposits.length > 0) {
      return head(allClearedDeposits);
    } else {
      const firstDepositRaw = prop('draftFeeWrapper', head(futureDrafts));

      const firstDeposit = {
        transactionId: firstDepositRaw?.id,
        transactionAmount: firstDepositRaw?.debitAmount,
        transactionDate: parseISO(firstDepositRaw?.draftsFeeDate),
        transactionStatusDescription: firstDepositRaw?.transactionStatus
      };

      return firstDeposit;
    }
  }
);

export const getTimelineDeposits = createSelector(
  getFirstDeposit,
  (firstDeposits) => {
    // Build array of months between firstDeposit and nextSettlement
  }
);

export const getTransactionsForLast30days = createSelector(
  getTransactions,
  (items) =>
    items?.filter((transaction) => {
      const diffOfDays = differenceInDays(
        today,
        parseISO(transaction.transactionDate)
      );

      return diffOfDays <= 0 && diffOfDays >= -30;
    })
);

export const getDepositsFromLast30days = createSelector(
  getTransactionsForLast30days,
  (transactions) =>
    transactions?.filter(
      (transaction) =>
        ['D', 'M'].includes(transaction.transactionClass) &&
        ['C', 'R', 'V', 'F'].includes(transaction.transactionStatus)
    )
);

export const getTotalDepositsFromLast30days = createSelector(
  getTransactionsForLast30days,
  (transactions) =>
    transactions
      ?.filter(
        (transaction) =>
          ['D', 'M'].includes(transaction.transactionClass) &&
          ['C', 'R', 'V', 'F'].includes(transaction.transactionStatus)
      )
      .reduce(
        (sum, transaction) => sum + parseFloat(transaction.transactionAmount),
        0
      )
);

export const getPaymentsFromLast30days = createSelector(
  getTransactionsForLast30days,
  (transactions) =>
    transactions?.filter(
      (transaction) =>
        ['P'].includes(transaction.transactionClass) &&
        ['C', 'R', 'V', 'F'].includes(transaction.transactionStatus)
    )
);

export const getTotalPaymentsFromLast30days = createSelector(
  getTransactionsForLast30days,
  (transactions) =>
    transactions
      ?.filter(
        (transaction) =>
          ['P'].includes(transaction.transactionClass) &&
          ['C', 'R', 'V', 'F'].includes(transaction.transactionStatus)
      )
      .reduce(
        (sum, transaction) => sum + parseFloat(transaction.transactionAmount),
        0
      )
);

export const getFeesFromLast30days = createSelector(
  getTransactionsForLast30days,
  (transactions) =>
    transactions?.filter((transaction) =>
      ['Fee', 'F', 'R'].includes(transaction.transactionClass)
    )
);

export const getTotalFeesFromLast30days = createSelector(
  getTransactionsForLast30days,
  (transactions) =>
    transactions
      ?.filter((transaction) =>
        ['Fee', 'F', 'R'].includes(transaction.transactionClass)
      )
      .reduce(
        (sum, transaction) => sum + parseFloat(transaction.transactionAmount),
        0
      )
);

export const getLatestPrenoteDate = createSelector(
  getTransactions,
  pipe(
    filter(propEq('transactionClass', 'P')),
    filter(propEq('transactionTypeDescription', 'PRENOTE')),
    reject(propEq('transactionDate', 'null')),
    map(prop('transactionDate')),
    map(parseISO),
    sort(compareDesc),
    head
  )
);
