import axios from 'axios';
import { getAppSubDomain, logger } from 'shared-components';

const subdomain = getAppSubDomain();

const defaultOptions = {
  headers: {
    'Content-type': 'application/json',
    'x-brand': subdomain
  }
};

export const axiosInstance = axios.create(defaultOptions);

export const updateAuthHeader = (token) => {
  if (token) {
    axiosInstance.defaults.headers.Authorization = `Bearer ${token}`;
  }
};

// Adding interceptors to handle responses, 2xx responses are passed through, non-2xx responses are logged
axiosInstance.interceptors.response.use(
  // 2xx responses
  (response) => {
    return response;
  },

  // non-2xx responses
  (error) => {
    const response = error.response;
    if (response) {
      if (response.status < 200 || response.status >= 300) {
        const url = response.config?.url ?? '';
        const method = response.config?.method ?? 'UNKNOWN';
        const status = response.status;
        const responseBody = response.data ?? '';
        const serviceName = url.includes('cloudfunctions')
          ? 'firebase functions'
          : url.includes('api')
            ? 'ffn-cx-api'
            : 'other';

        logger.error(
          `AXIOS :: Error in ${method} ${url} with status ${status}`,
          {
            serviceName,
            response: responseBody
          }
        );
      }
    } else {
      logger.error('AXIOS :: Network error or no response received', {
        error: error.message
      });
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
