import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import { AppRouter } from './apps';
import { QueryClient, QueryClientProvider } from 'react-query';
import { HelmetProvider } from 'react-helmet-async';
import { global } from '@ffn/ui';
import Layout from 'atomic-layout';
import {
  RenderIfNotWebview,
  SessionTimeoutDialog,
  TokenRefresh,
  WebviewAuthProvider
} from '@achieve/cx-auth';

// Additions for SunBeam
import { StyledEngineProvider, SunbeamThemeProvider } from '@achieve/sunbeam';
import { CacheProvider } from '@emotion/react';
import { createEmotionCache } from 'lib/utils/emotion';
import { BREAKPOINTS, SUNBEAM_THEME_DICTIONARY } from 'constants';
// End Additions for SunBeam
import {
  Datadog,
  getAppSubDomain,
  ProvideBrandTheme,
  ProvideDashboardType,
  ProvideSiteData
} from './shared-components';
import { Auth0ProviderWithNavigation } from 'shared-components/Auth0/Auth0Provider';
import {
  AuthProvider,
  FeatureFlagProvider,
  LaunchDarklyProvider,
  useAuth
} from 'lib/hooks';

import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light-border.css';
import './lib/utils/eventHorizon';

import ScrollToTop from 'shared-components/ScrollToTop';
import '@achieve/sunbeam/ultramarine-font';
import PWALinks from 'shared-components/PWALinks';
import { Provider } from 'react-redux';
import store from './store';
import { updateAuthHeader } from 'lib/axios';
import { getAuth0Vars } from './shared-components/Auth0/utils';
import { getTokenApiUrl } from 'lib/utils/getTokenApiUrl';

const GlobalStyle = global.GlobalStyle;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

// Configure Atomic Layout with the updated breakpoints
Layout.configure({ BREAKPOINTS });

function SessionTimeoutDialogContainer() {
  const { logout } = useAuth();
  const subdomain = getAppSubDomain();

  return (
    <SessionTimeoutDialog
      // uncomment to test, sets session warning message after 5 seconds
      // timeoutInSeconds={30000}
      // beforeIdleInSeconds={29995}
      brand={subdomain}
      handleLogout={logout}
      onLogEvent={({ level, message, data }) => console[level](message, data)}
    />
  );
}

/***
 * We need to dynamically set the basename for our routes based on the brand
 */
function setBasename(subdomain) {
  switch (subdomain) {
    case 'achieve':
      return `/dashboard/resolution`;
    case 'fdr':
      return `/dashboard`;
    case 'turnbull':
      return `/`;
    default:
      return `/dashboard`;
  }
}

function App() {
  const subdomain = getAppSubDomain();
  const { domain } = getAuth0Vars(subdomain);
  const customSunbeamTheme = SUNBEAM_THEME_DICTIONARY[subdomain];
  const clientSideEmotionCache = createEmotionCache();
  const tokenApiUrl = getTokenApiUrl();

  if (!subdomain) {
    return <>Not Found</>;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <React.StrictMode>
          <HelmetProvider>
            {/*** Adding a brand-aware manifest.json to the head of the document */}
            <PWALinks brand={subdomain} />
            {/*** End of manifest.json ***/}
            <ProvideDashboardType>
              <ProvideSiteData>
                <FeatureFlagProvider>
                  <ProvideBrandTheme>
                    <CacheProvider value={clientSideEmotionCache}>
                      <StyledEngineProvider injectFirst>
                        <SunbeamThemeProvider
                          customThemes={[customSunbeamTheme]}
                        >
                          <GlobalStyle />
                          {/* Our app has multiple entry point and that's where basename will come in handy */}
                          <Router basename={setBasename(subdomain)}>
                            {/* Ensure that every time the URL changes, the scroll position is moved to the top of the page */}
                            <ScrollToTop />
                            <Datadog />
                            <WebviewAuthProvider domain={domain}>
                              <Auth0ProviderWithNavigation
                                subdomain={subdomain}
                              >
                                <AuthProvider>
                                  <LaunchDarklyProvider>
                                    <AppRouter />
                                  </LaunchDarklyProvider>
                                  <RenderIfNotWebview>
                                    <TokenRefresh
                                      onLogEvent={({ level, message, data }) =>
                                        console[level](message, data)
                                      }
                                      onNewToken={updateAuthHeader}
                                      tokenApiUrl={tokenApiUrl}
                                    />
                                    <SessionTimeoutDialogContainer
                                      onLogEvent={({ level, message, data }) =>
                                        console[level](message, data)
                                      }
                                    />
                                  </RenderIfNotWebview>
                                </AuthProvider>
                              </Auth0ProviderWithNavigation>
                            </WebviewAuthProvider>
                          </Router>
                        </SunbeamThemeProvider>
                      </StyledEngineProvider>
                    </CacheProvider>
                  </ProvideBrandTheme>
                </FeatureFlagProvider>
              </ProvideSiteData>
            </ProvideDashboardType>
          </HelmetProvider>
        </React.StrictMode>
      </Provider>
    </QueryClientProvider>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

serviceWorker.unregister();
