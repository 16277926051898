import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Dashboard } from './Dashboard';
import { Onboarding } from './Onboarding';
import { LeadDocUpload } from './LeadDocUpload';
import { analyticsTrackPage } from 'lib/utils/analytics';
import { getAppSubDomain } from 'shared-components';
import { Route, Routes, useLocation } from 'react-router-dom';
import i18n from '../lang/i18n';
import LocaleContext from '../lang/LocaleContext';
import {
  ChangePasswordModalProvider,
  EduProvider,
  ProductTourProvider,
  RegionalEligibilityProvider,
  useAppData,
  useAuth
} from 'lib/hooks';
import { getUserRecord } from 'shared-selectors/userRecord';
import { modelsStatus } from 'shared-selectors/dataStatus';

export function AppRouter() {
  const { isAuthenticated } = useAuth();
  const { fetchModels, fetchPtsData, fetchUserRecord } = useAppData();
  const userAccount = useSelector(getUserRecord);
  const modelsLoadingStatus = useSelector(modelsStatus);
  const subdomain = getAppSubDomain();
  const [locale, setLocale] = useState(i18n.language);
  const [referringPath, setReferringPath] = useState(null);
  const { fdrApplicantId, subApp } = userAccount;
  i18n.on('languageChanged', (lng) => setLocale(i18n.language));
  const location = useLocation();
  const search = location.search;

  const languageParam = new URLSearchParams(search).get('language');

  // Kickoff fetching data once we have an auth0 user object
  useEffect(() => {
    if (!isAuthenticated || modelsLoadingStatus === 'success') return;
    // Get user record from Firestore

    // To Do:
    fetchUserRecord();
    // Get all model data from SalesForce via "/bootstrap" Firebase Function
    fetchModels();
    // Get pts data, for dashboard users, when fdrApplicantId is available
    if (fdrApplicantId && subApp === 'dashboard') {
      fetchPtsData();
    }
  }, [
    isAuthenticated,
    fdrApplicantId,
    fetchModels,
    fetchPtsData,
    fetchUserRecord,
    modelsLoadingStatus,
    subApp
  ]);

  useEffect(() => {
    if (languageParam && subdomain === 'fdr') {
      if (languageParam === 'es') {
        i18n.changeLanguage('es');
      } else {
        i18n.changeLanguage('en-US');
      }
    }
  }, [languageParam, subdomain]);

  // this is where ALL, but /program-resources/blog, page load events originate
  useEffect(() => {
    // data engineering requires page blog page information stored in contentful
    // isBlogPage stops analyticsTrackPage call, so it can be called inside
    // the ProgramResources/Blog subpages
    // packages/web/src/shared-pages/dashboard/ProgramResources/Blog/index.js
    // packages/web/src/shared-pages/dashboard/ProgramResources/Blog/Post/index.js
    const isBlogPage = location.pathname.split('/').includes('blog');
    if (referringPath !== location.pathname && !isBlogPage) {
      analyticsTrackPage({
        referringPath, // previous page path
        currentRoute: location.pathname, // current page path
        url_params: window.location?.search ?? '' // url parameters
      });
      setReferringPath(location.pathname);
    }
  }, [location.pathname, referringPath]);

  const locationPaths = location.pathname.split('/');

  const IS_DOC_UPLOAD = locationPaths.includes('upload');
  const IS_ONBOARDING_USER = userAccount?.subApp === 'onboarding';

  if (IS_DOC_UPLOAD) {
    return (
      <LocaleContext.Provider value={{ locale, setLocale }}>
        <Routes>
          <Route path="/upload/:oobCode" element={<LeadDocUpload />} />
        </Routes>
      </LocaleContext.Provider>
    );
  }
  return (
    <LocaleContext.Provider value={{ locale, setLocale }}>
      {IS_ONBOARDING_USER ? (
        <Onboarding />
      ) : (
        <EduProvider>
          <ChangePasswordModalProvider>
            <ProductTourProvider>
              <RegionalEligibilityProvider>
                <Dashboard />
              </RegionalEligibilityProvider>
            </ProductTourProvider>
          </ChangePasswordModalProvider>
        </EduProvider>
      )}
    </LocaleContext.Provider>
  );
}
