export const prd = {
  FIREBASE_API_KEY: 'AIzaSyBlFkOCH8ZzIXun9Y38Mreo22LN5a40n2c',
  FIREBASE_AUTH_DOMAIN: 'fdr-dashboard-prd.firebaseapp.com',
  FIREBASE_DATABASE_URL: 'https://fdr-dashboard-prd.firebaseio.com',
  FIREBASE_PROJECT_ID: 'fdr-dashboard-prd',
  FIREBASE_STORAGE_BUCKET: 'fdr-dashboard-prd.appspot.com',
  FIREBASE_MESSAGING_SENDER_ID: '828457459530',
  FIREBASE_FUNCTION_HOSTNAME:
    'https://us-central1-fdr-dashboard-prd.cloudfunctions.net',
  TEALIUM_ENV: 'prod',
  FFN_CX_API_URL: 'https://ffn-cx-api.prd.fdrgcp.com',
  AR_CX_API_URL: 'https://www.achieve.com/dashboard/resolution/api',
  FDR_CX_API_URL: 'https://www.freedomdebtrelief.com/dashboard/api',
  ARP_CX_API_URL: 'https://turnbull.programdashboard.com/api',
  ACHIEVE_ROOT_URL: 'https://www.achieve.com',
  FDR_ROOT_URL: 'https://www.freedomdebtrelief.com',
  TURNBULL_ROOT_URL: 'https://turnbull.programdashboard.com',
  AUTH0_FDR_DOMAIN: 'login.freedomdebtrelief.com',
  AUTH0_FDR_CLIENT_ID: '2s5FTY39r4Kp61BuABQGcE0MQUUu85wS',
  AUTH0_FDR_CALLBACK_URL: 'https://www.freedomdebtrelief.com/dashboard',
  AUTH0_FDR_AUDIENCE: 'https://auth.prd.ffngcp.com',
  AUTH0_FDR_ISSUER: 'https://login.freedomdebtrelief.com/',
  AUTH0_TURNBULL_DOMAIN: 'login-turnbull.programdashboard.com',
  AUTH0_TURNBULL_CLIENT_ID: '2knvKuOhxy8DDEZsCW9C2QZK3qEHhjXo',
  AUTH0_TURNBULL_CALLBACK_URL: 'https://turnbull.programdashboard.com',
  AUTH0_TURNBULL_AUDIENCE: 'https://auth.prd.ffngcp.com',
  AUTH0_AR_DOMAIN: 'login.achieve.com',
  AUTH0_AR_CLIENT_ID: 'JNwSvdE7VAW5uFiDwaTewyR6fag15hIV',
  AUTH0_AR_CALLBACK_URL: 'https://www.achieve.com/dashboard/resolution',
  AUTH0_AR_AUDIENCE: 'https://auth.prd.ffngcp.com',
  UPSCOPE_PUBLIC_API_KEY: 'WhMqt6nFqZ',
  DATADOG_ACX_RUM_APP_ID: 'c51586c0-7b29-4f43-ac5f-b251dd061bfe',
  DATADOG_ACX_RUM_CLIENT_TOKEN: 'pub59d6ffd2be890b820fdb75473968e124',
  DATADOG_RUM_APP_ID: '01c9b8c0-b004-46d4-91d0-321b5dbc6fff',
  DATADOG_RUM_CLIENT_TOKEN: 'pub689ecf0ceb47a1ef5def4dfc2f1bac0b',
  DATADOG_APP_ENV: 'prd',
  DD_SERVICE: 'cx-fdr-client-dashboard',
  DD_SESSION_REPLAY_SAMPLE_RATE: '10',
  DD_RUM_ENABLED: 'true',
  GENESYS_ENVIRONMENT: 'prod',
  GENESYS_DEPLOYMENT_ID: '8531dff0-d1f7-4933-89bf-23f8b39c60ba',
  GENESYS_REDIRECT_URL: 'https://www.freedomdebtrelief.com/dashboard',
  GENESYS_CLIENT_ID: 'MtkosOZ6xAfuMMYLWJGzw1zblRZtrrus',
  GENESYS_DEBUG_FLAG: 'false',
  ACHIEVE_GOOGLE_RECAPTCHA_SITE_KEY: '6Ld3H7wpAAAAAGaD6Do6PxHlHdlAFdqGrCAsmhUU',
  FDR_GOOGLE_RECAPTCHA_SITE_KEY: '6LeEHrwpAAAAAGU34WX8UW0TQXg53N81fV3eSlmE',
  TURNBULL_GOOGLE_RECAPTCHA_SITE_KEY:
    '6LdhH7wpAAAAAJFkN8wwwY_XHTlak1y41DR07B5O',
  EVENT_HORIZON_DEBUG: 'true'
};
