import React from 'react';
import { Box, Typography } from '@achieve/sunbeam';
import { CheckCircle, X } from 'react-feather';
import { useTranslation } from 'lib/hooks';
import { getAppSubDomain } from 'shared-components';

import FullImageFDR from './assets/full-image.png';
import FullImageTurnbull from './assets/full-image-turnbull.png';

const FULL_IMAGES = {
  turnbull: FullImageTurnbull,
  fdr: FullImageFDR,
  default: FullImageFDR
};

const PROMO_COPY_KEYS = {
  turnbull: 'appPromo.stayInTheKnowOnTheGoTurnbull',
  fdr: 'appPromo.stayInTheKnowOnTheGo',
  default: 'appPromo.stayInTheKnowOnTheGo'
};

const PROMO_IMAGE_ALT = {
  turnbull: 'Rendering of the Turnbull App on an iPhone',
  fdr: 'Rendering of the FDR App on an iPhone',
  default: 'Rendering of the FDR App on an iPhone'
};

export function AppPromoBody({
  renderBullets = true,
  renderImage = true,
  dismissPromo = null
}) {
  const { t } = useTranslation();
  const brand = getAppSubDomain();

  // branded content
  const FullImage = FULL_IMAGES[brand] || FULL_IMAGES.default;
  const promoCopyKey = PROMO_COPY_KEYS[brand] || PROMO_COPY_KEYS.default;
  const promoImageAlt = PROMO_IMAGE_ALT[brand] || PROMO_IMAGE_ALT.default;

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={3}
      data-testid="native-app-cta-body"
    >
      <Box display="flex" gap="20px" justifyContent="flex-start">
        <Typography component="h2" variant="ascendHeadingSm">
          {t(promoCopyKey)}
        </Typography>
        {dismissPromo && (
          <X
            data-testid="native-app-cta-dismiss-promo"
            size="24px"
            onClick={dismissPromo}
          />
        )}
      </Box>
      {renderImage && (
        <Box>
          <img width="100%" src={FullImage} alt={promoImageAlt} />
        </Box>
      )}
      <Typography component="p" variant="ascendBodySm">
        {t('appPromo.keepTrackOfEveryDebt')}
      </Typography>
      {renderBullets && (
        <Box display="flex" flexDirection="column" gap={2}>
          <Box display="flex" alignItems="center" gap={'8px'}>
            <Box display="flex" alignItems="center">
              <CheckCircle size={24} />
            </Box>
            <Typography component="p" variant="ascendBodySm">
              {t('appPromo.instantlyApproveSettlements')}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" gap={'8px'}>
            <Box display="flex" alignItems="center">
              <CheckCircle size={24} />
            </Box>
            <Typography component="p" variant="ascendBodySm">
              {t('appPromo.viewRealTimeProgramUpdates')}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
}
