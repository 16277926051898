import React from 'react';
import { css } from 'styled-components/macro';
import { useBrand } from 'lib/hooks';
import {
  Panel,
  PanelTitle,
  Row,
  Column,
  PanelTitleHeading,
  Icon
} from '@ffn/ui';
import { useTranslation } from 'lib/hooks';
import { MapPin, Printer } from 'react-feather';

export function MailOrFaxUs() {
  const { t } = useTranslation();
  css(``);
  const brand = useBrand();

  return (
    <Panel data-testid="csMailUsOrFaxUsPanel">
      <PanelTitle>
        <PanelTitleHeading>
          {t('customerService.mailOrFaxUs')}
        </PanelTitleHeading>
      </PanelTitle>
      <Row>
        {brand('contact.address') !== undefined && (
          <Column cols={12} colsMd={6}>
            <Row>
              <Column cols={2}>
                <Icon
                  icon={MapPin}
                  size={25}
                  css={`
                    color: ${(props) => props.theme.colors.tertiaryBlue};
                  `}
                />
              </Column>
              <Column cols={10}>
                <Row>
                  <Column
                    cols={12}
                    css={`
                      font-weight: 600;
                    `}
                  >
                    {t('customerService.address')}
                  </Column>
                </Row>
                <Row marginTop={10}>
                  <Column cols={12}>
                    {brand('business-name.legal')}
                    <br />
                    {brand('contact.address.line1')}{' '}
                    {brand('contact.address.city')},{' '}
                    {brand('contact.address.state')}
                    <br />
                    {brand('contact.address.zipCode')}
                  </Column>
                </Row>
              </Column>
            </Row>
          </Column>
        )}
        {brand('contact.fax') !== undefined && (
          <Column cols={12} colsMd={6} marginTop={16} marginTopMd={0}>
            <Row>
              <Column cols={2}>
                <Icon
                  icon={Printer}
                  size={25}
                  css={`
                    color: ${(props) => props.theme.colors.tertiaryBlue};
                  `}
                />
              </Column>
              <Column cols={10}>
                <Row>
                  <Column
                    cols={12}
                    css={`
                      font-weight: 600;
                    `}
                  >
                    {t('customerService.fax')}
                  </Column>
                </Row>
                <Row marginTop={10}>
                  <Column cols={12}>{brand('contact.fax')}</Column>
                </Row>
              </Column>
            </Row>
          </Column>
        )}
      </Row>
    </Panel>
  );
}
