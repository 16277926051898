import { makeStyles } from 'tss-react/mui';
import tw from 'twin.macro';

const useStyles = makeStyles({ name: 'ProgressBar' })((theme) => {
  return {
    rootContainer: {
      ...tw` w-full mt-2`
    },
    containerStyles: {
      ...tw`h-2 w-full rounded mt-2`,
      backgroundColor: theme.sb.colors.extended.cobaltTint03
    },
    fillerStyles: {
      ...tw`h-full rounded`,
      backgroundColor: theme.sb.colors.primary.achieveBlue
    },
    progressPercStyles: {
      ...tw`w-1/4 inline-block text-right`
    },
    progressLabelStyles: {
      ...tw`w-3/4 inline-block`
    }
  };
});

export default useStyles;
