import { Composition } from 'atomic-layout';
import { Container, Heading } from '@ffn/ui';
import PropTypes from 'prop-types';
import React from 'react';
import { BreadCrumbs } from './BreadCrumbs';

const WithoutSidebar = ({
  children,
  breadCrumbs = [],
  pageTitle,
  ...props
}) => {
  return (
    <Container paddingHorizontal={15}>
      <Composition
        areas={`content`}
        templateCols="100%"
        templateRows="auto"
        {...props}
      >
        {({ Content }) => (
          <Container paddingHorizontal={0}>
            <Content data-testid="main-content">
              <Heading data-testid="page-title">{pageTitle}</Heading>
              {breadCrumbs.length > 0 ? (
                <BreadCrumbs
                  data-testid="bread-crumbs"
                  breadCrumbs={breadCrumbs}
                ></BreadCrumbs>
              ) : null}
              {children}
            </Content>
          </Container>
        )}
      </Composition>
    </Container>
  );
};

WithoutSidebar.propTypes = {
  breadcrumbs: PropTypes.array,
  title: PropTypes.string,
  children: PropTypes.any.isRequired
};

export { WithoutSidebar };
