import { Box, useResponsiveQuery } from 'atomic-layout';
import React from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { Heading, Text } from '@ffn/ui';
import { useSelector } from 'react-redux';
import { getTestIds } from 'lib/utils/dataTestIds';
import 'styled-components/macro';
import { useTranslation } from 'lib/hooks';
const notifyTestIDs = getTestIds('notifications');

export function EmptyContent() {
  const { notificationType } = useParams();
  const isMd = useResponsiveQuery({ from: 'md' });
  const notifications = useSelector((state) => state.models.notifications);
  const firstNotification =
    notifications && notifications[notificationType]
      ? notifications[notificationType][0]
      : null;
  const { t } = useTranslation();

  if (firstNotification && isMd) {
    return <Navigate to={`${firstNotification.referenceIds[0]}`} replace />;
  }

  return (
    <Box
      flex
      alignItems="center"
      justifyContent="center"
      data-testid={notifyTestIDs.notifications_content_container}
    >
      <Heading
        as="h2"
        css={`
          text-align: center;
        `}
      >
        {notificationType === 'alerts' && !firstNotification && (
          <>
            {isMd ? (
              <Text>{t('alerts.greatNewsYouHaveNoAlertsAtThisTime')}</Text>
            ) : (
              <>
                <Text>{t('alerts.greatNews')}</Text>
                <Text>{t('alerts.youHaveNoAlertsAtThisTime')}</Text>
              </>
            )}
          </>
        )}
        {notificationType === 'messages' && !firstNotification && (
          <>
            {isMd ? (
              <Text>{t('alerts.youCurrentlyHaveNoMessagesToView')}</Text>
            ) : (
              <>
                <Text>{t('alerts.youCurrently')}</Text>
                <Text>{t('alerts.haveNoMessagesToView')}</Text>
              </>
            )}
          </>
        )}
      </Heading>
    </Box>
  );
}
