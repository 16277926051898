import { axiosInstance as axios } from 'lib/axios';
import getApiUrl from 'lib/utils/getApiUrl';

export async function fetchProgramDetails() {
  const urlStem = getApiUrl();
  const apiUrl = `${urlStem}/lead/programDetails`;

  const response = await axios.get(apiUrl, {
    validateStatus: (status) => status === 200
  });

  return response.data;
}

// checks if user is eligible for program
export async function fetchProgramDetailsEligibility() {
  const urlStem = getApiUrl();
  const apiUrl = `${urlStem}/user/cfosEligible`;

  const response = await axios.get(apiUrl, {
    validateStatus: (status) => status === 200
  });

  return response.data;
}
