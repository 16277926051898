import { getAppSubDomain } from '../../shared-components/ProvideDashboardType';
import envConfig from 'envConfig';

function getApiUrl() {
  const subdomain = getAppSubDomain();
  const { FDR_CX_API_URL, AR_CX_API_URL, ARP_CX_API_URL } = envConfig;

  if (subdomain === 'fdr') {
    return FDR_CX_API_URL;
  } else if (subdomain === 'turnbull') {
    return ARP_CX_API_URL;
  } else if (subdomain === 'achieve') {
    return AR_CX_API_URL;
  } else {
    throw new Error('ERROR: Cannot match CX API URL from domain');
  }
}

export default getApiUrl;
