import { Link } from 'react-router-dom';
import styled from 'styled-components';
import React from 'react';

import { Icon, PanelTitle, PanelTitleHeading } from '@ffn/ui';
import { FeatureFlag } from 'shared-components';
import { analyticsTrackEvent } from 'lib/utils/analytics';
import { EVENT_ACTION } from 'lib/utils/eventHorizon';
import { useTranslation } from 'lib/hooks';
import {
  Edit3,
  CheckSquare,
  HelpCircle,
  PlayCircle,
  Phone,
  Briefcase
} from 'react-feather';

const ResourceItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  &:last-of-type {
    margin-bottom: 0px;
  }
`;

const domain = document?.domain ?? '';
const PanelVariant = styled('div')`
  background-image: linear-gradient(
    to bottom,
    ${({ theme }) => theme.colors.gradientStart},
    ${({ theme }) => theme.colors.gradientEnd}
  );
  color: #fff;
  text-align: left;
  padding: 30px 20px;
  border-radius: 8px;
  border: 1px solid #c7c7cc;
  margin-bottom: 24px;
  box-shadow:
    0px 6px 40px -4px rgba(16, 24, 40, 0.11),
    0px 9px 12px -3px rgba(16, 24, 40, 0.05);

  li {
    padding-bottom: 8px;
    font-weight: 700;
  }

  a {
    text-decoration: none;
    color: #fff;
    cursor: pointer;
  }

  .key-resource-header {
    color: #fff;
    margin: 0;
  }
`;

const Resources = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const items = [
  {
    title: 'Update Contact Info',
    icon: Edit3,
    link: '/settings/contact'
  },
  {
    title: 'How it Works',
    icon: CheckSquare,
    link: '/program-resources/how-it-works'
  },
  {
    title: 'Helpful Videos',
    icon: PlayCircle,
    link: '/program-resources/videos'
  },
  {
    title: 'faq',
    icon: HelpCircle,
    link: '/program-resources/faq'
  },
  {
    title: 'Contact Us',
    icon: Phone,
    link: '/customer-service'
  },
  {
    title: 'Add a deposit',
    icon: Briefcase,
    link: '/customer-service/deposits'
  }
];

const KeyResources = (props) => {
  const click_position = props.index + 1;
  const { t } = useTranslation();
  return (
    <div data-testid="key-resources">
      <PanelVariant>
        <PanelTitle>
          <PanelTitleHeading>
            <h4 className="key-resource-header">{t('nav.keyResources')}</h4>
          </PanelTitleHeading>
        </PanelTitle>
        <div className="row">
          <Resources>
            {items.map((item, i) => {
              return (
                <FeatureFlag key={item.link} pathKey={item.link}>
                  <ResourceItem
                    onClick={() =>
                      analyticsTrackEvent(
                        {
                          event_action: EVENT_ACTION.LINK_CLICK,
                          nav_link_section: 'Sidebar',
                          click_type: 'Dynamic Promo',
                          click_action: 'Key Resources',
                          click_position,
                          click_id: item.title,
                          click_url: 'https://' + domain + item.link,
                          clickText: item.title,
                          track_event: 'internal_campaign_click'
                        },
                        `Sidebar: ${item.title} Link Click`
                      )
                    }
                  >
                    <Icon
                      icon={item.icon}
                      size={20}
                      style={{ marginRight: '10px' }}
                    />
                    <Link to={item.link}>{t('nav', item.title)}</Link>
                    <span className="default icon-arrow-right" />
                  </ResourceItem>
                </FeatureFlag>
              );
            })}
          </Resources>
        </div>
      </PanelVariant>
    </div>
  );
};

export default KeyResources;
export { KeyResources };
