import { getAppSubDomain } from 'shared-components';

export function getTokenApiUrl() {
  const domain = window.location.hostname;

  let environment;
  if (domain.includes('feature-')) {
    environment = 'feature';
  } else if (domain.includes('dev')) {
    environment = 'dev';
  } else if (domain.includes('stg')) {
    environment = 'stg';
  } else if (domain.endsWith('.local')) {
    environment = 'local';
  } else {
    environment = 'prd';
  }

  const brand = getAppSubDomain();

  let baseUrl;
  switch (environment) {
    case 'local':
      if (brand === 'fdr') baseUrl = 'www.freedomdebtrelief.local';
      if (brand === 'turnbull') baseUrl = 'turnbull.programdashboard.local';
      if (brand === 'achieve') baseUrl = 'achieve.programdashboard.local';
      break;

    case 'feature':
    case 'dev':
      if (brand === 'fdr') baseUrl = 'fdr.dev.fdrgcp.com';
      if (brand === 'turnbull') baseUrl = 'turnbull-dev.programdashboard.com';
      if (brand === 'achieve') baseUrl = 'achieve.dev.ffngcp.com';
      break;

    case 'stg':
      if (brand === 'fdr') baseUrl = 'fdr.stg.fdrgcp.com';
      if (brand === 'turnbull') baseUrl = 'turnbull-stg.programdashboard.com';
      if (brand === 'achieve') baseUrl = 'achieve.stg.ffngcp.com';
      break;

    case 'prd':
      if (brand === 'fdr') baseUrl = 'freedomdebtrelief.com';
      if (brand === 'turnbull') baseUrl = 'turnbull.programdashboard.com';
      if (brand === 'achieve') baseUrl = 'achieve.com';
      break;
  }

  let path = brand === 'achieve' ? '/signin/api/token' : '/login/api/token';
  return `https://${baseUrl}${path}`;
}
