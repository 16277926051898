import { axiosInstance as axios } from 'lib/axios';

import envConfig from 'envConfig';

export const alertResolve = async (payload) => {
  const apiUrl = `${envConfig.FIREBASE_FUNCTION_HOSTNAME}/alertResolve`;
  try {
    const response = await axios.put(apiUrl, payload, {
      validateStatus: (status) => status === 200
    });
    return response.data;
  } catch (error) {
    throw new Error('ALERT_RESOLVE_FAILURE');
  }
};
