export const local = {
  FIREBASE_API_KEY: 'AIzaSyAZfNGEogBX93E42XR7X5T5PtQfxk22PIE',
  FIREBASE_AUTH_DOMAIN: 'fdr-dashboard-dev-31e26.firebaseapp.com',
  FIREBASE_DATABASE_URL: 'https://fdr-dashboard-dev-31e26.firebaseio.com',
  FIREBASE_PROJECT_ID: 'fdr-dashboard-dev',
  FIREBASE_STORAGE_BUCKET: 'fdr-dashboard-dev.appspot.com',
  FIREBASE_MESSAGING_SENDER_ID: '584307740711',
  FIREBASE_FUNCTION_HOSTNAME:
    'https://us-central1-fdr-dashboard-dev.cloudfunctions.net',
  TEALIUM_ENV: 'dev',
  FFN_CX_API_URL: 'https://ffn-cx-api.dev.fdrgcp.com/api',
  AR_CX_API_URL: 'https://achieve.dev.ffngcp.com/dashboard/resolution/api',
  FDR_CX_API_URL: 'https://fdr.dev.fdrgcp.com/dashboard/api',
  ARP_CX_API_URL: 'https://turnbull-dev.programdashboard.com/api',
  ACHIEVE_ROOT_URL: 'https://achieve.dev.ffngcp.com',
  FDR_ROOT_URL: 'https://fdr.dev.fdrgcp.com',
  TURNBULL_ROOT_URL: 'https://turnbull-dev.programdashboard.com',
  AUTH0_FDR_DOMAIN: 'login-fdr.dev.fdrgcp.com',
  AUTH0_FDR_CLIENT_ID: 'ode0f4ykavYA1FRuubsIN2iuiBUNoxsE',
  AUTH0_FDR_CALLBACK_URL: 'https://www.freedomdebtrelief.local:3000',
  AUTH0_FDR_AUDIENCE: 'https://auth.dev.ffngcp.com',
  AUTH0_FDR_ISSUER: 'https://login-fdr.dev.fdrgcp.com',
  AUTH0_TURNBULL_DOMAIN: 'login-turnbull-dev.programdashboard.com',
  AUTH0_TURNBULL_CLIENT_ID: 'HOIqzZqht8jcnhRf6RVt3rJ6MMgPmPE9',
  AUTH0_TURNBULL_CALLBACK_URL: 'https://turnbull.programdashboard.local:3000/',
  AUTH0_TURNBULL_AUDIENCE: 'https://auth.dev.ffngcp.com',
  AUTH0_AR_DOMAIN: 'login-achieve-dev.ffngcp.com',
  AUTH0_AR_CLIENT_ID: 'tb0LqUo4uvTFBt1Z6vEGxZwn2sDOVVJX',
  AUTH0_AR_CALLBACK_URL:
    'https://achieve.programdashboard.local:3000/dashboard/resolution',
  AUTH0_AR_AUDIENCE: 'https://auth.dev.ffngcp.com',
  UPSCOPE_PUBLIC_API_KEY: 'WhMqt6nFqZ',
  DATADOG_ACX_RUM_APP_ID: 'c51586c0-7b29-4f43-ac5f-b251dd061bfe',
  DATADOG_ACX_RUM_CLIENT_TOKEN: 'pub59d6ffd2be890b820fdb75473968e124',
  DATADOG_RUM_APP_ID: '01c9b8c0-b004-46d4-91d0-321b5dbc6fff',
  DATADOG_RUM_CLIENT_TOKEN: 'pub689ecf0ceb47a1ef5def4dfc2f1bac0b',
  DATADOG_APP_ENV: 'dev',
  DD_SERVICE: 'cx-fdr-client-dashboard',
  DD_SESSION_REPLAY_SAMPLE_RATE: '10',
  DD_RUM_ENABLED: 'false',
  GENESYS_ENVIRONMENT: 'prod-usw2',
  GENESYS_DEPLOYMENT_ID: 'f68d09f8-e061-4913-9bf6-de4302438a2a',
  GENESYS_REDIRECT_URL: 'https://fdr.dev.fdrgcp.com/dashboard',
  GENESYS_CLIENT_ID: 'PlKJ7GW9Ipah33yPKs4xvg0cB62cmBfu',
  GENESYS_DEBUG_FLAG: 'true',
  ACHIEVE_GOOGLE_RECAPTCHA_SITE_KEY: '6LcDyXwpAAAAALYZq3PfgdQ1yEibB_pf4r-CYMdI',
  FDR_GOOGLE_RECAPTCHA_SITE_KEY: '6LcDyXwpAAAAALYZq3PfgdQ1yEibB_pf4r-CYMdI',
  TURNBULL_GOOGLE_RECAPTCHA_SITE_KEY:
    '6LcDyXwpAAAAALYZq3PfgdQ1yEibB_pf4r-CYMdI',
  EVENT_HORIZON_DEBUG: 'true'
};
