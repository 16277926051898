import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import 'styled-components/macro';
import { useSelector } from 'react-redux';
import { Composition, Only, Box } from 'atomic-layout';
import { DashInput, DashSelect } from 'shared-components';
import ReadyToUploadComponent from '../shared-components/ReadyToUploadComponent';
import { getDebtsInNegotiation } from 'shared-selectors/enrolled-debt';
import { useTranslation } from 'lib/hooks';
export function MetadataForm({ currentFile, fileIndex }) {
  const { errors, isValid, isSubmitting, setFieldValue } = useFormikContext();
  const accounts = useSelector(getDebtsInNegotiation);
  const { fdr_sf_debt_account_id, creditor } = currentFile.metadata;
  const fileWasRejected = currentFile.status === 'rejected';
  const fileWasSubmitted = currentFile.status === 'submitted';
  const showReadyToUpload =
    !isSubmitting && !fileWasSubmitted && (isValid || !errors.files[fileIndex]);
  const { t } = useTranslation();

  useEffect(() => {
    if (creditor) {
      const selector = `select[name='files.${fileIndex}.metadata.creditor']`;
      const { accountId } =
        document.querySelector(selector).selectedOptions[0].dataset;
      setFieldValue(
        `files.${fileIndex}.metadata.fdr_sf_debt_account_id`,
        accountId
      );
    }
  }, [creditor, fileIndex, setFieldValue]);
  return (
    <Composition
      templateCols="24px 1fr 24px"
      templateColsSmDown="1fr"
      autoFlowSmDown="row"
      gap={12}
      alignItems="center"
      paddingTopSmDown={8}
      paddingHorizontal={16}
      paddingBottom="16px"
      css={`
        background-color: ${(props) => props.theme.colors.plain};
      `}
    >
      <Only from="md" />
      <Box
        autoComplete="off"
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 16
        }}
      >
        <Box>
          <DashSelect
            id={`files.${fileIndex}.metadata.creditor`}
            name={`files.${fileIndex}.metadata.creditor`}
            label={t('docUpload.creditorName')}
            disabled={isSubmitting || fileWasSubmitted}
            style={{ paddingRight: '32px' }}
          >
            {!fdr_sf_debt_account_id && (
              <option>{t('docUpload.selectCreditor')}</option>
            )}
            {accounts.map((obj) => (
              <option key={obj.accountId} data-account-id={obj.accountId}>
                {`${obj.originalCreditor} ${obj.accountNumber}`}
              </option>
            ))}
          </DashSelect>
          {showReadyToUpload ? (
            <ReadyToUploadComponent
              fileWasRejected={fileWasRejected}
              from="md"
            />
          ) : null}
        </Box>
        <Box>
          <DashInput
            id={`files.${fileIndex}.metadata.currentBalance`}
            name={`files.${fileIndex}.metadata.currentBalance`}
            label={t('docUpload.currentBalance')}
            disabled={isSubmitting || fileWasSubmitted}
          />
          {showReadyToUpload ? (
            <ReadyToUploadComponent fileWasRejected={fileWasRejected} to="md" />
          ) : null}
        </Box>
      </Box>
      <Only from="md" />
    </Composition>
  );
}
