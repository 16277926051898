import React from 'react';
import { Box, Typography } from '@achieve/sunbeam';
import { getAppSubDomain } from 'shared-components';

function InfoCard(props) {
  const subDomain = getAppSubDomain();
  const { title, subTitle, bodyText } = props;
  return (
    <Box
      sx={(theme) => ({
        backgroundColor:
          subDomain === 'achieve'
            ? theme.sb.colors.primary.achieveBlue
            : theme.sb.colors.primary.trustDarkBlue,
        color: theme.sb.colors.neutral.white,
        width: '100%',
        height: 'auto',
        display: 'inline-block',
        textAlign: 'center',
        marginTop: '3rem'
      })}
      p="40px 96px"
    >
      <Box>
        <Typography variant="displayM10" fontWeight="medium">
          {title}
        </Typography>
      </Box>
      <Box>
        <Typography variant="displayS11CTA" fontWeight="regular">
          {subTitle}
        </Typography>
      </Box>
      <Box>
        <Typography variant="displayXS10" fontWeight="regular">
          {bodyText}
        </Typography>
      </Box>
    </Box>
  );
}

export default InfoCard;
