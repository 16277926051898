import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box } from 'atomic-layout';
import {
  CustomerServiceHours,
  FallBackLoader,
  getAppSubDomain,
  logger
} from 'shared-components';
import CreditBureauReviewMessage from './CreditBureauReviewMessage';
import { Text } from '@ffn/ui';
import { Typography } from '@ffn/sunbeam';
import 'styled-components/macro';
import {
  useBrand,
  useLaunchDarkly,
  useLocale,
  useNotificationTracking,
  useTranslation
} from 'lib/hooks';

import styles from './ClientContactFamily.module.scss';

/**
 * ClientContactFamily displays notification details for a client, including specific actions or updates
 * related to their credit bureau review process. It conditionally renders different components based on
 * the notification type and the presence of creditor details. A fallback loader is displayed if creditor
 * details are unavailable but required for the notification.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.notification - Notification data including payload, reference IDs, and a tracking label.
 * @param {Object} props.notification.payload - The payload of the notification, containing creditor details and review date.
 * @param {Array} props.notification.payload.creditorDetails - Array of creditor details objects whose keys are creditor names and values are account numbers
 * @param {string} props.notification.payload.discussCreditBureauReviewCreatedDate - Date of the credit bureau report.
 * @param {Array<string>} props.notification.referenceIds - IDs that reference specific alerts or tasks.
 * @param {string} props.notification.trackingLabel - Tracking label for analytics.
 * @param {string} props.viewLocation - Location where the notification is viewed, used for tracking.
 * @param {boolean} props.isVisible - Flag indicating if the notification is visible, used for tracking.
 *
 * @returns {JSX.Element} The ClientContactFamily component, rendering the notification details or a loader.
 */
export function ClientContactFamily({ notification, viewLocation, isVisible }) {
  const subdomain = getAppSubDomain();
  const { dateFnsLocale } = useLocale();
  const { formatDate, t } = useTranslation();
  const { activeFlagNames, fetchSingleFlag, flags } = useLaunchDarkly();
  const [eligibleToDisplayCreditors, setEligibleToDisplayCreditors] =
    useState(false);

  const dateFormat = 'MMM dd, yyyy';

  const { payload, referenceIds, trackingLabel } = notification;
  const brand = useBrand();
  const bizName = brand('business-name.abbr');
  const trackNotificationView = useNotificationTracking();
  const alertTasks = useSelector((state) => state?.models?.alerts);

  const creditorDetails = payload?.creditorDetails || [];
  const discussCreditBureauReviewCreatedDateNoLocale =
    payload?.discussCreditBureauReviewCreatedDate || '';

  const discussCreditBureauReviewCreatedDate = formatDate(
    discussCreditBureauReviewCreatedDateNoLocale,
    dateFormat,
    dateFnsLocale,
    logger
  );

  useEffect(() => {
    trackNotificationView({
      trackingLabel,
      viewLocation,
      isVisible
    });
  }, [trackingLabel, viewLocation, isVisible, trackNotificationView]);

  const thisAlertTasks = useMemo(
    () => alertTasks.filter((task) => referenceIds.includes(task.alertId)),
    [alertTasks, referenceIds]
  );

  // Creditor details may not be available when the alert is first shown
  // If the alert is for Discuss Credit Bureau Review, component may need to show a loader
  const subjectsIncludeDiscussCreditBureauReview = thisAlertTasks.some(
    (task) => task.subject === 'Discuss Credit Bureau Review'
  );

  // fetches LD flag only if the user has an active Discuss Credit Bureau Review alert and user is FDR
  useEffect(() => {
    if (subjectsIncludeDiscussCreditBureauReview && subdomain === 'fdr') {
      const fetchFlags = async () => {
        try {
          await fetchSingleFlag(activeFlagNames?.dashboardPtsDisplayCreditor);
        } catch (error) {
          logger.error('Error fetching single flag', error);
        }
      };

      fetchFlags();
    }
  }, [
    activeFlagNames,
    fetchSingleFlag,
    subdomain,
    subjectsIncludeDiscussCreditBureauReview
  ]);

  // sets eligibleToDisplayCreditors based on flag
  useEffect(() => {
    if (!payload || !flags) {
      return;
    }
    if (flags[activeFlagNames.dashboardPtsDisplayCreditor] === 'show') {
      setEligibleToDisplayCreditors(true);
    }
  }, [activeFlagNames, flags, payload]);

  // uses component for eligible users and old plain message for ineligible
  const creditBureauReview = eligibleToDisplayCreditors ? (
    <CreditBureauReviewMessage
      creditorDetails={creditorDetails}
      discussCreditBureauReviewCreatedDate={
        discussCreditBureauReviewCreatedDate
      }
    />
  ) : (
    <div>{t('alerts.clientContactFamily.ourAnalysisOfYourCreditReport')}</div>
  );

  const taskSubjectsToMessages = {
    'Missing Future Deposits': t(
      'alerts.clientContactFamily.thereAreNoProgramDeposits'
    ),
    'Settlement Signature Needed': t(
      'alerts.clientContactFamily.yourSignatureIsRequired'
    ),
    'No Welcome Call':
      t('alerts.clientContactFamily.youNeedToCompleteYour') +
      ' ' +
      bizName +
      ' ' +
      t('alerts.clientContactFamily.welcomeCall'),
    'Invalid Contact Information': t(
      'alerts.clientContactFamily.verifyYourContactInformationIsUpToDate'
    ),
    'Account Information Needed': t(
      'alerts.clientContactFamily.additionalInformation'
    ),
    'Client Information Conflict': t(
      'alerts.clientContactFamily.someOfYourPersonalInformation'
    ),
    'Program Document Issue': t(
      'alerts.clientContactFamily.oneOrMoreOfYourProgramDoc'
    ),
    'Creditor Update Available': t(
      'alerts.clientContactFamily.weReceivedUpdatedInformation'
    ),
    'Discuss Credit Bureau Review': creditBureauReview
  };

  // Create a unique list of messages for the tasks
  const taskMessages = thisAlertTasks.reduce((uniqueMessages, task) => {
    const message =
      taskSubjectsToMessages[task.subject] ||
      t('alerts.unknownTask', { subject: task.subject });
    if (!uniqueMessages.includes(message)) {
      uniqueMessages.push(message);
    }
    return uniqueMessages;
  }, []);

  // Loader if creditor details are not yet available, and the alert includes Discuss Credit Bureau Review
  if (!creditorDetails && subjectsIncludeDiscussCreditBureauReview) {
    return <FallBackLoader />;
  }

  return (
    <Box className={styles.main}>
      <Typography>
        <div className={styles.redBorder}>
          <ol>
            {taskMessages.map((alertTask) => (
              <li key={alertTask}>{alertTask}</li>
            ))}
          </ol>
        </div>
        <Text className={styles.contactMessage}>
          {t('alerts.clientContactFamily.contactCustomerServiceRightAway')}
        </Text>
        <div className={styles.greyBorder}></div>
        <CustomerServiceHours />
      </Typography>
    </Box>
  );
}

ClientContactFamily.defaultProps = {
  notification: {
    payload: {
      creditorDetails: [],
      discussCreditBureauReviewCreatedDate: ''
    },
    referenceIds: [],
    trackingLabel: ''
  },
  viewLocation: '',
  isVisible: false
};
