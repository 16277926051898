import React, { createContext, useMemo } from 'react';

export const dashboardTypeContext = createContext();

/**
 * Returns the brand based on the URL
 * When first implement the subdomain was the same as the brand, but the
 * subdomains have diverged since then
 * Requires hostname to be in /etc/hosts
 * @return {String} subdomain
 */
export function getAppSubDomain() {
  const hostname = window.location.hostname;

  if (hostname.includes('freedomdebtrelief') || hostname.includes('fdr')) {
    return 'fdr';
  } else if (hostname.includes('achieve')) {
    return 'achieve';
  } else if (hostname.includes('turnbull')) {
    return 'turnbull';
  }
  return null;
}

export function ProvideDashboardType({ children }) {
  const subDomain = getAppSubDomain();
  const value = useMemo(() => {
    return { subDomain };
  }, [subDomain]);

  return (
    <dashboardTypeContext.Provider value={value}>
      {subDomain && children}
    </dashboardTypeContext.Provider>
  );
}
