import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { Only } from 'atomic-layout';
import 'styled-components/macro';
import { Panel, PanelTitle, PanelTitleHeading, NavTabList } from '@ffn/ui';
import { FeatureFlag } from 'shared-components';
import { CustomTab } from './CustomTabs';
import { getTestIds } from 'lib/utils/dataTestIds';
import AccordionTabs from 'shared-components/DashTabs/AccordionTabs';
import { useTranslation } from 'lib/hooks';
import { CreditCard, Upload, Globe, PlusSquare } from 'react-feather';

const csTestIDs = getTestIds('customer_service');

function ServicesTabs() {
  const { t } = useTranslation();
  return (
    <>
      <NavTabList
        aria-label="My tabs"
        data-testid={csTestIDs.cs_online_services_tabs}
      >
        <FeatureFlag pathKey="/customer-service/deposits">
          <CustomTab
            icon={CreditCard}
            label={t('customerService.manageDedicatedAccount')}
            to="/customer-service/deposits"
          />
        </FeatureFlag>
        <FeatureFlag pathKey="/customer-service/bank-account-change">
          <CustomTab
            icon={Globe}
            label={t('customerService.bankAccountChange')}
            to="/customer-service/bank-account-change"
          />
        </FeatureFlag>
        <FeatureFlag pathKey="/customer-service/enroll-new-debt">
          <CustomTab
            icon={PlusSquare}
            label={t('customerService.enrollNewDebt')}
            to="/customer-service/enroll-new-debt"
          />
        </FeatureFlag>
        <FeatureFlag pathKey="/customer-service/document-upload">
          <CustomTab
            icon={Upload}
            label={t('customerService.uploadDocument')}
            to="/customer-service/document-upload"
          />
        </FeatureFlag>
      </NavTabList>
      <Outlet />
    </>
  );
}

export function OnlineServices() {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedId, setSelectedId] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    if (location.pathname === '/customer-service') {
      setSelectedId('');
    }
  }, [location, setSelectedId]);

  const handleTabSelect = (tab) => {
    setSelectedId(tab);
    navigate(`/customer-service/${tab}#${tab}`);
  };

  const config = [
    {
      id: 'deposits',
      label: t('customerService.manageDedicatedAccount'),
      title: t('customerService.manageDedicatedAccount'),
      icon: CreditCard,
      content: <Outlet />
    },
    {
      id: 'bank-account-change',
      label: t('customerService.bankAccountChange'),
      title: t('customerService.bankAccountChange'),
      icon: Globe,
      content: <Outlet />
    },
    {
      id: 'enroll-new-debt',
      label: t('customerService.enrollNewDebt'),
      title: t('customerService.enrollNewDebt'),
      icon: PlusSquare,
      content: <Outlet />
    },
    {
      id: 'document-upload',
      label: t('customerService.uploadDocument'),
      title: t('customerService.uploadDocument'),
      icon: Upload,
      content: <Outlet />
    }
  ];

  return (
    <Panel data-testid={csTestIDs.cs_online_services_container}>
      <PanelTitle>
        <PanelTitleHeading>
          {t('customerService.onlineServices')}
        </PanelTitleHeading>
      </PanelTitle>
      <Only from="lg">
        <ServicesTabs />
      </Only>
      <Only to="lg">
        <AccordionTabs
          unlimitedHeight={true}
          config={config}
          selectedId={selectedId}
          onTabClick={handleTabSelect}
        />
      </Only>
    </Panel>
  );
}

export default OnlineServices;
