import { useTranslation } from 'lib/hooks';
import { getAppSubDomain } from 'shared-components';

/**
 * This file defines the detailed content functions for the CoreQuestion components.
 * Each function represents a detailed view associated with a specific core question,
 * utilizing the `useTranslation` hook to render localized content.
 *
 * The functions are designed to match with corresponding IDs and summary keys specified
 * in the `coreQuestionContent` object, ensuring each question can be dynamically rendered
 * with appropriate localized details.
 */
const CoreQuestionHowCanISucceed = () => {
  const { t } = useTranslation();
  return (
    <section>
      <p>{t('pts.hereSAQuickChecklistToSetYourselfUp')}</p>
      <ul>
        <li>{t('pts.stopUsingAllCreditCardsEnrolledInYourProgram')}</li>
        <li>{t('pts.voluntarilyStoppingPaymentOnAllYourEnrolledDebts')}</li>
        <li>{t('pts.makeSureYouAlwaysHaveEnoughMoneyInYourBankAccount')}</li>
      </ul>
    </section>
  );
};
const CoreQuestionWhyDidTheOrderChange = () => {
  const { t } = useTranslation();
  return (
    <section>
      <p>{t('pts.weAlwaysTrackingYourProgress')}</p>
    </section>
  );
};
const CoreQuestionHowDoYouDecideWhichCreditor = () => {
  const { t } = useTranslation();
  const subdomain = getAppSubDomain();

  const settlementOverviewLinks = {
    turnbull: '/enrolled-debt/settlement-overview',
    fdr: '/dashboard/enrolled-debt/settlement-overview',
    achieve: '/dashboard/resolution/enrolled-debt/settlement-overview'
  };

  const settlementOverviewLink =
    settlementOverviewLinks[subdomain] ||
    '/dashboard/enrolled-debt/settlement-overview';

  return (
    <section>
      <p>
        {t('pts.weNegotiateWithYourCreditorsinASpecificOrder')}{' '}
        <a href={settlementOverviewLink}>{t('pts.settlementTiming')}</a>
      </p>
    </section>
  );
};
const CoreQuestionwhyWouldMySettlementTakeLonger = () => {
  const { t } = useTranslation();
  return (
    <section>
      <p>{t('pts.thisCanHappenIfYourDebtIsntPastDue')}</p>
      <ul>
        <li>{t('pts.voluntarilyStopPayingYourCreditors')}</li>
        <li>{t('pts.keepMakingDepositsSoYourDedicatedAccount')}</li>
      </ul>
    </section>
  );
};

/**
 * Maps question IDs to their respective details and summary keys for rendering CoreQuestion components.
 * Exposes the core question content.
 *
 * @type {Object.<string, {id: string, summaryKey: string, details: Function}>}
 * - `id`: A unique identifier for each question. Used for indexing content, assigning data-testids,
 *   and tracking analytics. IDs should remain constant once deployed to production to ensure data consistency.
 * - `summaryKey`: A translation key used to fetch the localized summary or title for the question.
 * - `detailsComponent`: One of the above defined core question details components
 */

export const coreQuestionContent = {
  how_can_i_succeed: {
    id: 'how_can_i_succeed',
    summaryKey: 'pts.howCanISucceedInThisProgram',
    detailsComponent: <CoreQuestionHowCanISucceed />
  },
  why_did_the_order_change: {
    id: 'why_did_the_order_change',
    summaryKey: 'pts.whyDidTheOrderChange',
    detailsComponent: <CoreQuestionWhyDidTheOrderChange />
  },
  how_do_you_decide_which_creditor: {
    id: 'how_do_you_decide_which_creditor',
    summaryKey: 'pts.howDoYouDecideWhichCreditor',
    detailsComponent: <CoreQuestionHowDoYouDecideWhichCreditor />
  },
  why_would_my_settlement_take_longer: {
    id: 'why_would_my_settlement_take_longer',
    summaryKey: 'pts.whyWouldMySettlementTakeLonger',
    detailsComponent: <CoreQuestionwhyWouldMySettlementTakeLonger />
  }
};
