import { datadogRum as ddRum } from '@datadog/browser-rum';
import { useEffect } from 'react';

import libLogger, { initializeLogger } from '@achieve/cx-logger';

import { getAppSubDomain } from 'shared-components';
import { useSelector } from 'react-redux';

import {
  APP_ENV,
  APP_VERSION,
  CLIENT_ID_APP_ID_MAP,
  DD_CONFIG_SITE,
  DD_RUM_ENABLED,
  DD_SERVICE,
  DD_SESSION_REPLAY_SAMPLE_RATE
} from './constants';

/**
 * In some cases, cx-logger may not be initialized before logging occurs.
 * We defensively initialize it with console methods here to handle
 * potential race conditions.
 */
let logger = {
  info: console.log.bind(console),
  warn: console.warn.bind(console),
  error: console.error.bind(console),
  debug: console.debug.bind(console)
};

const subdomain = getAppSubDomain();

const { DATADOG_CLIENT_TOKEN, DATADOG_APPLICATION_ID } =
  CLIENT_ID_APP_ID_MAP[subdomain.toLowerCase()];

const ddRumConfig = {
  applicationId: DATADOG_APPLICATION_ID, // The ID of your RUM application, different for achieve vs fdr (tlg)
  clientToken: DATADOG_CLIENT_TOKEN, // Client token for authentication with the DataDog API, different for achieve vs fdr (tlg)
  site: DD_CONFIG_SITE, // 'datadoghq.com' for all US sites
  service: DD_SERVICE, // the name of the service inside datadog, cx-fdr-client-dashboard
  env: APP_ENV, // DEV, STG, PRD
  version: APP_VERSION,
  sessionReplaySampleRate: Number(DD_SESSION_REPLAY_SAMPLE_RATE), // Percentage of sessions to capture for session replay
  // Additional config params
  sessionSampleRate: 10, // Percentage of sessions to track
  trackResources: true, // If set to true, the SDK tracks resource loading (e.g., AJAX requests, images)
  trackLongTasks: true, // If set to true, the SDK tracks long tasks (tasks that block the main thread for a significant time)
  trackUserInteractions: true, // If set to true, the SDK tracks user interactions like clicks, taps, and custom user actions
  defaultPrivacyLevel: 'mask-user-input' // Controls the default privacy level for your RUM data; 'mask-user-input' obscures text entered in form fields
};

const datadogOptions = {
  clientToken: DATADOG_CLIENT_TOKEN,
  env: APP_ENV,
  forwardErrorsToLogs: true,
  service: DD_SERVICE,
  version: APP_VERSION
};

export const Datadog = () => {
  const client = useSelector((state) => state?.models?.client);

  // datadog real user monitoring config https://docs.datadoghq.com/real_user_monitoring/

  const fdrApplicantId = client?.fdrApplicantId;
  const email = client?.clientEmail;

  useEffect(() => {
    const isLocalhost = window.location.hostname?.includes('local');

    const shouldDDInit = !isLocalhost && DD_RUM_ENABLED;
    // Why "local" check? Local env files are generally set to 'dev'
    // We don't want to pollute the real dev data with local data

    try {
      if (fdrApplicantId && shouldDDInit) {
        ddRum.init(ddRumConfig);
        ddRum.setUser({ fdrApplicantId });
        ddRum.startSessionReplayRecording();
        initializeLogger(datadogOptions);
        logger = libLogger.child({ email, fdrApplicantId });
      } else {
        // If we're not initializing the logger,
        // we still want to log to the console for dev
        logger = console;
      }
    } catch (error) {
      console.error('Datadog failed to initialize: ', error);
    }

    return () => {
      ddRum.stopSessionReplayRecording();
    };
  }, [email, fdrApplicantId]);

  return null;
};

export { logger };
