import styled from 'styled-components';
import { query } from 'atomic-layout';

export const Title = styled.p`
  font-weight: bold;
  font-size: 18px;
`;

export const CreditorInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 0;
`;

export const CreditorInfoText = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;

  @media ${query({ from: 'sm' })} {
    width: 300px;
    margin: 0 auto;
  }
`;

export const ContactQuestionsWrapper = styled.div`
  border-top: 1px solid ${(props) => props.theme.colors.grayLight};
  padding-top: 14px;
  margin-top: 14px;
`;
