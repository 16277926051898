import { axiosInstance as axios } from 'lib/axios';

import envConfig from 'envConfig';
import store from '../../store';
import { updateModels } from 'shared-reducers/models';

// This function will trigger a refresh of notifications data in Firestore.
// Additionally, the caller can pass an array of model names (or [*] for all models) to refresh those specific models
export const refreshNotifications = async () => {
  const apiUrl = `${envConfig.FIREBASE_FUNCTION_HOSTNAME}/bootstrap`;
  try {
    const response = await axios.get(apiUrl, {
      validateStatus: (status) => status >= 200 && status < 500
    });

    // Updating our Redux store here, so that alerts will clear
    store.dispatch(updateModels(response?.data?.models));
    return response.data;
  } catch (e) {
    throw e;
  }
};
