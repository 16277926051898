import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { getUserRecord } from 'shared-selectors/userRecord';
import { getSuipLead } from 'lib/api';
import { useAuth } from 'lib/hooks';

export function useSuipLead() {
  const userRecord = useSelector(getUserRecord);
  const [lead, setLead] = useState(null);
  const [leadFetched, setLeadFetched] = useState(false);
  const { isAuthenticated } = useAuth();

  /** Fetching lead data to determine if user should see extended PT */
  const { refetch: fetchSuipLead } = useQuery(
    'suipLead',
    async () => {
      const lead = await getSuipLead();
      return lead;
    },
    {
      /** Prevents the query from running on initial render */
      enabled: false,
      onSuccess: (data) => {
        if (data) {
          setLead(data);
        }
      },
      onError: (error) => {
        if (error.response?.status !== 404) {
          console.error('Error fetching lead data for Product Tour', error);
        }
        /***
         * Explicitly set the flag to false so we can make this value a true Boolean
         * and just not a falsy value
         */
        setLead({
          serveExtendedProductTour: false
        });
      },
      onSettled: () => {
        setLeadFetched(true);
      },
      retry: (failureCount, error) => {
        if (error.response?.status === 404) {
          return false;
        }
        return failureCount < 2;
      }
    }
  );

  useEffect(() => {
    if (!userRecord.lawFirmId || !isAuthenticated) return;

    const isFDRUser =
      userRecord?.lawFirmId === 1059 || userRecord?.lawFirmId === '1059'; // FDR

    if (!isFDRUser) {
      /***
       * If the user is not an FDR user, we don't need to fetch the lead data
       * and can just set the flag to false, since we don't want to display it.
       */
      setLeadFetched(true);
      setLead({
        serveExtendedProductTour: false
      });
    }

    if (isAuthenticated && isFDRUser) {
      /** Once we are authenticated, we can try to get the SUIP lead */
      fetchSuipLead();
    }
  }, [fetchSuipLead, userRecord, isAuthenticated]);

  return {
    fetchSuipLead,
    lead,
    leadFetched
  };
}
