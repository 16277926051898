import {
  createAnalytics,
  plugins,
  EVENT_ORIGIN,
  EVENT_ACTION,
  EVENT_TYPES
} from '@achieve/cx-event-horizon/browser';

import { tealiumIncludedFields } from './analytics/constants';
import { getAppSubDomain } from 'shared-components/ProvideDashboardType';
import envConfig from 'envConfig';

const domain = getAppSubDomain();
let appName;

if (domain === 'turnbull') {
  appName = 'Turnbull Client Dashboard';
} else if (domain === 'achieve') {
  appName = 'Achieve Resolution Dashboard';
} else {
  appName = 'FDR Client Dashboard';
}

const isDebug = envConfig.EVENT_HORIZON_DEBUG || false;

const analytics = createAnalytics({
  application: appName,
  debug: isDebug,
  plugins: [
    plugins.analyticsFreedom({
      application: appName,
      isDebug: isDebug
    }),
    plugins.analyticsTealium({
      application: appName,
      // specify fields to send to tealium
      includedFields: tealiumIncludedFields
    })
  ]
});

/* Export the instance for usage in your app */
export default analytics;
export { analytics, EVENT_ORIGIN, EVENT_ACTION, EVENT_TYPES };
