import { useSelector } from 'react-redux';
import { useLaunchDarkly } from './useLaunchDarkly';
import { getUserRecord } from 'shared-selectors/userRecord';

export function useNewLookBanner() {
  const { flags, activeFlagNames } = useLaunchDarkly();
  const userAccount = useSelector(getUserRecord);
  const TLG_LAW_FIRM_IDS = ['1001', '1001-OH', '1002', '1003'];

  const isTLG = TLG_LAW_FIRM_IDS.includes(String(userAccount?.lawFirmId));

  const featureFlagEnabled = flags[activeFlagNames.newLookBanner] === 'show';

  return { showNewLookBanner: isTLG && featureFlagEnabled };
}
