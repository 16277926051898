import { axiosInstance as axios } from 'lib/axios';

import envConfig from 'envConfig';

export const dfli = async ({ allDraftFees }) => {
  const apiUrl = `${envConfig.FIREBASE_FUNCTION_HOSTNAME}/dfli`;
  try {
    const response = await axios.post(apiUrl, allDraftFees, {
      validateStatus: (status) => status === 200
    });

    return response.data;
  } catch (error) {
    throw new Error('DATA_REQUESTS_ERROR');
  }
};
