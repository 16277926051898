import React from 'react';
import styled from 'styled-components';
import 'styled-components/macro';
import { Box, Composition, useResponsiveQuery } from 'atomic-layout';
import { Text } from '@ffn/ui';
import { useTranslation } from 'lib/hooks';
import { removalReasonMap } from './removalReasonMap';

export function DefaultStep({ notification }) {
  const { t } = useTranslation();
  const { payload } = notification;
  return (
    <>
      <Text style={{ fontWeight: 'bold' }}>
        {t('alerts.accountRemovalRequest.weVeBeenInformedThatYourAccount')}
      </Text>
      <Box
        paddingHorizontal={16}
        paddingVertical={8}
        css={`
          background: ${(props) => props.theme.colors.plain};
        `}
      >
        <DetailsDisplay notificationPayload={payload} />
      </Box>
    </>
  );
}

const CompactText = styled(Text)`
  margin-bottom: 2px;
  margin-top: 2px;
`;

function DetailsDisplay({ notificationPayload }) {
  const { t } = useTranslation();
  const { creditorName, accountNumber, requestReason } = notificationPayload;
  const isMobile = useResponsiveQuery({ from: 'xs', to: 'md' });

  const removalReason = removalReasonMap(requestReason, t);

  return (
    <Composition
      paddingHorizontal={8}
      paddingVertical={4}
      gap={isMobile ? 3 : 8}
      templateCols={isMobile ? '1fr' : '300px 1fr'}
    >
      <CompactText>
        {t('alerts.accountRemovalRequest.originalCreditor')}:
      </CompactText>
      <CompactText style={{ fontWeight: 'bold' }}>{creditorName}</CompactText>
      <CompactText>
        {t('alerts.accountRemovalRequest.accountEndingIn')}:
      </CompactText>
      <CompactText style={{ fontWeight: 'bold' }}>{accountNumber}</CompactText>
      <CompactText>
        {t('alerts.accountRemovalRequest.removalReason')}:
      </CompactText>
      <CompactText style={{ fontWeight: 'bold' }}>
        {removalReason ?? 'N/A'}
      </CompactText>
      <CompactText>
        {t('alerts.accountRemovalRequest.programImpact')}:
      </CompactText>
      <CompactText style={{ fontWeight: 'bold' }}>
        {t('alerts.accountRemovalRequest.removingThisAccountWillAllow')}
      </CompactText>
    </Composition>
  );
}
