import React from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import {
  DashDatePicker,
  DashForm,
  DashSelect
} from 'shared-components/DashFields';
import { Box, Composition } from 'atomic-layout';
import { LoadingButton, FormMessage } from '@ffn/ui';
import { ChevronThinRight } from '@styled-icons/entypo/ChevronThinRight';
import { parseISO } from 'date-fns';
import { analyticsTrackEvent } from 'lib/utils/analytics';
import { alertUpdate, refreshNotifications } from 'lib/api';
import { fromMstToLocal } from './timeFunctions';
import { useTranslation, useLocale } from 'lib/hooks';

const errorMessages = {
  DEFAULT: 'Something went wrong!'
};

const getGoodTimes = (dateString, availableTimes) => {
  const timesForDay = availableTimes[dateString];
  const goodTimes = timesForDay.map((time) => {
    const displayTime = fromMstToLocal(dateString, time);
    // 'displayTime' is the value in Local time that we'll show in the options element
    // while 'value' represents the value that we'll send to the backend when user submits the form
    // (which remains in MST, which is what the backend requires)
    return { displayTime, value: time };
  });

  return goodTimes;
};

export function ScheduleCallForm({
  successCallback,
  successRefresh,
  errorCallback,
  updateSubmittingState,
  availableTimes,
  alertId,
  trackingLabel
}) {
  const { t } = useTranslation();
  const { dateFnsLocale } = useLocale();
  const [error, setError] = React.useState(null);
  const [dateSelected, setDateSelected] = React.useState(null);
  const [timeOptions, setTimeOptions] = React.useState([]);

  React.useEffect(() => {
    if (dateSelected) {
      setTimeOptions(getGoodTimes(dateSelected, availableTimes));
    }
  }, [dateSelected, setTimeOptions, availableTimes]);

  // Calculate an array of Date objects that we want to pass to the date picker
  // Memo-izing here because this data will not change after initial render
  const daysToInclude = React.useMemo(() => {
    const validDays = Object.keys(availableTimes);
    return validDays.map((validDay) => (validDay ? parseISO(validDay) : null));
  }, [availableTimes]);

  return (
    <Formik
      initialValues={{
        callDate: '',
        callTime: ''
      }}
      validationSchema={yup.object().shape({
        callDate: yup
          .string()
          .required(t('alerts.milestoneCalls.pleaseSelectADate')),
        callTime: yup
          .string()
          .required(t('alerts.milestoneCalls.pleaseSelectATime'))
      })}
      onSubmit={async (values, formikBag) => {
        const callDate = values.callDate.toISOString().slice(0, 10);
        const clientTime = fromMstToLocal(callDate, values.callTime);
        const clientTimeFull = fromMstToLocal(
          callDate,
          values.callTime,
          true,
          dateFnsLocale
        );
        const updatePayload = [
          {
            alert_id: alertId,
            client_time: clientTime,
            request_answer: values.callTime,
            schedule_date: callDate
          }
        ];

        setError(null);

        try {
          const res = await alertUpdate(updatePayload);
          if (!res.success) {
            throw new Error(res.error_message);
          }

          // Wait 10 seconds and then refresh notifications data in Firestore, which
          // should clear any notifications that prompted the user to take this action.
          setTimeout(() => {
            refreshNotifications(['alertTasks']);
            if (typeof successRefresh === 'function') {
              successRefresh();
            }
          }, 10000);

          if (typeof successCallback === 'function') {
            successCallback(clientTimeFull);
          }
          setError(null);
          analyticsTrackEvent(
            {
              category: 'alert',
              action: 'success',
              label: trackingLabel
            },
            'Schedule Call Success'
          );
        } catch (err) {
          console.log(err);
          if (typeof errorCallback === 'function') {
            errorCallback();
          }
          setError(err.message);
          analyticsTrackEvent(
            {
              category: 'alert',
              action: 'failure',
              label: trackingLabel
            },
            'Schedule Call Failure'
          );
        } finally {
          if (typeof updateSubmittingState === 'function') {
            updateSubmittingState(false);
          }
        }
      }}
      validateOnMount
    >
      {(props) => {
        const { setFieldValue } = props;
        return (
          <>
            <DashForm>
              {error && (
                <FormMessage variant="error">
                  {errorMessages.DEFAULT}
                </FormMessage>
              )}
              <Composition gap={16} widthMd="75%">
                <DashDatePicker
                  data-test-id="call-date-picker"
                  id="callDate"
                  name="callDate"
                  label={t('alerts.milestoneCalls.selectAnAvailableDate')}
                  dateFormat="MMMM d, yyyy"
                  includeDates={daysToInclude}
                  onChange={(value) => {
                    const date = value.toISOString().slice(0, 10);
                    setFieldValue('callDate', value);
                    setDateSelected(date);
                  }}
                />
                {dateSelected && (
                  <>
                    <DashSelect
                      data-test-id="call-time-select-element"
                      id="callTime"
                      name="callTime"
                      label={t('alerts.milestoneCalls.selectAnAvailableTime')}
                    >
                      {timeOptions.map(({ value, displayTime }) => (
                        <option value={value} key={value}>
                          {displayTime}
                        </option>
                      ))}
                    </DashSelect>
                  </>
                )}
              </Composition>
              <Box widthMd="50%" paddingLeftMd={16}>
                <LoadingButton
                  type="submit"
                  variant="primary"
                  height={40}
                  width="100%"
                  isLoading={props.isSubmitting}
                  disabled={!props.isValid}
                  rightEnhancer={<ChevronThinRight size={14} />}
                  data-testid="schedule-call-submit-button"
                  onClick={() => {
                    if (typeof updateSubmittingState === 'function') {
                      updateSubmittingState(true);
                    }
                  }}
                >
                  {t('alerts.milestoneCalls.scheduleCall')}
                </LoadingButton>
              </Box>
            </DashForm>
          </>
        );
      }}
    </Formik>
  );
}
