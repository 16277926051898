import React, { useState, useEffect, useMemo } from 'react';
import Carousel from 'react-material-ui-carousel';
import { getAppSubDomain } from 'shared-components';
import FinalAckScreen from 'shared-components/EduWizard/FinalAckScreen';
import LessonAckScreen from 'shared-components/EduWizard/LessonAckScreen';
import { Box, Typography, useTheme, IconButton } from '@achieve/sunbeam';
import ProgressBar from 'shared-components/EduWizard/EduCallout/ProgressBar';
import { analyticsTrackEvent } from 'lib/utils/analytics';
import { Paper } from '@mui/material';
import { parseRichText } from 'lib/utils';
import useStyles from './EduCarousal.styles';
import { useClientEducation } from 'lib/hooks';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSelector } from 'react-redux';
import { getUserEduData } from 'shared-selectors/userRecord';
import { useCallback } from 'react';

function EduCarousel({ lessonData, isMobile }) {
  const { trackingId, slideData, nextLessonData } = lessonData;
  const eduUserData = useSelector(getUserEduData);
  const { setLessonData } = useClientEducation();
  const { classes } = useStyles();
  const [hideAckScreen, setHideAckScreen] = useState(false);
  const subDomain = getAppSubDomain();
  const setInitialSlide = useCallback(() => {
    const currentLesson =
      eduUserData?.lessons && eduUserData?.lessons[trackingId]
        ? eduUserData?.lessons[trackingId]
        : null;
    const firstSlide =
      currentLesson && currentLesson?.slide ? currentLesson?.slide : 0;
    return firstSlide;
  }, [eduUserData, trackingId]);
  const [currentSlide, setCurrentSlide] = useState(setInitialSlide);

  const isFinalSlide = currentSlide === slideData.length;
  // Track whether the lesson is completed in the user record from the DB
  const lessonIsCompleted =
    eduUserData?.lessons && // New user
    eduUserData?.lessons[trackingId] && // First-time viewing lesson content
    eduUserData?.lessons[trackingId].status === 2; // User has completed lesson

  /**
   * Mark the lesson as complete when the user reaches the final slide
   */
  useEffect(() => {
    if (isFinalSlide) {
      setLessonData(trackingId, {
        status: 2 // Complete
      });
    }
  }, [isFinalSlide, setLessonData, trackingId]);

  /**
   * Persist the slide progress and mark the lesson as in-progress
   */
  useEffect(() => {
    if (!isFinalSlide && !lessonIsCompleted) {
      setLessonData(trackingId, {
        status: 1, // In-progress
        slide: currentSlide
      });
    }
  }, [
    isFinalSlide,
    trackingId,
    setLessonData,
    currentSlide,
    lessonIsCompleted
  ]);

  const resetCarouselIndex = useCallback(() => {
    setCurrentSlide(0);
    /**
     *  Using this to programmatically hide the ack screen so that the old ack screen
     * is no longer rendered to accommodate animation to the next lesson.
     * If we didn't do this, we'd see the next lesson's ack screen for the duration of
     * the transition animation.
     */
    setHideAckScreen(true);
  }, []);

  const slideDataWithAckScreen = useMemo(() => {
    const updatedSlideData = [
      ...slideData,
      {
        fields: {
          slideRichText: !nextLessonData ? (
            <FinalAckScreen
              trackingId={trackingId}
              hideAckScreen={hideAckScreen}
            />
          ) : (
            <LessonAckScreen
              trackingId={trackingId}
              lessonPreview={nextLessonData}
              resetCarouselIndex={resetCarouselIndex}
              hideAckScreen={hideAckScreen}
            />
          )
        }
      }
    ];

    return updatedSlideData;
  }, [
    slideData,
    trackingId,
    hideAckScreen,
    nextLessonData,
    resetCarouselIndex
  ]);

  const nextButtonClick = async (slide) => {
    setCurrentSlide(currentSlide + 1);
    setHideAckScreen(false);

    const label = `client_edu_v1_1_${trackingId}_${slide + 1}_next`;
    analyticsTrackEvent(
      {
        category: 'edu',
        action: 'next_slide',
        label
      },
      'slide nav click'
    );
    if (currentSlide === slideData.length - 1) {
      analyticsTrackEvent(
        {
          category: 'edu',
          action: 'completion',
          label: `client_edu_v1_1_${trackingId}`
        },
        'lesson completed'
      );
    }
  };

  const previousButtonClick = async (slide) => {
    setCurrentSlide(currentSlide - 1);
    const label = `client_edu_v1_1_${trackingId}_${slide + 1}_back`;
    analyticsTrackEvent(
      {
        category: 'edu',
        action: 'back_slide',
        label
      },
      'slide nav click'
    );
  };

  return (
    <Box
      sx={{
        overflowY: 'auto'
      }}
    >
      <Carousel
        autoPlay={false}
        cycleNavigation={false}
        swipe={false}
        indicators={false}
        next={() => nextButtonClick(currentSlide)}
        prev={() => previousButtonClick(currentSlide)}
        navButtonsWrapperProps={{
          className: [isMobile ? classes.navButton : classes.navButtonFull]
        }}
        NavButton={({ onClick, next, prev }) => {
          return (
            <div
              style={{
                textAlign: next ? 'left' : 'right',
                left: 'calc(100% - 250px) !important'
              }}
            >
              <IconButton
                onClick={onClick}
                className={classes.btn}
                sx={{
                  backgroundColor:
                    subDomain === 'achieve'
                      ? '#3300FF !important'
                      : '#041B93 !important'
                }}
              >
                {next && (
                  <ArrowForwardIcon
                    className={classes.btnIcon}
                    data-testid="lesson-arrow-button-forward"
                  />
                )}
                {prev && (
                  <ArrowBackIcon
                    className={classes.btnIcon}
                    data-testid="lesson-arrow-button-back"
                  />
                )}
              </IconButton>
            </div>
          );
        }}
        navButtonsAlwaysVisible={true}
        index={currentSlide}
      >
        {slideDataWithAckScreen.map((slide, i) => {
          const content = slide.fields.slideRichText;
          const completed = (100 * (i + 1)) / slideDataWithAckScreen.length;
          return (
            <div key={i}>
              <Box px="20px">
                <ProgressBar completed={completed} />
              </Box>
              {i === 0 ? <LessonIntroBlock lessonData={lessonData} /> : null}
              {content?.content ? (
                <Paper
                  sx={{
                    border: 'none',
                    borderWidth: '0px',
                    borderRadius: '0px',
                    padding: '1rem'
                  }}
                >
                  {parseRichText(slide.fields.slideRichText)}
                  <p className={classes.btnNudge} />
                </Paper>
              ) : (
                <Box height="130vh">{content}</Box>
              )}
            </div>
          );
        })}
      </Carousel>
      <span className={classes.btnHolder} />
    </Box>
  );
}
export default EduCarousel;

function LessonIntroBlock({ lessonData }) {
  const theme = useTheme();
  const { title, lessonHeroImage } = lessonData;
  const lessonHeroUrl = lessonHeroImage?.fields?.file?.url;

  if (!lessonHeroUrl) return null;

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      width="100%"
      height="188px"
      color={theme.sb.colors.neutral.white}
      sx={{
        backgroundImage: `url(${lessonHeroUrl})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover'
      }}
    >
      <Typography variant="displayS30" fontWeight="bold" width="65%">
        {title}
      </Typography>
    </Box>
  );
}
