import { axiosInstance as axios } from 'lib/axios';

import store from '../../store';
import {
  messageActioned,
  messageRead,
  messageTrashed
} from '../../shared-reducers/models';
import envConfig from 'envConfig';

export async function markRead(messageId) {
  const apiUrl = `${envConfig.FIREBASE_FUNCTION_HOSTNAME}/messages`;
  try {
    const response = await axios.post(
      apiUrl,
      { messageId, operation: 'read' },
      {
        validateStatus: (status) => status === 200
      }
    );

    if (response.status !== 200) {
      const message = 'ERROR_MARKING_MESSAGE_READ';
      throw new Error(message);
    }
    // Update our local copy to reflect the server state
    store.dispatch(messageRead({ messageId }));

    return await response.data;
  } catch (error) {
    throw error;
  }
}

export async function markTrashed(messageId) {
  const apiUrl = `${envConfig.FIREBASE_FUNCTION_HOSTNAME}/messages`;
  try {
    await axios.post(
      apiUrl,
      { messageId, operation: 'trash' },
      {
        validateStatus: (status) => status === 200
      }
    );
    // Update our local copy to reflect the server state
    store.dispatch(messageTrashed({ messageId }));
  } catch (error) {
    throw new Error('ERROR_MARKING_MESSAGE_TRASHED');
  }
}

export async function markActioned(messageId) {
  const apiUrl = `${envConfig.FIREBASE_FUNCTION_HOSTNAME}/messages`;

  try {
    const response = await axios.post(
      apiUrl,
      { messageId, operation: 'action' },
      {
        validateStatus: (status) => status === 200
      }
    );

    store.dispatch(messageActioned({ messageId }));

    return await response.data;
  } catch (error) {
    throw new Error('ERROR_MARKING_MESSAGE_ACTIONED');
  }
}
