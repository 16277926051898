import { axiosInstance as axios } from 'lib/axios';

import envConfig from 'envConfig';

function formatDate(date) {
  const [month, day, year] = date
    .toLocaleDateString('en-us', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric'
    })
    .split('/');
  return `${year}-${month}-${day}`;
}

export const updateDebtAccount = async (metadata) => {
  try {
    const { fdr_sf_debt_account_id, dateOfService, methodOfService } = metadata;
    const endpoint = `${envConfig.FIREBASE_FUNCTION_HOSTNAME}/debtAccountUpdate`;
    const methodOfServiceIsMail = methodOfService === 'Mail';
    const formattedDate = formatDate(dateOfService);
    const sfObj = {
      debt_account_updates: {
        debt_account_id: fdr_sf_debt_account_id,
        method_of_service: metadata?.methodOfService,
        date_mail_received: methodOfServiceIsMail ? formattedDate : null,
        lit_service_date: !methodOfServiceIsMail ? formattedDate : null
      }
    };

    const response = await axios.put(endpoint, sfObj, {
      validateStatus: (status) => status === 200 || status === 500
    });

    if (
      response.status === 500 &&
      Object.values(response.data).every((val) => val === null)
    ) {
      return true;
    }

    throw response.data;
  } catch (error) {
    console.error(error);
    throw new Error('Failed SF update');
  }
};
