import '@reactour/popover/dist/index.css';
// file contains the styles for the popover element's arrow, "shape",
// and navigation dots. For styling the content inside of each popover
// see the productTourSteps.js file
// dynamic style adds a triangle shaped popover "arrow" to the popover element
// you can alter its shape with the width and height variables
// adapted from https://github.com/elrumordelaluz/reactour/blob/main/apps/web/pages/popover.tsx
function doArrow(position, verticalAlign, horizontalAlign) {
  if (!position || position === 'custom') {
    return {};
  }
  const oppositeSide = {
    top: 'bottom',
    bottom: 'top',
    right: 'left',
    left: 'right'
  };
  const width = 25;
  const height = 12;
  const color = 'white';
  const isVertical = position === 'top' || position === 'bottom';
  const spaceFromSide = 10;
  const obj = {
    [`--rtp-arrow-${
      isVertical ? oppositeSide[horizontalAlign] : verticalAlign
    }`]: height + spaceFromSide + 'px',
    [`--rtp-arrow-${oppositeSide[position]}`]: -height + 2 + 'px',
    [`--rtp-arrow-border-${isVertical ? 'left' : 'top'}`]: `${
      width / 2
    }px solid transparent`,
    [`--rtp-arrow-border-${isVertical ? 'right' : 'bottom'}`]: `${
      width / 2
    }px solid transparent`,
    [`--rtp-arrow-border-${position}`]: `${height}px solid ${color}`
  };
  return obj;
}

// styles the shape of the popover element
const popover = {
  borderRadius: '10px',
  maxWidth: '360px'
};

export const customPopover = {
  popover: (base, state) => {
    return {
      ...base,
      ...popover,
      ...doArrow(state.position, state.verticalAlign, state.horizontalAlign)
    };
  }
};
