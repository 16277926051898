import React from 'react';
import { Box, Button, Dialog, Typography } from '@achieve/sunbeam';

import { analyticsTrackEvent } from 'lib/utils/analytics';
import { useTranslation, useAppPromo } from 'lib/hooks';
import { AppPromoBody } from './PromoBody';
import { AppStoreBadgeButton } from './AppStoreBadgeButton';
import { getDeviceType } from 'lib/utils';

/**
 * Tracks interactions with the app promotion modal
 * @param {string} actionType - The type of interaction (e.g., 'download', 'dismiss')
 */
const handleAppCTAInteraction = (actionType) => {
  analyticsTrackEvent(
    {
      category: 'native_app_cta',
      action: actionType,
      label: 'native_app_cta_modal_interaction'
    },
    // Uppercasing first letter for formatting
    `Native App Download CTA ${
      actionType.charAt(0).toUpperCase() + actionType.slice(1)
    }`
  );
};

/**
 * Modal component for app promotion that displays promo content app store badges
 * Shows one badge for known devices (iOS/Android) or both for unknown devices
 */
export const Modal = () => {
  const { showPromo, dismissPromo } = useAppPromo('modal');
  const { t } = useTranslation();
  const deviceType = getDeviceType();

  // Device-specific display logic
  const showSingleBadge = deviceType === 'iOS' || deviceType === 'Android';
  const showBothBadges = deviceType === 'unknown';

  return (
    <Box data-testid="native-app-cta-modal">
      {/*** Adding z-index to prevent conflicts with Product Tour. This should appear above everything. */}
      <Dialog open={showPromo} sx={{ zIndex: 111111 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
            padding: '24px'
          }}
        >
          <AppPromoBody />
          {showBothBadges && (
            <Box
              sx={{
                borderTop: '1px solid #D3D3D3',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                marginTop: '16px',
                paddingTop: '38px',
                gap: '16px'
              }}
              data-testid="native-app-cta-modal-store-badges"
            >
              <AppStoreBadgeButton
                analyticsCallback={handleAppCTAInteraction}
                deviceType="iOS"
                promoType="modal"
              />
              <AppStoreBadgeButton
                analyticsCallback={handleAppCTAInteraction}
                deviceType="Android"
                promoType="modal"
              />
            </Box>
          )}
          {showSingleBadge && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%'
              }}
            >
              <AppStoreBadgeButton
                analyticsCallback={handleAppCTAInteraction}
                deviceType={deviceType}
                promoType="modal"
              />
            </Box>
          )}
          <Button
            data-testid="native-app-cta-dismiss-button"
            variant="text"
            onClick={() => {
              handleAppCTAInteraction('dismiss');
              dismissPromo();
            }}
          >
            <Typography variant="ascendLabelLg" fontWeight="bold">
              {t('appPromo.remindMeLater')}
            </Typography>
          </Button>
        </Box>
      </Dialog>
    </Box>
  );
};

export default Modal;
