import React from 'react';
import { useBrand } from 'lib/hooks';
import 'styled-components/macro';
import { Only, useResponsiveQuery } from 'atomic-layout';
import { FacebookWithCircle } from '@styled-icons/entypo-social/FacebookWithCircle';
import { TwitterWithCircle } from '@styled-icons/entypo-social/TwitterWithCircle';
import { YoutubeWithCircle } from '@styled-icons/entypo-social/YoutubeWithCircle';
import { LinkedinWithCircle } from '@styled-icons/entypo-social/LinkedinWithCircle';
import {
  Footer as FDRFooter,
  FooterItem,
  FooterSeparator,
  Link,
  Column,
  Row,
  Container,
  Icon
} from '@ffn/ui';
import parse from 'url-parse';

export function LeadDocUploadFooter() {
  const inlineRange = useResponsiveQuery({ from: 'md' });
  const largeRange = useResponsiveQuery({ to: 'xl' });
  const brand = useBrand();
  const now = new Date();
  const currentYear = now.getFullYear();

  return (
    <FDRFooter>
      <Container>
        <Row>
          <Column cols={12} colsLg={6}>
            {brand('site.url') && (
              <FooterItem inline={inlineRange} separator={inlineRange}>
                <Link href={brand('site.url')} target="_blank">
                  {parse(brand('site.url'), {}).hostname}
                </Link>
              </FooterItem>
            )}
          </Column>
          <Column
            cols={12}
            colsLg={4}
            marginTop={10}
            marginTopLg={0}
            css={`
              text-align: ${largeRange ? 'left' : 'right'};
            `}
          >
            &copy; {currentYear}. All rights reserved.{' '}
            {brand('business-name.legal')}.
          </Column>
          <Column
            cols={12}
            colsLg={2}
            marginTop={10}
            marginTopLg={0}
            css={`
              text-align: ${largeRange ? 'center' : 'right'};
            `}
          >
            <Only to="lg">
              <FooterSeparator />
            </Only>
            {brand('socials.facebook') && (
              <Link
                css={`
                  margin-left: 7px;
                `}
                href={brand('socials.facebook')}
                target="_blank"
              >
                <Icon icon={FacebookWithCircle} color="plain" size={30} />
              </Link>
            )}
            {brand('socials.youtube') && (
              <Link
                css={`
                  margin-left: 7px;
                `}
                href={brand('socials.youtube')}
                target="_blank"
              >
                <Icon icon={YoutubeWithCircle} color="plain" size={30} />
              </Link>
            )}
            {brand('socials.twitter') && (
              <Link
                css={`
                  margin-left: 7px;
                `}
                href={brand('socials.twitter')}
                target="_blank"
              >
                <Icon icon={TwitterWithCircle} color="plain" size={30} />
              </Link>
            )}
            {brand('socials.linkedin') && (
              <Link
                css={`
                  margin-left: 7px;
                `}
                href={brand('socials.linkedin')}
                target="_blank"
              >
                <Icon icon={LinkedinWithCircle} color="plain" size={30} />
              </Link>
            )}
          </Column>
        </Row>
        <FooterSeparator />

        {(brand('site.cali-privacy-url') || brand('site.do-not-sell-site')) && (
          <Row>
            <Column
              cols={12}
              css={`
                text-align: center;
              `}
            >
              <strong>California Residents</strong>
              {brand('site.cali-privacy-url') && (
                <>
                  <br />
                  <Link
                    css={`
                      color: ${(props) => props.theme.colors.plain};
                    `}
                    href={brand('site.cali-privacy-url')}
                    target="_blank"
                    underline
                  >
                    California Privacy Notice
                  </Link>
                </>
              )}
              {brand('site.do-not-sell-site') && (
                <>
                  <br />
                  <Link
                    css={`
                      color: ${(props) => props.theme.colors.plain};
                    `}
                    href={brand('site.do-not-sell-site')}
                    target="_blank"
                    underline
                  >
                    Do Not Sell My Personal Information
                  </Link>
                </>
              )}
            </Column>
          </Row>
        )}
      </Container>
    </FDRFooter>
  );
}

export default LeadDocUploadFooter;
