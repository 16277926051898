import React, { useState } from 'react';

import { Accordion, logger, ThumbsUpDown } from 'shared-components';
import { analyticsTrackEvent } from 'lib/utils/analytics';
import { ArrowDown, ArrowUp } from 'react-feather';

import { Typography } from '@achieve/sunbeam';
import { useProgramDetails, useTranslation } from 'lib/hooks';

import { Row } from './Row';
import { Section } from './Section';

import styles from './ProgramDetails.module.scss';

const LOGGING_PREFIX = 'PROGRAM DETAILS :: ';

const IconCollapsed = () => {
  return <ArrowDown strokeWidth={3} size={30} color="#fff" />;
};

const IconExpanded = () => {
  return <ArrowUp strokeWidth={3} size={30} color="#fff" />;
};

export const ProgramDetails = () => {
  const { pureT, t } = useTranslation();
  const { programDetails } = useProgramDetails();

  const [isExpanded, setIsExpanded] = useState(true);

  const handleAccordionToggle = (isExpanded) => {
    analyticsTrackEvent(
      {
        category: 'cfos_program_details',
        action: 'clicked',
        label: isExpanded ? 'expanded' : 'collapsed'
      },
      'CFOS Program Details ' + (isExpanded ? 'Expanded' : 'Collapsed')
    );
  };

  if (programDetails) {
    const {
      clientSignDate,
      monthlyPaymentPercentages,
      monthlySavings,
      previousLength,
      previousMonthlyPayment,
      previousTotalCost,
      programLength,
      programLengthPercentages,
      programMonthlyPayment,
      programTotalCost,
      totalCostPercentages
    } = programDetails;

    if (
      !clientSignDate ||
      !monthlySavings ||
      !previousLength ||
      !previousMonthlyPayment ||
      !previousTotalCost ||
      !programLength ||
      !programMonthlyPayment ||
      !programTotalCost
    ) {
      logger.error(`${LOGGING_PREFIX} Missing necessary data`, programDetails);
      return null;
    }
    const displayProgramLength = programLengthPercentages?.program !== 100;

    return (
      <div className={styles?.programDetailsBorder}>
        <div className={styles?.programDetailsHeading}>
          <div
            className={styles.mainHeading}
            data-testid="program-details-main-heading"
          >
            <Typography variant="ascendDisplaySm">
              {pureT('programDetails.youReSaving', { monthlySavings })}
            </Typography>
          </div>
          <div
            className={styles.subHeading}
            data-testid="program-details-sub-heading"
          >
            <Typography variant="ascendBodySm">
              <p>{t('programDetails.youVeBeenMakingGreatProgress')}</p>
            </Typography>
            <Typography variant="ascendBodySm">
              <span>
                {pureT('programDetails.theseEstimatesAreFrom', {
                  clientSignDate
                })}
                *:
              </span>
            </Typography>
          </div>
        </div>
        <div className={styles?.programDetailsCharts}>
          {/* Section 1 You're paying less each month*/}
          <Section
            id="you-re-paying-less-each-month"
            title={t('programDetails.youRePayingLessEachMonth')}
            supNumber={1}
          >
            <Row
              amount={programMonthlyPayment}
              id={'program-monthly-payment'}
              isDollars={true}
              isHighlighted={monthlyPaymentPercentages?.program < 100}
              label={t('programDetails.ourSolution')}
              percentage={monthlyPaymentPercentages?.program}
              unit={t('programDetails.mo')}
            />
            <Row
              amount={previousMonthlyPayment}
              id={'previous-monthly-payment'}
              isDollars={true}
              isHighlighted={monthlyPaymentPercentages?.previous < 100}
              label={t('programDetails.previousMinimumPayments')}
              percentage={monthlyPaymentPercentages?.previous}
              unit={t('programDetails.estMoWithInterest')}
            />
          </Section>
          <Accordion
            buttonColor="#33B083"
            collapseButtonAlt={t('programDetails.collapseSavingsSection')}
            expandButtonAlt={t('programDetails.expandSavingsSection')}
            heading={t('programDetails.exploreAllYourSavings')}
            IconCollapsed={IconCollapsed}
            IconExpanded={IconExpanded}
            id="program-details"
            isExpanded={isExpanded}
            onToggle={handleAccordionToggle}
            setIsExpanded={setIsExpanded}
          >
            <div className={styles.accordionContainer}>
              <hr />
              {/* Section 2 You're getting rid of debt faster */}
              {displayProgramLength && (
                <div>
                  <Section
                    id="getting-rid-of-debt"
                    title={t('programDetails.youReGettingRidOfDebtFaster')}
                    supNumber={2}
                  >
                    <Row
                      amount={programLength}
                      id={'program-length'}
                      isHighlighted={programLengthPercentages?.program < 100}
                      isExpanded={isExpanded}
                      label={t('programDetails.ourSolution')}
                      percentage={programLengthPercentages?.program}
                      unit={t('programDetails.estMonths')}
                    />
                    <Row
                      amount={previousLength}
                      id={'previous-length'}
                      isHighlighted={programLengthPercentages?.previous < 100}
                      isExpanded={isExpanded}
                      label={t('programDetails.previousSolution')}
                      percentage={programLengthPercentages?.previous}
                      unit={t('programDetails.estMonths')}
                    />
                  </Section>
                  <hr />
                </div>
              )}
              {/* Section 3 You're paying less that what you owe */}
              <Section
                title={t('programDetails.youRePayingLessThanWhatYouOwe')}
                supNumber={3}
              >
                <Row
                  amount={programTotalCost}
                  id={'program-total-cost'}
                  isDollars={true}
                  isHighlighted={totalCostPercentages?.program < 100}
                  isExpanded={isExpanded}
                  label={t('programDetails.ourSolution')}
                  percentage={totalCostPercentages?.program}
                  unit={t('programDetails.estCostWithFees')}
                />
                <Row
                  amount={previousTotalCost}
                  id={'previous-total-cost'}
                  isDollars={true}
                  isHighlighted={totalCostPercentages?.previous < 100}
                  isExpanded={isExpanded}
                  label={t('programDetails.previousSolution')}
                  percentage={totalCostPercentages?.previous}
                  unit={t('programDetails.estWithInterest')}
                />
              </Section>
              <div
                className={styles.finePrint}
                data-testid="program-details-fine-print"
              >
                <Typography variant="ascendBodySm">
                  <span>
                    *{t('programDetails.theseEstimatesMayNotBeAccurateIf')}:
                  </span>
                  <ul>
                    <li>{t('programDetails.youChangedYourDepositAmount')}</li>
                    <li>
                      {t(
                        'programDetails.yourEnrolledDebtBalancesHaveBeenUpdated'
                      )}
                    </li>
                    <li>{t('programDetails.youAddedOrRemovedAccounts')}</li>
                  </ul>
                </Typography>
              </div>
              <ThumbsUpDown featureKey="cfos_program_details" />
            </div>
          </Accordion>
        </div>
      </div>
    );
  }
};
