import React from 'react';
import * as R from 'ramda';
import { css } from 'styled-components/macro';
import { SortableTable, Panel } from '@ffn/ui';
import { useSelector } from 'react-redux';
import { getSuipDebtAccountsByType } from 'shared-selectors/suipDebtAccounts';
import { Currency } from 'shared-components';
import { Box } from 'atomic-layout';
import { Title } from 'shared-components/TabNavTitle';
import { useTranslation } from 'lib/hooks';
// Doing this to prevent linter warning about unused 'css' import, even though we're using it in props below
css(``);

function renderSharedColumns(t) {
  return {
    creditor: {
      Header: `${t('onboarding.content.enrolledDebts.creditor')}`,
      accessor: 'name',
      width: '1fr',
      sortOptions: ['A-Z', 'Z-A'],
      Cell: ({ value }) => {
        return (
          <Box flex flexDirection="column">
            <span>{value}</span>
          </Box>
        );
      }
    },
    balanceToEnroll: {
      Header: `${t('onboarding.content.enrolledDebts.balanceToEnroll')}`,
      accessor: 'estimatedDebt',
      width: '1fr',
      sortOptions: [t('common.lowHigh'), t('common.highLow')],
      mobile: false,
      Cell: ({ value }) => {
        return (
          <Box flex flexDirection="column">
            <Currency value={value} decimalScale={0} />
          </Box>
        );
      }
    },
    accountNumber: {
      Header: `${t('onboarding.content.enrolledDebts.accountNumber')}`,
      accessor: 'accountNumber',
      width: '1fr',
      mobile: false,
      sortOptions: [],
      Cell: ({ value }) => {
        return (
          <Box flex flexDirection="column">
            {value}
          </Box>
        );
      }
    },
    minimumPayment: {
      Header: `${t('onboarding.content.enrolledDebts.minimumPayment')}`,
      accessor: 'currentMonthlyPayment',
      width: '1fr',
      mobile: false,
      sortOptions: [t('common.lowHigh'), t('common.highLow')],
      Cell: ({ value }) => {
        return (
          <Box flex alignItems="center">
            {value ? <Currency value={value} decimalScale={0} /> : 'N/A'}
          </Box>
        );
      }
    }
  };
}

function basicRenderDetail({ row }) {
  return R.difference(row.allCells, row.cells).map((cell) => {
    return (
      // eslint-disable-next-line react/jsx-key
      <Box
        flex
        justifyContent="space-between"
        padding={5}
        marginHorizontal={5}
        css={css`
          border-top: 1px solid ${(props) => props.theme.colors.grayRegular};
          width: 80%;
        `}
      >
        <div>{cell.column.render('Header')}</div>
        <div>{cell.render('Cell')}</div>
      </Box>
    );
  });
}

export function MyDebts() {
  const { t } = useTranslation();

  const suipAccountsByType = useSelector(getSuipDebtAccountsByType);
  const columns = [
    renderSharedColumns(t).creditor,
    renderSharedColumns(t).balanceToEnroll,
    renderSharedColumns(t).accountNumber,
    renderSharedColumns(t).minimumPayment
  ];
  const renderDetail = React.useCallback(basicRenderDetail, []);
  // const rowHandler = path("/enrolled-debt/:accountId")
  //   ? ({ tag }) => navigate(`/enrolled-debt/${tag}`)
  //   : undefined;
  return (
    <>
      <Panel>
        {suipAccountsByType.debtAccounts.length === 0 ? null : (
          <>
            <Title>
              {t('onboarding.content.enrolledDebts.debtsIWantToEnroll')}
            </Title>
            <SortableTable
              columns={columns}
              data={suipAccountsByType.debtAccounts}
              renderDetails={renderDetail}
            />
          </>
        )}

        {suipAccountsByType.eligibleAccounts.length === 0 ? null : (
          <>
            <Title>
              {t('onboarding.content.enrolledDebts.debtsIDoNotWantToEnroll')}
            </Title>
            <SortableTable
              columns={columns}
              data={suipAccountsByType.eligibleAccounts}
              renderDetails={renderDetail}
            />
          </>
        )}

        {suipAccountsByType.nonEligibleAccounts.length === 0 ? null : (
          <>
            <Title>
              {t('onboarding.content.enrolledDebts.debtsCannotBeEnrolled')}
            </Title>
            <SortableTable
              columns={columns}
              data={suipAccountsByType.nonEligibleAccounts}
              renderDetails={renderDetail}
            />
          </>
        )}
      </Panel>
    </>
  );
}
