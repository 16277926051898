import { axiosInstance as axios } from 'lib/axios';
import { getLawFirmName } from 'lib/utils';
import getApiUrl from 'lib/utils/getApiUrl';

export const blogCategories = async ({ lawFirmId }) => {
  const apiUrl = getApiUrl();
  const url = `${apiUrl}/content/blogCategories`;

  try {
    const response = await axios.get(url, {
      validateStatus: (status) => status >= 200 && status < 500,
      params: {
        brand: getLawFirmName(lawFirmId)
      }
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export default blogCategories;
