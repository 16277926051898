import React from 'react';
import { Formik } from 'formik';
import { DashForm, DashSelect } from 'shared-components';
import { NavLink } from 'react-router-dom';
import { Button, Icon, Text } from '@ffn/ui';
import { ChevronThinRight } from '@styled-icons/entypo/ChevronThinRight';
import { UploadOptions } from '../utils';
import i18next from 'i18next';
import { useTranslation } from 'lib/hooks';

export const DropDownOptions = ({
  initialValues = {
    documentType: i18next.t('docUpload.accountStatementS'),
    path: '/customer-service/document-upload/account-statements',
    description: i18next.t('docUpload.billOrStatementFromACreditor')
  }
}) => {
  const { t } = useTranslation();
  return (
    <Formik initialValues={initialValues}>
      {({ setValues, values }) => (
        <DashForm>
          <DashSelect
            id="documentType"
            name="documentType"
            label={t('docUpload.documentIAmUploading')}
            onChange={({ target }) => {
              const { documentType, path, description } =
                target.selectedOptions[0].dataset;
              setValues({
                documentType,
                path,
                description
              });
            }}
          >
            <UploadOptions card={false} />
          </DashSelect>
          <NavLink
            to={values.path}
            style={{ textDecoration: 'none', width: '100%' }}
          >
            <Button
              variant="primary"
              rightEnhancer={<Icon icon={ChevronThinRight} size={14} />}
              box-sizing="border-box"
            >
              Next
            </Button>
          </NavLink>
          <Text
            css={`
              margin: 0;
            `}
          >
            <em>{values.description}</em>
          </Text>
        </DashForm>
      )}
    </Formik>
  );
};
