import React, { Suspense, lazy } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { css } from 'styled-components/macro';
import { FallBackLoader, DashTabs } from 'shared-components';
import { Panel, Row, Column, Icon } from '@ffn/ui';
import {
  Alert2,
  BankAccountChange,
  EnrolledDebt,
  Notifications
} from '@ffn/icons';
import { useFeatureFlag } from 'lib/hooks';
import { MyDebts } from '../MyDebts';
import { useTranslation } from 'lib/hooks';
const PersonalInfo = lazy(() => import('../PersonalInfo'));
const Notices = lazy(() => import('../Notices'));
const BankingInfo = lazy(() => import('../BankingInfo'));

export function TabNav({ sub }) {
  const { t } = useTranslation();
  css(``);
  const navigate = useNavigate();
  const { path } = useFeatureFlag();
  // Initializing to the default state we want to show here
  const [tabId, setTabId] = React.useState(null);
  const { pathname } = useLocation();
  // Set the appropriate tab id based on the URL when pathname changes
  React.useEffect(() => {
    let tabIdOnMount;
    switch (pathname) {
      case '/enrolled-debts':
        tabIdOnMount = 'enrolled-debts';
        break;
      case '/personal-info':
        tabIdOnMount = 'personal-info';
        break;
      case '/important-notices':
        tabIdOnMount = 'important-notices';
        break;
      case '/banking-info':
        tabIdOnMount = 'banking-info';
        break;
      default:
        tabIdOnMount = 'important-notices';
        break;
    }
    setTabId(tabIdOnMount);
  }, [pathname]);

  const handleTabSelect = (tab) => {
    setTabId(tab);
    navigate(`/${tab}`);
  };

  const tabConfig = [];

  if (path('/enrolled-debts')) {
    tabConfig.push({
      id: 'enrolled-debts',
      title: (
        <>
          <Row marginBottom={4} paddingTop={12}>
            <Column cols={2} colsLg={12}>
              <Icon icon={EnrolledDebt} size={30} />
            </Column>
            <Column cols={10} colsLg={12} paddingTop={4} paddingTopLg={0}>
              {t('onboarding.nav.enrolledDebts')}
            </Column>
          </Row>
        </>
      ),
      content: (
        <Suspense fallback={<FallBackLoader />}>
          <MyDebts />
        </Suspense>
      )
    });
  }

  if (path('/personal-info')) {
    tabConfig.push({
      id: 'personal-info',
      title: (
        <>
          <Row marginBottom={4} paddingTop={12}>
            <Column cols={2} colsLg={12}>
              <Icon icon={Notifications} size={30} />
            </Column>
            <Column cols={10} colsLg={12} paddingTop={4} paddingTopLg={0}>
              {t('onboarding.nav.personalInfo')}
            </Column>
          </Row>
        </>
      ),
      content: (
        <Suspense fallback={<FallBackLoader />}>
          <PersonalInfo />
        </Suspense>
      )
    });
  }

  if (path('/important-notices')) {
    tabConfig.push({
      id: 'important-notices',
      title: (
        <>
          <Row marginBottom={4} paddingTop={12}>
            <Column cols={2} colsLg={12}>
              <Icon icon={Alert2} size={30} />
            </Column>
            <Column cols={10} colsLg={12} paddingTop={4} paddingTopLg={0}>
              {t('onboarding.nav.importantNotices')}
            </Column>
          </Row>
        </>
      ),
      content: (
        <Suspense fallback={<FallBackLoader />}>
          <Notices />
        </Suspense>
      )
    });
  }

  if (path('/banking-info')) {
    tabConfig.push({
      id: 'banking-info',
      title: (
        <>
          <Row marginBottom={4} paddingTop={12}>
            <Column cols={2} colsLg={12}>
              <Icon icon={BankAccountChange} size={30} />
            </Column>
            <Column cols={10} colsLg={12} paddingTop={4} paddingTopLg={0}>
              {t('onboarding.nav.bankingInfo')}
            </Column>
          </Row>
        </>
      ),
      content: (
        <Suspense fallback={<FallBackLoader />}>
          <BankingInfo />
        </Suspense>
      )
    });
  }

  return (
    <Panel>
      <DashTabs
        config={tabConfig}
        selectedId={tabId}
        onTabClick={handleTabSelect}
      />
    </Panel>
  );
}

export default TabNav;
