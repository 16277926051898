import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import 'styled-components/macro';
import {
  Text,
  FormMessage,
  FormInput,
  Link,
  LoadingButton,
  Icon
} from '@ffn/ui';
import { PhoneNumber } from 'shared-components/NumberFormatters';
import { format, addBusinessDays, subBusinessDays } from 'date-fns/fp';
import { Composition, Box, Only } from 'atomic-layout';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Currency,
  DashForm,
  DashCheckbox,
  DashDatePicker,
  EFTAModal,
  CustomerServiceHours,
  ContactQuestions
} from 'shared-components';
import { useBrand, useNotificationTracking } from 'lib/hooks';
import { analyticsTrackEvent } from 'lib/utils/analytics';
import { ChevronThinRight } from '@styled-icons/entypo/ChevronThinRight';
import { CheckmarkCircle } from '@ffn/icons';
import {
  draftModificationAdditional,
  refreshNotifications,
  alertUpdate
} from 'lib/api';
import { useSelector } from 'react-redux';
import { USD } from 'lib/utils';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'lib/hooks';

const PlainBox = styled.div`
  background-color: ${(props) => props.theme.colors.plain};
  padding: 16px;
  padding-top: 0;
`;

const styledInput = styled(FormInput)`
  border-bottom: 1px solid ${(props) => props.theme.colors.tertiaryBlueLight} !important;
`;

export function SettlementConflict({ notification, viewLocation, isVisible }) {
  const {
    payload: { conflicts, totalAmount },
    trackingLabel
  } = notification;

  const conflictDateToDate = (str) => {
    const d = str.split('/');
    return new Date(d[2], parseInt(d[0]) - 1, parseInt(d[1]));
  };

  const { t } = useTranslation();

  const initiallyChecked = conflicts.map((conflict) => conflict.alertId);
  const trackNotificationView = useNotificationTracking();
  const [checkList, setCheckList] = useState(initiallyChecked);
  const [selectedTotal, setSelectedTotal] = useState(0);
  const [unSelectedTotal, setUnSelectedTotal] = useState(0);
  const [depositDate, setDepositDate] = useState(
    subBusinessDays(3, conflictDateToDate(conflicts[0].conflictDate))
  );
  const [conflictDateGrouping, setConflictDateGrouping] = useState({});
  const [hasGrouped, setHasGrouped] = useState(false);
  const [isEftaModalOpen, setIsEftaModalOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState(null);
  const clientData = useSelector((state) => state?.models?.client ?? {});
  const brand = useBrand();

  useEffect(() => {
    trackNotificationView({
      trackingLabel,
      viewLocation,
      isVisible
    });
  }, [trackingLabel, viewLocation, isVisible, trackNotificationView]);

  useEffect(() => {
    const conflictsWithMatchedDates = {};
    let hasAtLeastOne = false;
    conflicts.forEach((conflict) => {
      const hasDate = conflicts.filter(
        (c) =>
          c.conflictDate === conflict.conflictDate &&
          c.alertId !== conflict.alertId
      );
      if (hasDate.length > 0) {
        conflictsWithMatchedDates[conflict.alertId] = hasDate.map(
          (item) => item.alertId
        );
        hasAtLeastOne = true;
      }
    });
    if (hasAtLeastOne) {
      setHasGrouped(true);
    }
    setConflictDateGrouping(conflictsWithMatchedDates);
  }, [conflicts]);

  useEffect(() => {
    const totalAmount = conflicts.reduce((amt, conflict) => {
      return conflict &&
        conflict.conflictAmount &&
        checkList.includes(conflict.alertId)
        ? amt + Math.abs(conflict.conflictAmount)
        : amt;
    }, 0);
    const leftoverAmount = conflicts.reduce((amt, conflict) => {
      return conflict &&
        conflict.conflictAmount &&
        !checkList.includes(conflict.alertId)
        ? amt + Math.abs(conflict.conflictAmount)
        : amt;
    }, 0);
    setSelectedTotal(totalAmount);
    setUnSelectedTotal(leftoverAmount);
  }, [checkList, conflicts]);

  const conflictDates = [...conflicts].sort((a, b) => {
    if (a.conflictDate === b.conflictDate) {
      return 0;
    }
    return a.conflictDate > b.conflictDate ? 1 : -1;
  });

  const handleCheckbox = (alertId) => {
    let alertIndex = initiallyChecked.indexOf(alertId);
    const selectionGroup = conflictDateGrouping[alertId];
    if (checkList.includes(alertId)) {
      if (selectionGroup?.length > 0) {
        alertIndex = selectionGroup.reduce((latest, item) => {
          const itemIndex = initiallyChecked.indexOf(item);
          return itemIndex < latest ? itemIndex : latest;
        }, alertIndex);
      }
      const nowChecked = initiallyChecked.slice(0, alertIndex);
      setCheckList(nowChecked);
    } else {
      if (selectionGroup?.length > 0) {
        alertIndex = selectionGroup.reduce((latest, item) => {
          const itemIndex = initiallyChecked.indexOf(item);
          return itemIndex > latest ? itemIndex : latest;
        }, alertIndex);
      }
      const nowChecked = initiallyChecked.slice(0, alertIndex + 1);
      setCheckList(nowChecked);
    }
  };

  const howeverTable = (includeForm, formProps) => {
    let items = conflicts.filter((item) => {
      return !checkList.includes(item.alertId);
    });

    if (includeForm) {
      items = conflicts;
    }

    if (items.length > 0) {
      return (
        <>
          {!includeForm && (
            <Text>
              {t('alerts.settlementConflict.howeverTheFollowingSettlementS')}
              {':'}
            </Text>
          )}
          <div
            css={`
              border: 1px solid ${(props) => props.theme.colors.grayDark};
            `}
          >
            <Only from="md">
              <Composition
                areas={`AccountHeading AmountHeading DateHeading`}
                paddingHorizontal={4}
                paddingHorizontalMd={8}
                paddingVertical={4}
                templateCols="auto auto auto"
                templateColsMd="1fr 150px 150px"
                css={`
                  background: ${(props) => props.theme.colors.grayDark};
                  color: ${(props) => props.theme.colors.plain};
                `}
              >
                {({ AccountHeading, AmountHeading, DateHeading }) => (
                  <>
                    <AccountHeading>
                      <strong>{t('alerts.settlementConflict.account')}</strong>
                    </AccountHeading>
                    <AmountHeading>
                      <strong>
                        {t('alerts.settlementConflict.shortfallAmount')}
                      </strong>
                    </AmountHeading>
                    <DateHeading>
                      <strong>
                        {t('alerts.settlementConflict.shortfallDate')}
                      </strong>
                    </DateHeading>
                  </>
                )}
              </Composition>
            </Only>
            {items.map((conflict, i) => {
              flipper = !flipper;
              let prevShortfallDate =
                i === 0 ? null : items[i - 1].conflictDate;
              let disabledCompleted = false;
              if (
                prevShortfallDate !== null &&
                !disabledCompleted &&
                conflict.conflictDate !== prevShortfallDate
              ) {
                disabledCompleted = true;
              }
              return (
                <Composition
                  key={i}
                  areas={`
                    Account
                    Amount
                    Date
                  `}
                  areasMd={`Account Amount Date`}
                  paddingHorizontal={8}
                  paddingVertical={8}
                  paddingVerticalMd={4}
                  templateRows="auto auto auto"
                  templateColsMd="1fr 150px 150px"
                  css={`
                    background: ${!flipper
                      ? (props) => props.theme.colors.plain
                      : (props) => props.theme.colors.grayLight};
                  `}
                >
                  {({ Account, Amount, Date }) => (
                    <>
                      <Account>
                        {includeForm ? (
                          <DashCheckbox
                            id={'conflictItem-' + i}
                            name={'conflictItem-' + i}
                            onChange={() => handleCheckbox(conflict.alertId)}
                            checked={checkList.includes(conflict.alertId)}
                            value={conflict.alertId}
                            css={`
                              height: 16px;
                              width: 16px;
                              margin-top: 3px;
                            `}
                            disabled={
                              (!disabledCompleted &&
                                conflictDateGrouping[conflict.alertId] &&
                                conflictDateGrouping[conflict.alertId].length >
                                  0) ||
                              formProps.isSubmitting ||
                              i === 0
                            }
                          >
                            <span
                              css={`
                                line-height: 22px;
                              `}
                            >
                              {conflict.originalCreditor}
                              {conflictDateGrouping[conflict.alertId] &&
                                conflictDateGrouping[conflict.alertId].length >
                                  0 && <> *</>}
                            </span>
                          </DashCheckbox>
                        ) : (
                          <span
                            css={`
                              line-height: 22px;
                            `}
                          >
                            {conflict.originalCreditor}
                          </span>
                        )}
                      </Account>
                      <Amount>
                        <Only
                          to="md"
                          css={`
                            display: inline;
                            font-weight: bold;
                          `}
                        >
                          {t('alerts.settlementConflict.shortfallAmount')}{' '}
                        </Only>
                        <Currency value={Math.abs(conflict.conflictAmount)} />
                      </Amount>
                      <Date>
                        <Only
                          to="md"
                          css={`
                            display: inline;
                            font-weight: bold;
                          `}
                        >
                          {t('alerts.settlementConflict.shortfallDate')}{' '}
                        </Only>
                        {conflict.conflictDate}
                      </Date>
                    </>
                  )}
                </Composition>
              );
            })}
            <Composition
              areas={`Total TotalAmount`}
              paddingHorizontal={8}
              paddingVertical={4}
              templateCols="auto auto"
              templateColsMd="1fr 150px 150px"
              css={`
                background: ${(props) => props.theme.colors.grayDark};
                color: ${(props) => props.theme.colors.plain};
              `}
            >
              {({ Total, TotalAmount }) => (
                <>
                  <Total>
                    <strong>{t('alerts.settlementConflict.total')}</strong>
                  </Total>
                  <TotalAmount>
                    <Only to="md">
                      <div
                        css={`
                          text-align: right;
                        `}
                      >
                        <strong>
                          <Currency
                            value={
                              includeForm ? selectedTotal : unSelectedTotal
                            }
                          />
                        </strong>
                      </div>
                    </Only>
                    <Only from="md">
                      <strong>
                        <Currency
                          value={includeForm ? selectedTotal : unSelectedTotal}
                        />
                      </strong>
                    </Only>
                  </TotalAmount>
                </>
              )}
            </Composition>
            {!includeForm && (
              <>
                <Text>
                  {t('alerts.settlementConflict.toSaveYourSettlementS')}{' '}
                  <Link
                    as={NavLink}
                    to="/customer-service/deposits/one-time-deposit"
                  >
                    {t('alerts.settlementConflict.scheduleAnAdditionalDeposit')}
                  </Link>{' '}
                  {t('alerts.settlementConflict.now')}
                </Text>
                <ContactQuestions />
              </>
            )}
          </div>
        </>
      );
    }
  };

  // add translation for 'soon'?
  const firstConflictDate =
    conflicts.length > 0
      ? conflictDates[0].conflictDate
      : t('alerts.settlementConflict.soon');
  const deficitTotal = Math.abs(totalAmount);
  let flipper = false;

  return (
    <PlainBox>
      {clientData.spa === 'GCS' ? (
        <>
          <Text
            css={`
              font-weight: bold;
            `}
          >
            {t('alerts.settlementConflict.toResolveYourSettlement')}
            {':'}
          </Text>
          <Box
            padding={32}
            marginTop={24}
            css={`
              background: ${(props) => props.theme.colors.plain};
            `}
          >
            <CustomerServiceHours />
          </Box>
        </>
      ) : (
        <>
          {isSuccess ? (
            <Box
              marginTop={16}
              css={`
                background: ${(props) => props.theme.colors.plain};
                border: 1px solid
                  ${(props) => props.theme.colors.secondaryGreen};
              `}
            >
              <Box
                flex
                alignItems="center"
                paddingHorizontal={16}
                paddingVertical={8}
                css={`
                  font-weight: 600;
                `}
              >
                <Icon icon={CheckmarkCircle} size={24} color="secondaryGreen" />
                <Box
                  marginLeft={8}
                  css={`
                    color: ${(props) => props.theme.colors.secondaryGreen};
                  `}
                >
                  {t('alerts.settlementConflict.success')}
                </Box>
              </Box>
              <Box paddingHorizontal={16} paddingTop={8} paddingBottom={16}>
                <Text>
                  {t('alerts.settlementConflict.thankYouForScheduling')}{' '}
                  {USD.format(selectedTotal)}{' '}
                  {t('alerts.settlementConflict.on')}{' '}
                  {format('MM/dd/yyyy', depositDate)}.
                </Text>
                {howeverTable()}
              </Box>
            </Box>
          ) : (
            <>
              <Text
                css={`
                  font-weight: bold;
                `}
              >
                {t('alerts.settlementConflict.yourDedicatedAccountHas')}{' '}
                <Currency value={deficitTotal} />{' '}
                {t('alerts.settlementConflict.thatImpacts')} {conflicts.length}{' '}
                {t('alerts.settlementConflict.settlement', {
                  count: conflicts.length
                })}
                {t('alerts.settlementConflict.starting')} {firstConflictDate}.
              </Text>
              <Text>
                {t(
                  'alerts.settlementConflict.scheduleAnAdditionalDepositBelow'
                )}
              </Text>
              <Formik
                initialValues={{
                  depositDate: depositDate,
                  eftaAgree: false
                }}
                validateOnMount
                validationSchema={Yup.object().shape({
                  depositDate: Yup.string().required('Required'),
                  eftaAgree: Yup.boolean().oneOf([true], 'Required')
                })}
                onSubmit={async (values, actions) => {
                  if (!isSuccess) {
                    try {
                      actions.setSubmitting(true);
                      const response = await draftModificationAdditional({
                        values: {
                          amount: selectedTotal,
                          depositDate: depositDate
                        }
                      });

                      if (response?.success === false) {
                        throw new Error(
                          t('alerts.settlementConflict.couldNotSubmitYour')
                        );
                      }

                      const alertUpdates = checkList.map((alertId) => ({
                        alert_id: alertId,
                        alert_status: 'deferred',
                        comments: 'Dashboard Settlement Conflict Action'
                      }));

                      await alertUpdate(alertUpdates);

                      analyticsTrackEvent(
                        {
                          category: 'alert',
                          action: 'success',
                          label: trackingLabel
                        },
                        'Settlement Conflict Success'
                      );
                      setIsSuccess(true);
                      setTimeout(() => {
                        refreshNotifications();
                        setIsSuccess(false);
                        setError(null);
                      }, 12000);
                    } catch (error) {
                      actions.setSubmitting(true);
                      setIsSuccess(false);
                      console.log(error);
                      analyticsTrackEvent(
                        {
                          category: 'alert',
                          action: 'failure',
                          label: trackingLabel,
                          error: error?.message
                        },
                        'Settlement Conflict Failure'
                      );
                      setError(error.message);
                    }
                  }
                }}
              >
                {(props) => (
                  <DashForm gap={0}>
                    {error && (
                      <FormMessage variant="error">
                        {error === 'ALERT_UPDATE_FAILURE' ? (
                          <>
                            {t(
                              'alerts.settlementConflict.thereWasAnErrorProcessing'
                            )}{' '}
                            <PhoneNumber
                              value={brand('contact.customer-service.phone')}
                            />
                          </>
                        ) : (
                          <>{error}</>
                        )}
                      </FormMessage>
                    )}
                    {howeverTable(true, props)}
                    {hasGrouped && (
                      <div
                        css={`
                          font-size: 11px;
                        `}
                      >
                        {'* '}
                        {t(
                          'alerts.settlementConflict.itemsThatHaveTheSameShortfall'
                        )}
                      </div>
                    )}
                    <Text>
                      {t('alerts.settlementConflict.toAvoidLosingYour')}{' '}
                      {t('alerts.settlementConflict.settlement', {
                        count: conflicts.length
                      })}{' '}
                      {t(
                        'alerts.settlementConflict.scheduleAnAdditionalDepositToMake'
                      )}
                    </Text>
                    <Composition
                      areas={`DepositAmount DepositAmount
                        DepositDate DepositDate
                        Efta Efta
                        Questions Questions
                        Submission Submission`}
                      areasMd={`DepositAmount DepositDate
                        Efta Efta
                        Questions Submission
                      `}
                      gap={24}
                      templateCols="1fr"
                      templateColsMd="1fr 1fr"
                      templateRows="auto"
                      marginVertical={16}
                    >
                      {({
                        DepositAmount,
                        DepositDate,
                        Efta,
                        Questions,
                        Submission
                      }) => (
                        <>
                          <DepositAmount>
                            <Currency
                              displayType="input"
                              customInput={styledInput}
                              readOnly
                              label={t(
                                'alerts.settlementConflict.depositAmount'
                              )}
                              value={selectedTotal}
                              style={{
                                borderBottom: `1px solid ${(props) =>
                                  props.theme.colors
                                    .tertiaryBlueLight} !important`
                              }}
                            />
                          </DepositAmount>
                          <DepositDate>
                            <DashDatePicker
                              id="depositDate"
                              name="depositDate"
                              label={t('alerts.settlementConflict.depositDate')}
                              minDate={addBusinessDays(3, new Date())}
                              maxDate={subBusinessDays(
                                3,
                                conflictDateToDate(conflicts[0].conflictDate)
                              )}
                              onChange={(value) => {
                                setDepositDate(value);
                              }}
                            />
                          </DepositDate>
                          <Efta>
                            <DashCheckbox
                              id="eftaAgree"
                              name="eftaAgree"
                              value="eftaAgreed"
                              disabled={props.isSubmitting}
                              onChange={() => {
                                props.setFieldValue(
                                  'eftaAgree',
                                  !props.values.eftaAgree
                                );
                              }}
                            >
                              <span
                                css={`
                                  margin-right: 4px;
                                `}
                              >
                                {t(
                                  'alerts.settlementConflict.iHaveReadAndAgreeToThe'
                                )}
                              </span>
                              <Link
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setIsEftaModalOpen(true);
                                }}
                              >
                                {t(
                                  'alerts.settlementConflict.electronicFundsTransferAuthorization'
                                )}
                              </Link>
                              .
                            </DashCheckbox>
                            <EFTAModal
                              isOpen={isEftaModalOpen}
                              closeModal={() => setIsEftaModalOpen(false)}
                            />
                          </Efta>
                          <Questions>
                            <ContactQuestions />
                          </Questions>
                          <Submission>
                            <LoadingButton
                              isLoading={props.isSubmitting}
                              disabled={!props.isValid}
                              variant="primary"
                              rightEnhancer={
                                <Icon icon={ChevronThinRight} size={14} />
                              }
                              height={40}
                              width="100%"
                              type="submit"
                              css={`
                                &:disabled {
                                  border: 1px solid #ccc;
                                }
                              `}
                            >
                              {t('alerts.settlementConflict.addDeposit')}
                            </LoadingButton>
                          </Submission>
                        </>
                      )}
                    </Composition>
                  </DashForm>
                )}
              </Formik>
            </>
          )}
        </>
      )}
    </PlainBox>
  );
}

export default SettlementConflict;
