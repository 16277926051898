import { FormMessage } from '@ffn/ui';
import { Formik } from 'formik';
import {
  draftModificationChange,
  sendGCSIncreaseDepositRequestEmail
} from 'lib/api';
import { analyticsTrackEvent } from 'lib/utils/analytics';
import { getEventExtension, isNotNil } from 'lib/utils';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { DashForm } from 'shared-components';
import { getLastScheduledDraftDate } from 'shared-selectors/draftFees';
import { getClient } from 'shared-selectors/enrolled-debt';
import {
  getEarliestDraftChangeDate,
  getAmountLimits
} from 'shared-selectors/client';
import * as yup from 'yup';
import { CustomPanel } from '../CustomTabs';
import UnableToPerformRequest from '../UnableToPerformRequest';
import { Form, Review, Success } from './components';
import { clampDate } from './utils/dateClamp';
import { updateAllDraftFees } from './utils/updateAllDraftFees';
import { USD } from 'lib/utils';
import { useBrand } from 'lib/hooks';
import { useTranslation } from 'lib/hooks';

export const FormError = ({ errorCode }) => {
  const { t } = useTranslation();

  const brand = useBrand();
  const messages = {
    'Portal_DataUpdates.DraftSuspendedException':
      t('common.form.validationsAndErrors.thereWasAProblemScheduling') +
      ' ' +
      brand('contact.customer-service.phone') +
      ' ' +
      t('common.form.validationsAndErrors.toScheduleAdditionalDeposits'),
    WRONG_OTHER_AMOUNT_VALUE: t(
      'common.form.validationsAndErrors.amountIsRequiredMustBeGreater'
    ),
    UNHANDLED_ERROR: t('common.form.validationsAndErrors.somethingWentWrong')
  };
  return messages[errorCode] ? messages[errorCode] : messages.UNHANDLED_ERROR;
};

export function IncreaseProgramDeposit({ goToSelect, close }) {
  const [error, setError] = useState();
  const [step, setStep] = useState('form');
  const location = useLocation();
  const client = useSelector(getClient);
  const { minAmount, maxAmount } = useSelector(getAmountLimits);
  const earliestDraftChangeDate = useSelector(getEarliestDraftChangeDate);
  const lastScheduledDraftDate = useSelector(getLastScheduledDraftDate);
  const allDraftFees = useSelector(
    (state) => state?.models?.allDraftFees || {}
  );
  const { t } = useTranslation();

  const labels = {
    Monthly: t('customerService.increaseProgramDeposit.monthly'),
    Biweekly: t('customerService.increaseProgramDeposit.biWeekly'),
    Split: t('customerService.increaseProgramDeposit.splitMonthly')
  };

  const draftTypeLabel = labels[client.draftType];
  return (
    <CustomPanel
      heading={t('dedicatedAccount.increaseProgramDeposit')}
      close={close}
    >
      {isNotNil(lastScheduledDraftDate) ? (
        <Formik
          initialValues={{
            increaseAmount: minAmount,
            startDate: earliestDraftChangeDate,
            endDate: lastScheduledDraftDate,
            acknowledged: false
          }}
          validationSchema={yup.object().shape(
            {
              increaseAmount: yup
                .number(t('common.form.validationsAndErrors.mustBeANumber'))
                .required(
                  t('common.form.validationsAndErrors.amountIsRequired')
                )
                .min(
                  minAmount,
                  t('common.form.validationsAndErrors.mustBeAtLeast') +
                    ' ' +
                    USD.format(minAmount)
                )
                .max(
                  maxAmount,
                  t('common.form.validationsAndErrors.mustBe') +
                    ' ' +
                    USD.format(maxAmount) +
                    ' ' +
                    t('common.form.validationsAndErrors.orLess')
                )
                .label(t('common.form.validationsAndErrors.amount')),
              acknowledged: yup
                .bool()
                .oneOf(
                  [true],
                  t('common.form.validationsAndErrors.mustBeChecked')
                ),
              startDate: yup
                .date()
                .when(
                  'endDate',
                  (endDate, schema) =>
                    endDate &&
                    schema.max(
                      endDate,
                      t(
                        'common.form.validationsAndErrors.startDateMustBeEarlier'
                      )
                    )
                ),
              endDate: yup
                .date()
                .when(
                  'startDate',
                  (startDate, schema) =>
                    startDate &&
                    schema.min(
                      startDate,
                      t('common.form.validationsAndErrors.endDateMustBeLaterOr')
                    )
                )
            },
            ['startDate', 'endDate']
          )}
          validateOnMount
          onSubmit={async (values) => {
            try {
              analyticsTrackEvent(
                {
                  category: 'csr',
                  label: 'increase_deposit' + getEventExtension(location),
                  action: 'confirm'
                },
                `Increase Deposit Confirmed`
              );

              if (/cft/i.test(client.spa)) {
                const updatedAllDraftFees = updateAllDraftFees({
                  values: {
                    ...values,
                    startDate: clampDate(
                      earliestDraftChangeDate,
                      lastScheduledDraftDate
                    )(values.startDate),
                    endDate: clampDate(
                      earliestDraftChangeDate,
                      lastScheduledDraftDate
                    )(values.endDate)
                  },
                  allDraftFees,
                  monthlyDraftsFeeAmount: client.monthlyDraftsFeeAmount
                });
                await draftModificationChange({
                  allFeesData: updatedAllDraftFees
                });
              } else {
                await sendGCSIncreaseDepositRequestEmail({ client, values });
              }
              analyticsTrackEvent(
                {
                  category: 'csr',
                  label: 'increase_deposit' + getEventExtension(location),
                  action: 'success'
                },
                'Increase Deposit Success'
              );
              setStep('success');
            } catch (err) {
              analyticsTrackEvent(
                {
                  category: 'csr',
                  label: 'increase_deposit' + getEventExtension(location),
                  action: 'failure'
                },
                'Increase Deposit Failure'
              );
              console.log(err);
              setError(err);
            }
          }}
        >
          {({ values }) => (
            <DashForm>
              {error && (
                <FormMessage variant="error">
                  <FormError error={error} />
                </FormMessage>
              )}
              {step === 'form' && (
                <Form
                  draftTypeLabel={draftTypeLabel}
                  minAmount={minAmount}
                  maxAmount={maxAmount}
                  earliestDraftChangeDate={earliestDraftChangeDate}
                  lastScheduledDraftDate={lastScheduledDraftDate}
                  nextStep={() => setStep('review')}
                  goToSelect={goToSelect}
                />
              )}
              {step === 'review' && (
                <Review
                  draftTypeLabel={draftTypeLabel}
                  prevStep={() => setStep('form')}
                />
              )}
              {step === 'success' && <Success />}
            </DashForm>
          )}
        </Formik>
      ) : (
        <UnableToPerformRequest goToSelect={goToSelect} />
      )}
    </CustomPanel>
  );
}

export default IncreaseProgramDeposit;
