import { createSelector } from 'reselect';
import {
  parseISO,
  addMonths,
  differenceInCalendarMonths,
  isAfter,
  addDays
} from 'date-fns/fp';
import * as R from 'ramda';
import { getDraftFees } from './draftFees';

const getRawOmittedAccounts = (state) => state.models.omittedAccounts ?? [];
const getClient = (state) => state.models.client ?? [];

const getEstimatedDebtFreeDate = (state) =>
  state.models?.client?.estimatedDebtFreeDate
    ? parseISO(state.models?.client?.estimatedDebtFreeDate)
    : addMonths(48, new Date());

const getNextEffectiveDraft = createSelector(
  getDraftFees,
  R.pipe(
    R.filter(
      R.pipe(R.prop('date'), parseISO, isAfter(addDays(15, new Date())))
    ),
    R.filter(R.prop('includeInDfli')),
    R.filter(R.propEq('type', 'Draft')),
    R.sortBy(R.ascend(R.prop('date'))),
    R.head
  )
);

export const getRemainingMonths = createSelector(
  [getNextEffectiveDraft, getEstimatedDebtFreeDate],
  (nextEffectiveDraft, estimatedDebtFreeDate) => {
    if (R.isNil(nextEffectiveDraft) || R.isNil(estimatedDebtFreeDate)) {
      return null;
    }
    return differenceInCalendarMonths(
      R.pipe(R.prop('date'), parseISO)(nextEffectiveDraft)
    )(estimatedDebtFreeDate);
  }
);

export const getNextEffectiveDate = createSelector(
  getNextEffectiveDraft,
  (nextEffectiveDraft) =>
    R.isNil(nextEffectiveDraft)
      ? null
      : R.pipe(R.prop('date'), parseISO)(nextEffectiveDraft)
);

export const getOmittedAccounts = createSelector(
  [getRawOmittedAccounts, getRemainingMonths, getNextEffectiveDraft, getClient],
  (rawOmittedAccounts, remainingMonths, nextEffectiveDraft, client) => {
    if (
      R.isNil(rawOmittedAccounts) ||
      R.isNil(remainingMonths) ||
      R.isNil(nextEffectiveDraft) ||
      R.isNil(client)
    ) {
      return [];
    }

    const draftFrequency = R.pipe(
      R.prop('remark'),
      R.cond([
        [R.test(/split/i), R.always('split')],
        [R.test(/biweekly/i), R.always('biweekly')],
        [R.T, R.always('monthly')]
      ])
    )(nextEffectiveDraft);

    return rawOmittedAccounts
      .map((omittedAccount) => ({
        ...omittedAccount,
        creditorSettPercentage: omittedAccount.creditorSettPercentage ?? 0,
        clientFeePercentage: client.feePercentage ?? 0
      }))
      .filter(
        (omittedAccount) =>
          omittedAccount.balance > 0 &&
          omittedAccount.creditorSettPercentage > 0 &&
          client.feePercentage > 0
      )
      .map((omittedAccount) => {
        let addedAccountAmount =
          omittedAccount.balance *
            (omittedAccount.creditorSettPercentage / 100) +
          (client.feePercentage / 100) * omittedAccount.balance;

        if (draftFrequency === 'split' || draftFrequency === 'biweekly') {
          addedAccountAmount = addedAccountAmount / 2;
        }
        return {
          ...omittedAccount,
          addedAccountDraftsIncrease: Math.round(
            addedAccountAmount / remainingMonths
          )
        };
      });
  }
);
