import React from 'react';
import { NavLink } from 'react-router-dom';
import { useAuth, useBrand, useLocale, useTranslation } from 'lib/hooks';
import { getUrlExtension } from 'lib/utils';
import 'styled-components/macro';
import {
  Container,
  Dropdown,
  DropdownItem,
  DropdownSeparator,
  Icon,
  Image
} from '@ffn/ui';
import { Logout, Notifications } from '@ffn/icons';
import { FeatureFlag, LanguageToggle } from 'shared-components';
import { Box, Composition } from 'atomic-layout';

import { getTestIds } from 'lib/utils/dataTestIds';

const headerTestIDs = getTestIds('header');

const testAreas = `
menus brand accountInfo
`;

export function MobileNav({ showMenuItems }) {
  const brand = useBrand();
  const { t } = useTranslation();
  const { changeLocale, locale } = useLocale();
  const { logout } = useAuth();

  return (
    <Composition
      as={Container}
      areas={testAreas}
      templateCols="50px 1fr 50px"
      templateRows="64px"
    >
      {({ Menus, Brand, AccountInfo }) => (
        <>
          <Brand flex justifyContent="center">
            {getUrlExtension(brand('logo.main')) === 'svg' ? (
              <NavLink to="/" data-testid={headerTestIDs.brand_home_link}>
                <Image
                  src={brand('logo.main')}
                  css={`
                    height: 48px;
                    padding: 8px 0px;
                  `}
                />
              </NavLink>
            ) : (
              <NavLink
                to="/"
                css={`
                  display: flex;
                  align-items: center;
                `}
                data-testid={headerTestIDs.brand_home_link}
              >
                <Image
                  src={brand('logo.main')}
                  css={`
                    max-height: 48px;
                    max-width: 180px;
                    vertical-align: middle;
                    padding: 8px 0px;
                  `}
                />
              </NavLink>
            )}
          </Brand>
          {showMenuItems && (
            <>
              <AccountInfo flex>
                <Dropdown
                  content={
                    <>
                      {/* <FeatureFlag pathKey="/settings/program">
												<DropdownItem as={NavLink} to="/settings/program">
													<Icon icon={AccountSettings} size={20} />
													<span style={{ marginLeft: 8 }} data-testid={headerTestIDs.mobile_account_settings_link}>
														Account Settings
													</span>
												</DropdownItem>
											</FeatureFlag> */}
                      <DropdownItem
                        onClick={(event) => logout()}
                        css={`
                          cursor: pointer;
                        `}
                      >
                        <Icon icon={Logout} size={20} />
                        <span
                          style={{ marginLeft: 8 }}
                          data-testid={headerTestIDs.mobile_account_logout_link}
                        >
                          {t('nav.logout')}
                        </span>
                      </DropdownItem>
                      <FeatureFlag flagKey="nav.spanish-toggle">
                        <DropdownSeparator />
                        <LanguageToggle
                          language={locale}
                          changeLocale={changeLocale}
                        />
                      </FeatureFlag>
                    </>
                  }
                  placement="bottom-end"
                  css={`
                    width: 500px !important;
                  `}
                >
                  <Box height={44} marginHorizontal={20} marginTop={20}>
                    <Icon
                      icon={Notifications}
                      size={24}
                      color="primaryBlue"
                      css={`
                        cursor: pointer;
                      `}
                      data-testid={headerTestIDs.mobile_account_menu_button}
                    />
                  </Box>
                </Dropdown>
              </AccountInfo>
            </>
          )}
        </>
      )}
    </Composition>
  );
}
