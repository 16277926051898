import React from 'react';

import { AchieveLogo, FDRLogo, TLGLogo } from './Logos';
import { useBrand } from 'lib/hooks';

const brandLogoMap = {
  Achieve: (isdesktop) => <AchieveLogo isdesktop={isdesktop} />,
  FDR: (isdesktop) => <FDRLogo isdesktop={isdesktop} />,
  Turnbull: (isdesktop) => <TLGLogo isdesktop={isdesktop} />
};

export const BrandLogo = ({ isdesktop }) => {
  const brand = useBrand();
  const getLogoComponent = brandLogoMap[brand('business-name.abbr')];
  const logo = getLogoComponent(isdesktop);

  return logo;
};

export default BrandLogo;
