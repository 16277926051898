import React from 'react';
import 'styled-components/macro';
import { NewTabs, NewTab, NewTabList, NewTabPanel } from '@ffn/ui';
import { useTranslation } from 'lib/hooks';

export function Tabs({ config, selectedId, onTabClick, translationPath }) {
  const { t } = useTranslation();
  return (
    <NewTabs selectedId={selectedId} tabsModifyUrl>
      <NewTabList aria-label="My tabs">
        {config.map((tab, key) => {
          const tabClick =
            typeof onTabClick === 'function' ? onTabClick : () => {};
          return (
            <NewTab
              key={key}
              id={tab.id}
              onClick={() => tabClick(tab.id)}
              css={`
                height: auto;
                line-height: 20px;
                padding: 8px;
                width: 25%;
              `}
            >
              {translationPath ? t(translationPath, tab.title) : tab.title}
            </NewTab>
          );
        })}
      </NewTabList>
      {config.map((tab, key) => {
        return (
          <NewTabPanel
            key={key}
            css={`
              padding: 32px;
              background-color: ${(props) => props.theme.colors.plain};
              border: none;
            `}
            visible={selectedId === tab.id}
          >
            {tab.content}
          </NewTabPanel>
        );
      })}
    </NewTabs>
  );
}

export default Tabs;
