import React, { useState } from 'react';
import { Button, LoadingButton, FormInput, FormMessage } from '@ffn/ui';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import { Box, Composition } from 'atomic-layout';
import { useSelector } from 'react-redux';

import { ChevronThinRight } from '@styled-icons/entypo/ChevronThinRight';
import { DashForm, ContactQuestions } from 'shared-components';
import { getTestIds } from 'lib/utils/dataTestIds';
import { analyticsTrackEvent } from 'lib/utils/analytics';
import { newBankAccountChange } from './util';
import { FormError } from './FormError';
import { SuccessMessageText } from './SuccessMessageText';
import { WhiteBox } from './WhiteBox';
import { SubmitButtonContainer } from './SubmitButtonContainer';
import { refreshNotifications } from '../../lib/api';
import { useBrand } from 'lib/hooks';
import { shortDateWithLongYear } from 'lib/utils';
import { useTranslation } from 'lib/hooks';

const csTestIDs = getTestIds('customer_service');

const validationSchema = yup.object().shape({
  authorize: yup.boolean().oneOf([true], 'Must be checked')
});

function SuccessMessage({ isFromAlert, handleReset }) {
  const { t } = useTranslation();

  return (
    <Composition gap={24} templateCols="1fr" templateRows="auto auto auto">
      <SuccessMessageText />
      <Box>{t('bankAccountChange.updateNoticeText')}</Box>
      {!isFromAlert && (
        <Box flex alignItems="center" justifyContent="center">
          <Button
            variant="tertiary"
            height={40}
            rightEnhancer={<ChevronThinRight size={14} />}
            onClick={handleReset}
          >
            {t('bankAccountChange.okThanks')}
          </Button>
        </Box>
      )}
    </Composition>
  );
}

export function AuthorizeOnlyBankAccountChangeForm({
  successCallback,
  errorCallback,
  updateSubmittingState,
  isFromAlert
}) {
  const client = useSelector((state) => state?.models?.client);
  const [error, setError] = useState();
  const [state, setState] = useState('form');
  const brand = useBrand();
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{
        newAccountNumber: '',
        newRoutingNumber: '',
        newAccountNames: '',
        newBankName: '',
        newAccountType: 'checking',
        authorize: false
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, formikBag) => {
        try {
          await newBankAccountChange({
            fieldValues: { ...values, type: 'authOnly' },
            clientId: client.accountId,
            fdrApplicantId: client.fdrApplicantId
          });
          // Wait 5 seconds and then refresh notifications data in Firestore, which
          // should clear any notifications that prompted the user to take this action.
          setTimeout(refreshNotifications, 5000);

          /**
           * The success callback is our way for the parent component
           * to do something after the form is successfully submitted
           */
          if (typeof successCallback === 'function') {
            successCallback();
          }
          setState('success');
          setError(null);
          analyticsTrackEvent(
            {
              category: 'csr',
              action: 'success',

              label: 'bank_account_change'
            },
            'Bank Account Change Success'
          );
        } catch (err) {
          console.log(err);
          if (typeof errorCallback === 'function') {
            errorCallback();
          }
          setError(err);
          analyticsTrackEvent(
            {
              category: 'csr',
              action: 'major failure',
              label: 'bank_account_change'
            },
            'Bank Account Change Failure'
          );
        } finally {
          if (typeof updateSubmittingState === 'function') {
            updateSubmittingState(false);
          }
        }
      }}
      onReset={() => setState('form')}
      validateOnMount
    >
      {(props) => (
        <>
          {state === 'form' && (
            <DashForm>
              <WhiteBox>
                {error && (
                  <FormMessage variant="error">
                    <FormError error={error} />
                  </FormMessage>
                )}
                <Composition gap={16} widthMd="50%">
                  <FormInput
                    value={client.routingNumberBankName}
                    readOnly
                    label={t('bankAccountChange.currentBankName')}
                    data-testid={csTestIDs.cs_account_name_input}
                  />
                  <FormInput
                    value={'****' + client.bankAccountNumber}
                    readOnly
                    label={t('bankAccountChange.currentAccountNumber')}
                    data-testid={csTestIDs.cs_account_number_input}
                  />
                </Composition>
                <label
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    margin: '24px 0px'
                  }}
                >
                  <Box
                    style={{
                      height: 18,
                      width: 18,
                      margin: '0px 16px 0px 0px'
                    }}
                  >
                    <Field
                      type="checkbox"
                      name="authorize"
                      style={{ height: 18, width: 18 }}
                      data-testid={csTestIDs.cs_new_bank_confirm}
                    />
                  </Box>
                  <span
                    css={`
                      font-size: 13px;
                      font-weight: 400;
                    `}
                  >
                    {t('bankAccountChange.achText1')}{' '}
                    {shortDateWithLongYear(new Date())},{' '}
                    {t('bankAccountChange.achText2')}{' '}
                    {brand('business-name.long')}{' '}
                    {t('bankAccountChange.achText3')}{' '}
                    {t('bankAccountChange.achText4')}{' '}
                    {shortDateWithLongYear(client.nextScheduledDraftDate)}{' '}
                  </span>
                </label>
              </WhiteBox>
              <SubmitButtonContainer>
                <ContactQuestions />
                <Box widthMd="50%" paddingLeftMd={16}>
                  <LoadingButton
                    type="submit"
                    variant="primary"
                    height={40}
                    width="100%"
                    isLoading={props.isSubmitting}
                    disabled={!props.isValid}
                    rightEnhancer={<ChevronThinRight size={14} />}
                    data-testid={csTestIDs.cs_new_bank_update_button}
                    onClick={() => {
                      if (typeof updateSubmittingState === 'function') {
                        updateSubmittingState(true);
                      }
                    }}
                  >
                    {t('bankAccountChange.updateBankAccount')}
                  </LoadingButton>
                </Box>
              </SubmitButtonContainer>
            </DashForm>
          )}
          {state === 'success' && (
            <SuccessMessage
              isFromAlert={isFromAlert}
              handleReset={props.resetForm}
            />
          )}
        </>
      )}
    </Formik>
  );
}
