import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery, useTheme } from '@achieve/sunbeam';

import { useLaunchDarkly } from 'lib/hooks';
import { getUserRecord } from 'shared-selectors/userRecord';
import { storage } from 'lib/utils/storage';
import { getAppSubDomain } from 'shared-components';

const getDismissed = (keyName) => storage.get(`${keyName}Dismissed`, false); // Default to false
const setDismissed = (keyName) => storage.set(`${keyName}Dismissed`, true); // Set to true

// For looking up the feature flag and local storage
const PROMO_KEY_NAME = {
  fdr: {
    modal: 'appPromoModal',
    inline: 'appPromoInline',
    sidebar: 'appPromoSidebar'
  },
  turnbull: {
    modal: 'appPromoModalTurnbull',
    inline: 'appPromoInlineTurnbull',
    sidebar: 'appPromoSidebarTurnbull'
  }
};

// Eligible law firm IDs for the app promo
const ELIGIBLE_LAW_FIRM_IDS = ['1059', '1001', '1001-OH', '1002', '1003'];

// This hook is used to determine whether or not a given promo for the Native App should be rendered
// It's made to be extensible as well since it can ultimately service three different promo scenarios
// controlled by different feature flags.
export function useAppPromo(promoType) {
  const user = useSelector(getUserRecord);
  const [showPromo, setShowPromo] = useState(false);
  const { flags, activeFlagNames } = useLaunchDarkly();
  const brand = getAppSubDomain();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  const keyName = PROMO_KEY_NAME[brand]?.[promoType];

  useEffect(() => {
    if (!keyName) {
      setShowPromo(false);
      return;
    }

    const featureFlagEnabled =
      flags && flags[activeFlagNames[keyName]] === 'show';

    const isBrandEligible = ELIGIBLE_LAW_FIRM_IDS.includes(
      user.lawFirmId?.toString()
    );
    const hasDismissedCTA = getDismissed(keyName);

    // Common conditions for all promo types
    const baseConditions = featureFlagEnabled && isBrandEligible;

    switch (promoType) {
      case 'modal':
      case 'inline':
        setShowPromo(baseConditions && !hasDismissedCTA && isMobile);
        return;
      case 'sidebar':
        setShowPromo(baseConditions && isDesktop);
        return;
      default:
        setShowPromo(false);
        return;
    }
  }, [
    activeFlagNames,
    flags,
    isDesktop,
    isMobile,
    keyName,
    promoType,
    user,
    brand
  ]);

  return {
    showPromo,
    dismissPromo: () => {
      setShowPromo(false);
      setDismissed(keyName);
    }
  };
}
