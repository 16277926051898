import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import 'styled-components/macro';
import { useSelector } from 'react-redux';
import { Box, Composition, Only } from 'atomic-layout';
import { DashSelect } from 'shared-components';
import AdditionalMetadataFields from './AdditionalMetadataFields';
import ReadyToUploadComponent from '../shared-components/ReadyToUploadComponent';
import { getDebtsInNegotiation } from 'shared-selectors/enrolled-debt';
import { useTranslation } from 'lib/hooks';

export function MetadataForm({ currentFile, fileIndex }) {
  const { errors, isValid, isSubmitting, setFieldValue } = useFormikContext();
  const accounts = useSelector(getDebtsInNegotiation);
  const { fdr_sf_debt_account_id, creditor, document_type } =
    currentFile.metadata;
  const fileWasRejected = currentFile.status === 'rejected';
  const sfDidNotUpdate = currentFile.status === 'Failed SF update';
  const fileWasSubmitted = currentFile.status === 'submitted';
  const isSummonsAndComplaints = document_type === 'summons_and_complaints';
  const showReadyToUpload =
    !isSubmitting && (isValid || !errors.files[fileIndex]);
  const { t } = useTranslation();

  useEffect(() => {
    const selector = `select[name='files.${fileIndex}.metadata.creditor']`;
    const { accountId } =
      document.querySelector(selector).selectedOptions[0].dataset;
    setFieldValue(
      `files.${fileIndex}.metadata.fdr_sf_debt_account_id`,
      accountId
    );
  }, [creditor, fileIndex, setFieldValue]);

  useEffect(() => {
    if (!isSummonsAndComplaints && currentFile.metadata.methodOfService) {
      setFieldValue(`files.${fileIndex}.metadata.methodOfService`, '');
    }
    if (!isSummonsAndComplaints && currentFile.metadata.dateOfService) {
      setFieldValue(`files.${fileIndex}.metadata.dateOfService`, '');
    }
  }, [
    currentFile.metadata.dateOfService,
    currentFile.metadata.methodOfService,
    isSummonsAndComplaints,
    fileIndex,
    setFieldValue
  ]);

  return (
    <Composition
      templateCols="24px 1fr 24px"
      templateColsSmDown="1fr"
      autoFlowSmDown="row"
      gap={12}
      alignItems="center"
      paddingTopSmDown={8}
      paddingHorizontal={16}
      paddingBottom="16px"
      css={`
        background-color: ${(props) => props.theme.colors.plain};
      `}
    >
      <Only from="md" />
      <Box style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
        <Box>
          <DashSelect
            id={`files.${fileIndex}.metadata.creditor`}
            name={`files.${fileIndex}.metadata.creditor`}
            label={t('docUpload.creditorName')}
            disabled={isSubmitting || fileWasSubmitted}
            style={{ paddingRight: '32px' }}
          >
            {!fdr_sf_debt_account_id && (
              <option>{t('docUpload.selectCreditor')}</option>
            )}
            {accounts.map((obj) => (
              <option key={obj.accountId} data-account-id={obj.accountId}>
                {`${obj.originalCreditor} ${obj.accountNumber}`}
              </option>
            ))}
          </DashSelect>
          {showReadyToUpload && !isSummonsAndComplaints && !fileWasSubmitted ? (
            <ReadyToUploadComponent
              fileWasRejected={fileWasRejected}
              from="md"
            />
          ) : null}
        </Box>
        <Box>
          <DashSelect
            id={`files.${fileIndex}.document_type`}
            name={`files.${fileIndex}.document_type`}
            label={t('docUpload.legalDocumentType')}
            disabled={isSubmitting || fileWasSubmitted}
            style={{ paddingRight: '32px' }}
            onChange={({ target }) => {
              const { legalDocumentType } = target.selectedOptions[0].dataset;
              setFieldValue(`files.${fileIndex}.document_type`, target.value);
              setFieldValue(
                `files.${fileIndex}.metadata.document_type`,
                legalDocumentType
              );
            }}
          >
            {!document_type && (
              <option>{t('docUpload.selectLegalDocumentType')}</option>
            )}
            <option data-legal-document-type="summons_and_complaints">
              {t('docUpload.summonsAndComplaints')}
            </option>
            <option data-legal-document-type="power_of_attorney">
              {t('docUpload.powerOfAttorney')}
            </option>
            <option data-legal-document-type="bankruptcy_document">
              {t('docUpload.bankruptcyDocuments')}
            </option>
            <option data-legal-document-type="legal_services_contract">
              {t('docUpload.legalServicesContract')}
            </option>
            <option data-legal-document-type="waivers">
              {t('docUpload.waivers')}
            </option>
            <option data-legal-document-type="court_documents">
              {t('docUpload.iMNotSure')}
            </option>
            <option data-legal-document-type="demand_letter">
              {t('docUpload.demandLetter')}
            </option>
            <option data-legal-document-type="intent_to_file_suit">
              {t('docUpload.intentToFileSuit')}
            </option>
            <option data-legal-document-type="answer">
              {t('docUpload.answer')}
            </option>
            <option data-legal-document-type="change_of_attorneys">
              {t('docUpload.changeOfAttorney')}
            </option>
            <option data-legal-document-type="intent_to_file_default">
              {t('docUpload.intentToFileDefault')}
            </option>
            <option data-legal-document-type="motion_for_default">
              {t('docUpload.motionForDefault')}
            </option>
            <option data-legal-document-type="motion_for_alternate_service">
              {t('docUpload.motionForAlternateService')}
            </option>
            <option data-legal-document-type="register_for_hearing_date">
              {t('docUpload.registerForHearingDate')}
            </option>
            <option data-legal-document-type="consent_of_judgement">
              {t('docUpload.consentOfJudgement')}
            </option>
            <option data-legal-document-type="garnishment">
              {t('docUpload.garnishment')}
            </option>
            <option data-legal-document-type="notice_of_judgement">
              {t('docUpload.noticeOfJudgement')}
            </option>
            <option data-legal-document-type="property_lien">
              {t('docUpload.propertyLien')}
            </option>
            <option data-legal-document-type="satisfaction_of_judgement">
              {t('docUpload.satisfactionOfJudgement')}
            </option>
            <option data-legal-document-type="subpoena">
              {t('docUpload.subpoena')}
            </option>
            <option data-legal-document-type="attempt_to_serve">
              {t('docUpload.attemptToServe')}
            </option>
          </DashSelect>
          {showReadyToUpload && !isSummonsAndComplaints && !fileWasSubmitted ? (
            <ReadyToUploadComponent fileWasRejected={fileWasRejected} to="md" />
          ) : null}
        </Box>
        {isSummonsAndComplaints ? (
          <AdditionalMetadataFields
            fileIndex={fileIndex}
            currentFile={currentFile}
            showReadyToUpload={showReadyToUpload}
            isSummonsAndComplaints={isSummonsAndComplaints}
            fileWasRejected={fileWasRejected}
            isSubmitting={isSubmitting}
            fileWasSubmitted={fileWasSubmitted}
            sfDidNotUpdate={sfDidNotUpdate}
          />
        ) : null}
      </Box>
      <Only from="md" />
    </Composition>
  );
}
