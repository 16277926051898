import { local } from './env.config.local';
import { dev } from './env.config.dev';
import { stg } from './env.config.stg';
import { prd } from './env.config.prd';
import { feature } from './env.config.feature';

const windowLocation = window.location.hostname;

const envConfigs = {
  local,
  dev,
  stg,
  prd,
  feature
};

let environment;

const PRODUCTION_HOSTNAMES = [
  'freedomdebtrelief.com',
  'turnbull.programdashboard.com',
  'achieve.com'
];

if (windowLocation.includes('local')) {
  environment = 'local';
} else if (PRODUCTION_HOSTNAMES.some((host) => windowLocation.includes(host))) {
  environment = 'prd';
} else if (windowLocation.includes('stg')) {
  environment = 'stg';
} else if (windowLocation.includes('feature')) {
  environment = 'feature';
} else if (windowLocation.includes('dev')) {
  environment = 'dev';
} else {
  environment = 'dev';
}

export default envConfigs[environment];
