import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import 'styled-components/macro';
import { Box } from 'atomic-layout';
import { ChevronThinRight } from '@styled-icons/entypo/ChevronThinRight';
import { Icon, LoadingButton, Link } from '@ffn/ui';
import {
  DashDatePicker,
  DashNumberFormat,
  siteDataContext
} from 'shared-components';
import { Field } from 'formik';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { getFutureDraftDates } from 'shared-selectors/draftFees';
import { getClient } from 'shared-selectors/enrolled-debt';
import { getAllExcludedDates } from 'lib/utils';
import * as constants from '../../../constants';
import EFTAModal from '../../EFTAModal';
import { USD } from 'lib/utils';
import { useTranslation } from 'lib/hooks';

const MIN_DEPOSIT = 1;
const MAX_DEPOSIT = 10000;

const getExcludeDates = createSelector(
  [getClient, getFutureDraftDates],
  (client, futureDraftDates) =>
    client.spa === constants.STATEMENT_PROVIDER.CFT ? futureDraftDates : []
);

const GetAllExcludeDates = () => {
  const excludeDates = useSelector(getExcludeDates);
  const config = useContext(siteDataContext);
  const federalHolidays = config.globalConfig['federal-holidays'];

  return getAllExcludedDates(excludeDates, federalHolidays);
};

export function FormStep({ isValid, isSubmitting, defaultDate }) {
  const allExcludedDates = GetAllExcludeDates();
  const { t } = useTranslation();

  return (
    <>
      <Box widthMd="50%">
        <DashNumberFormat
          prefix={'$'}
          thousandSeparator
          allowNegative={false}
          id="amount"
          name="amount"
          label={`
          ${t('alerts.insufficientFunds.enterAmount')}
          (${USD.format(MIN_DEPOSIT)} - ${USD.format(MAX_DEPOSIT)})`}
        />
      </Box>
      <Box widthMd="50%">
        <DashDatePicker
          id="depositDate"
          name="depositDate"
          label={t('alerts.insufficientFunds.depositDate')}
          minDate={defaultDate}
          excludeDates={allExcludedDates}
        />
      </Box>
      <EFTACheckbox />
      <Box widthMd="50%">
        <LoadingButton
          isLoading={isSubmitting}
          disabled={!isValid || isSubmitting}
          type="submit"
          variant="primary"
          height={40}
          width="100%"
          rightEnhancer={<Icon icon={ChevronThinRight} size={14} />}
        >
          {t('alerts.insufficientFunds.addDeposit')}
        </LoadingButton>
      </Box>
    </>
  );
}

const Label = styled.label`
  display: flex;
  align-items: center;
`;

const FieldContainer = styled.div`
  margin-right: 16px;
`;

const EFTACheckbox = () => {
  const [eftaModalIsOpen, setEftaModalIsOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <EFTAModal
        isOpen={eftaModalIsOpen}
        closeModal={() => setEftaModalIsOpen(false)}
      />
      <Label>
        <FieldContainer>
          <Field
            data-testid="eftaAgreeCheckbox"
            type="checkbox"
            name="eftaAgreed"
            style={{ height: 18, width: 18 }}
          />
        </FieldContainer>
        <span>
          {t('alerts.insufficientFunds.iHaveReadAndAgreeToThe')}{' '}
          <Link
            data-testid="eftaAgreeLink"
            onClick={(event) => {
              event.preventDefault();
              setEftaModalIsOpen(true);
            }}
          >
            {t('alerts.insufficientFunds.electronicFundsTransferAuthorization')}
          </Link>
          .
        </span>
      </Label>
    </>
  );
};
