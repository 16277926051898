import React from 'react';
import NumberFormat from 'react-number-format';

import { Typography, Box } from '@achieve/sunbeam';
import { keyframes } from '@emotion/react';
import { Check } from 'react-feather';

import styles from './Row.module.scss';

/**
 * `Row` is a React component that renders a row with a label, a bar representing a percentage, and an optional check icon.
 *
 * @component
 * @param {Object} props - The properties that define the `Row` component.
 * @param {number} props.amount - The amount to display in the row.
 * @param {boolean} props.isDollars - If true, a dollar sign is prepended to the amount.
 * @param {string} props.id - The unique identifier for the row.
 * @param {boolean} props.isHighlighted - If true, the row is highlighted green and a check icon is displayed.
 * @param {boolean} [props.isExpanded=true] - Indicates if the parent wants the row to be visible, used to trigger the animation.
 * @param {string} props.label - The label for the row.
 * @param {number} props.percentage - The percentage that the bar should represent.
 * @param {string} props.unit - The unit for the amount.
 * @returns {React.Element} The `Row` component.
 */
export const Row = ({
  amount,
  isDollars,
  id,
  isHighlighted,
  isExpanded = true,
  label,
  percentage,
  unit
}) => {
  // Text-wrap is specified to stop the content from wrapping when the bar is animating
  const grow = keyframes`
  0% {
    width: 0;
    text-wrap: nowrap;
  }
  99% {
    text-wrap: nowrap;
  }
  100% {
    width: ${percentage}%;
    text-wrap: wrap;
  }
`;

  const animation = isExpanded ? `${grow} 2s ease-in-out forwards` : '';

  return (
    <div
      className={`${styles.row} ${isHighlighted ? styles.isHighlighted : ''}`}
    >
      <div className={styles.rowLeft}>
        <Typography
          className={styles.rowLabel}
          data-testid={`program-details-section-row-label-${id}`}
          id={`label-${id}`}
          variant="displayXS20"
        >
          {label}
        </Typography>
      </div>
      <div className={styles.rowRight} aria-labelledby={`label-${id}`}>
        <Box
          className={styles.chartBar}
          data-testid={`program-details-section-row-chart-bar-${id}`}
          width={`${percentage}%`}
          sx={{
            animation
          }}
        >
          <Typography variant="ascendLabelMd" className={styles.amount}>
            <NumberFormat
              value={amount}
              displayType="text"
              thousandSeparator={true}
              fixedDecimalScale={false}
              prefix={isDollars ? '$' : ''}
              renderText={(value) => <span>{value}</span>}
            />
          </Typography>
          <Typography variant="displayXS20" className={styles.unit}>
            <span>{unit}</span>
          </Typography>
        </Box>
        {isHighlighted && (
          <div
            aria-hidden="true"
            className={styles.checkIcon}
            data-testid={`program-details-section-row-check-${id}`}
          >
            <Check
              strokeLinecap="squared"
              strokeLinejoin="squared"
              strokeWidth={5}
              size={30}
            />
          </div>
        )}
      </div>
    </div>
  );
};
