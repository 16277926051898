import { axiosInstance as axios } from 'lib/axios';

import envConfig from 'envConfig';

const settlementAuth = async ({ data }) => {
  const apiUrl = `${envConfig.FIREBASE_FUNCTION_HOSTNAME}/alertResolve`;
  try {
    const response = await axios.put(apiUrl, data, {
      validateStatus: (status) => status === 200
    });

    return response.data;
  } catch (e) {
    throw new Error('ALERT_RESOLVE_FAILURE');
  }
};

export function rejectSettlement({ data: { settlementId, rejectReason } }) {
  const rejectData = {
    alertType: 'alert_reject_settlement',
    settlement_id: settlementId,
    rejection_reason: rejectReason
  };
  return settlementAuth({ data: rejectData });
}

export function acceptSettlement({ data: { settlementId } }) {
  const acceptData = {
    alertType: 'alert_ras',
    settlement_id: settlementId
  };
  return settlementAuth({ data: acceptData });
}
