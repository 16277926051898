import axios from 'axios';
import getApiUrl from 'lib/utils/getApiUrl';

/**
 * Handles the verification of a reCAPTCHA token.
 *
 * @param {string} token - The reCAPTCHA token to be verified.
 * @param {string} subdomain - The subdomain of the application.
 * @returns {Promise} - A promise that resolves to the response data from the verification request.
 * @throws {Error} - If no token is provided or if an error occurs during the verification process.
 */
export const recaptchaHandler = async (token, subdomain) => {
  const apiUrlStem = getApiUrl();
  const apiUrl = `${apiUrlStem}/recaptcha-verify`;
  const requestConfig = {
    headers: {
      'Content-Type': 'application/json'
    },
    validateStatus: (status) => status >= 200 && status < 500
  };
  if (!token) {
    throw new Error('No token provided');
  }
  const payload = JSON.stringify({ token, subdomain });
  try {
    const response = await axios.post(apiUrl, payload, requestConfig);
    return response.data;
  } catch (error) {
    console.error(`[Recaptcha] - ${error}`);
    throw new Error(error);
  }
};
export default recaptchaHandler;
