import React from 'react';
import { Only } from 'atomic-layout';
import { Text } from '@ffn/ui';
import 'styled-components/macro';
import { useTranslation } from 'lib/hooks';

export function ReadyToUploadComponent({
  fileWasRejected,
  sfDidNotUpdate,
  ...props
}) {
  const { t } = useTranslation();
  return (
    <Only {...props}>
      <Text
        fileWasRejected={fileWasRejected}
        sfDidNotUpdate={sfDidNotUpdate}
        css={`
          margin-top: 12px;
          margin-bottom: 0;
          font-size: 13px;
          color: ${({ theme, fileWasRejected, sfDidNotUpdate }) =>
            fileWasRejected || sfDidNotUpdate
              ? theme.colors.secondaryRed
              : theme.colors.secondaryGreen};
        `}
      >
        <em>
          {fileWasRejected
            ? t('docUpload.failedToUpload')
            : sfDidNotUpdate
              ? t('docUpload.fileUploadedBut')
              : t('docUpload.readyToUpload')}
        </em>
      </Text>
    </Only>
  );
}

export default ReadyToUploadComponent;
