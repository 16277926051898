import React from 'react';
import getDraftReductions from 'lib/api/getDraftReductions';
import { getLawFirmId } from 'shared-selectors/userRecord';
import { useSelector } from 'react-redux';

const MAX_ALLOWED_REDUCTIONS = 2;

export function useDraftReductions() {
  const lawFirmId = useSelector(getLawFirmId);
  const [depositReductions, setDepositReductions] = React.useState(null);
  const [userAllowedToReduceOnline, setUserAllowedToReduceOnline] =
    React.useState(true);

  // Check to see how many draft reductions the user has in the last 90 days
  React.useEffect(() => {
    getDraftReductions().then(({ data }) => {
      setDepositReductions(data.totalReductions ?? 0);
    });
  }, []);

  // When feature flags are loaded and deposit reductions are known,
  // set a boolean to determine if the user is allowed to reduce online
  React.useEffect(() => {
    const maxReductionsExceeded = depositReductions >= MAX_ALLOWED_REDUCTIONS;

    if (maxReductionsExceeded) {
      setUserAllowedToReduceOnline(false);
    }
  }, [depositReductions, lawFirmId]);

  return userAllowedToReduceOnline;
}
