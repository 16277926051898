export const dataTestIds = {
  add_account_opportunity: {
    continue_button: 'aaoContinueButton',
    not_enrolled_reason: 'aaoNotEnrolledReason',
    efta_checkbox: 'aaoEftaCheckbox',
    ach_checkbox: 'aaoAchCheckbox',
    cancel_complete: 'aaoCancelComplete',
    confirm_complete: 'aaoConfirmComplete',
    success_ok: 'aaoSuccessOK'
  },
  header: {
    brand_home_link: 'brandHomeLink',
    enrolled_debt_link: 'enrolledDebtLink',
    dedicated_account_link: 'dedicatedAccountLink',
    program_resources_link: 'programResourcesLink',
    customer_service_link: 'customerServiceLink',
    enrolled_debt_dropdown_link: 'enrolledDebtDropdownLink',
    negotiation_overview_dropdown_link: 'negotiationOverviewDropdownLink',
    settlement_overview_dropdown_link: 'settlementOverviewDropdownLink',
    customer_service_dropdown_link: 'customerServiceDropdownLink',
    dedicated_account_dropdown_link: 'dedicatedAccountDropdownLink',
    account_change_dropdown_link: 'accountChangeDropdownLink',
    enroll_debt_dropdown_link: 'enrollDebtDropdownLink',
    upload_document_dropdown_link: 'uploadDocumentDropdownLink',
    phone_contact_dropdown_link: 'phoneContactDropdownLink',
    mobile_drawer_menu_button: 'mobileDrawerMenuButton',
    mobile_drawer_close_button: 'mobileDrawerCloseButton',
    mobile_drawer_home_link: 'mobileDrawerHomeLink',
    // mobile_enrolled_debt_link: "mobileEnrolledDebtLink",
    // mobile_dedicated_account_link: "mobileDedicatedAccountLink",
    mobile_program_resources_link: 'mobileProgramResourcesLink',
    // mobile_customer_service_link: "mobileCustomerServiceLink",
    mobile_howitworks_link: 'mobileHowitworksLink',
    mobile_faq_link: 'mobileFaqLink',
    mobile_videos_link: 'mobileVideosLink',
    mobile_drawer_cs_link: 'mobileDrawerCsLink',
    mobile_drawer_deposits_link: 'mobileDrawerDepositsLink',
    // mobile_account_menu_button: "mobileAccountMenuButton",
    // mobile_account_notifcations_link: "mobileAccountNotifcationsLink",
    mobile_account_vault_link: 'mobileAccountVaultLink',
    // mobile_account_settings_link: "mobileAccountSettingsLink",
    // mobile_account_logout_link: "mobileAccountLogoutLink",
    account_return_to_member_dashboard_link: 'returnToMemberDashboardLink',
    account_menu_button: 'accountMenuButton',
    account_notifications_link: 'accountNotificationsLink',
    account_vault_link: 'accountVaultLink',
    account_settings_link: 'accountSettingsLink',
    account_change_password: 'changePasswordLink',
    account_logout_link: 'accountLogoutLink'
  },
  login: {
    password_input: 'passwordInput',
    username_input: 'usernameInput',
    login_button: 'loginButton',
    remember_checkbox: 'rememberCheckBox',
    forgot_pass_link: 'forgotPassLink',
    activate_link: 'activateLink'
  },
  notifications: {
    notifications_heading: 'notificationsHeading',
    notifications_list_container: 'notificationsListContainer',
    notifications_list_alerts_button: 'notificationsListAlertsButton',
    notifications_list_messages_button: 'notificationsListMessagesButton',
    notifications_content_container: 'notificationsContentContainer',
    notification_content_success_action: 'notificationContentSuccessAction',
    notification_content_reject_action: 'notificationContentRejectAction',
    notification_content_reject_link: 'notificationContentRejectLink',
    notification_content_cs_link: 'notificationContentCsLink',
    notification_content_success_message: 'notificationContentSuccessMessage'
  },
  customer_service: {
    cs_online_services_container: 'csOnlineServicesContainer',
    cs_online_services_tabs: 'csOnlineServicesTabs',
    cs_account_name_input: 'csAccountNameInput',
    cs_account_number_input: 'csAccountNumberInput',
    cs_new_account_number_input: 'csNewAccountNumberInput',
    cs_new_routing_number_input: 'csNewRoutingNumberInput',
    cs_new_account_name_input: 'csNewAccountNameInput',
    cs_new_bank_name_input: 'csNewBankNameInput',
    cs_new_bank_type_select: 'csNewBankTypeSelect',
    cs_new_bank_confirm: 'csNewBankConfirm',
    cs_new_bank_update_button: 'csNewBankUpdateButton'
  },
  dedicated_account: {
    da_overview_container: 'daOverviewContainer',
    da_generate_statement_button: 'daGenerateStatementButton',
    da_generate_statement_select: 'daGenerateStatementSelect',
    da_overview_edit_account_link: 'daOverviewEditAccountLink',
    da_overview_manage_account_link: 'daOverviewManageAccountLink',
    da_generate_statement_error: 'daGenerateStatementError'
  },
  account_settings: {
    as_login_edit_save_button: 'asLoginEditSaveButton',
    as_contact_info_edit_save_button: 'asContactInfoEditSaveButton',
    as_program_tab: 'asProgramTab',
    as_communication_pref_tab: 'asCommunicationPrefTab',
    as_contact_info_tab: 'asContactInfoTab'
  },
  increaseDepositForm: {
    reviewDepoistButton: 'reviewDepositButton',
    increaseDepositButton: 'increaseDepositButton'
  },
  program_resources: {
    faq_item: 'faqItemToggle'
  },
  csEmailUsForm: {
    csSubjectInput: 'csSubjectInput',
    csMessageInput: 'csMessageInput',
    csSubmitButton: 'csSubmitButton'
  },
  documentVault: {
    document_vault: 'document-vault',
    dvModalButton: 'dvModalButton',
    dvSearchBox: 'dvSearchBox',
    dvFilterTabs: 'dvFilterTabs',
    dvSortableTable: 'dvSortableTable',
    dvDocUpload: 'dvDocUpload',
    dvDocItemPreviewButton: 'DvDocItemPreviewButton',
    dvDocItemDownload: 'DvDocItemDownload'
  }
};

export const getTestIds = (section) => {
  return dataTestIds[section] || {};
};
