import { makeStyles } from 'tss-react/mui';
import tw from 'twin.macro';
const useStyles = makeStyles({ name: 'TaskList' })((theme) => {
  return {
    container: tw`m-4`,
    cardContainer: tw`rounded-md mt-4`
  };
});

export default useStyles;
