import { axiosInstance as axios } from 'lib/axios';

import envConfig from 'envConfig';

export const communicationPreferences = async () => {
  const apiUrl = `${envConfig.FIREBASE_FUNCTION_HOSTNAME}/emailPreferences`;

  try {
    const response = await axios.get(apiUrl, {
      validateStatus: (status) => status >= 200 && status < 500
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export default communicationPreferences;
