import { ANALYTICS_PAGE_NAME_MAP } from '../../../constants';

// Run this when the file is imported to save on cycles
// It sets up paths with params for optimizations
const pathsWithParams = Object.keys(ANALYTICS_PAGE_NAME_MAP).filter(
  (key) =>
    ANALYTICS_PAGE_NAME_MAP[key].params &&
    ANALYTICS_PAGE_NAME_MAP[key].params.length > 0
);

// Run this when the file is imported to save on cycles
const mapPaths = pathsWithParams.map((pathWithParam) => {
  return {
    pathname: pathWithParam,
    ...ANALYTICS_PAGE_NAME_MAP[pathWithParam]
  };
});

// Helper function that matches
// the ANALYTICS_PAGE_NAME_MAP paths with window.location.pathname
export const matchPath = (pathname) => {
  const pathnameParts = pathname.split('/');
  return mapPaths.find((mapObj) => {
    const mapPathnameParts = mapObj.pathname.split('/');
    if (pathnameParts.length !== mapPathnameParts.length) return false;
    // [], [profile], [collin], [photo], [17]
    // [], [profile], [:profileId], [photo], [:photoId]
    return mapPathnameParts.every((mapPathnamePart, index) => {
      if (mapPathnamePart.charAt(0) === ':') {
        return true;
      }
      if (
        mapPathnamePart.toLowerCase() === pathnameParts[index].toLowerCase()
      ) {
        return true;
      }
      return false;
    });
  });
};
