import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLaunchDarkly } from 'lib/hooks';
import { logger } from 'shared-components';
import { getUserRecord } from 'shared-selectors/userRecord';

const LOGGING_PREFIX = `PtS :: usePathToSettlementEligibility ::`;

/**
 * Determines if a user is eligible for a specific feature (PTS) based on LaunchDarkly flags and lawFirmId
 * @returns {boolean} Returns true if the user is eligible for the PTS feature, otherwise false.
 */
export const usePathToSettlementEligibility = () => {
  const { flags, activeFlagNames } = useLaunchDarkly();
  const userRecord = useSelector(getUserRecord) || {};
  const lawFirmId = userRecord?.lawFirmId;
  const featureFlagEnabled = flags[activeFlagNames.ptsEnabled] === 'show';
  const [ptsEligible, setPtsEligible] = useState(null);

  useEffect(() => {
    // const declared inside useEffect to avoid unnecessary re-renders
    const ENABLED_LAW_FIRM_ID_STRINGS = [
      '1059',
      '1001-OH',
      '1001',
      '1002',
      '1003'
    ];

    const lawFirmAllowed = ENABLED_LAW_FIRM_ID_STRINGS.includes(
      String(lawFirmId)
    );

    if (lawFirmAllowed && featureFlagEnabled) {
      logger.info(
        `${LOGGING_PREFIX} User is eligible for the Path to Settlement feature.`
      );
      setPtsEligible(true);
    } else {
      logger.info(
        `${LOGGING_PREFIX} User is not eligible for the Path to Settlement feature. Law Firm ID: (${lawFirmId}), Feature Flag Enabled: (${featureFlagEnabled})`
      );
      setPtsEligible(false);
    }
  }, [featureFlagEnabled, lawFirmId]);

  return { ptsEligible };
};
