import { axiosInstance as axios } from 'lib/axios';
import getApiUrl from 'lib/utils/getApiUrl';

export const updateFirestoreUserRecord = async (userRecordFieldsToUpdate) => {
  const apiUrlStem = getApiUrl();
  const apiUrl = `${apiUrlStem}/user`;

  try {
    const response = await axios.patch(apiUrl, userRecordFieldsToUpdate, {
      validateStatus: (status) => status === 200
    });
    return response.data;
  } catch (err) {
    console.error('Error updating user record');
    console.error(err);
    throw err;
  }
};

export const updateFirestoreTimestamp = async () => {
  const apiUrl = getApiUrl();
  const url = `${apiUrl}/user/timestamp`;
  try {
    const response = await axios.patch(url, {
      validateStatus: (status) => status === 200
    });
    return response.data;
  } catch (err) {
    console.error('Error updating user record');
    console.error(err);
    throw err;
  }
};
export default updateFirestoreUserRecord;
