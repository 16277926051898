import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import 'styled-components/macro';
import { Box, Composition, useResponsiveQuery } from 'atomic-layout';
import { Text, LoadingButton, Icon, FormMessage } from '@ffn/ui';
import { ChevronThinRight } from '@styled-icons/entypo/ChevronThinRight';
import { Currency } from 'shared-components';
import { shortDateWithLongYear } from 'lib/utils';
import { getNextEffectiveDate } from 'shared-selectors/omittedAccounts';
import { useTranslation } from 'lib/hooks';
import { removalReasonMap } from './removalReasonMap';

export function ConfirmStep({
  notification,
  errorMessage,
  isSubmitting,
  handleSubmit,
  handleBack
}) {
  const nextDraftDate = useSelector(getNextEffectiveDate);
  const formattedNextDraftDate = shortDateWithLongYear(nextDraftDate);
  const { t } = useTranslation();

  const { payload } = notification;
  return (
    <>
      <Text style={{ fontWeight: 'bold' }}>
        {t('alerts.accountRemovalRequest.toCompleteThisRequestPlease')}
      </Text>
      <Box
        paddingHorizontal={16}
        paddingVertical={8}
        css={`
          background: ${(props) => props.theme.colors.plain};
        `}
      >
        <ConfirmDetails notificationPayload={payload} />
      </Box>
      {errorMessage ? (
        <Box marginTop={8}>
          <FormMessage variant="error">
            {t('alerts.accountRemovalRequest.somethingWentWrong')}
          </FormMessage>
        </Box>
      ) : null}
      <Box flex justifyContent="center" width="100%" marginTop={16}>
        <Box width={300}>
          <LoadingButton
            width={'100%'}
            variant="primary"
            isLoading={isSubmitting}
            leftEnhancer={<Icon icon={ChevronThinRight} size={14} />}
            onClick={() => handleSubmit({ formattedNextDraftDate })}
          >
            {t('alerts.accountRemovalRequest.confirm')}
          </LoadingButton>
        </Box>
      </Box>
      {isSubmitting ? null : (
        <Box flex justifyContent="center" width="100%">
          <Box width={300}>
            <Text
              css={`
                text-align: center;
                :hover {
                  cursor: pointer;
                  text-decoration: underline;
                }
              `}
              onClick={handleBack}
            >
              {t('alerts.accountRemovalRequest.back')}
            </Text>
          </Box>
        </Box>
      )}
    </>
  );
}

const CompactText = styled(Text)`
  margin-bottom: 2px;
  margin-top: 2px;
`;

function ConfirmDetails({ notificationPayload }) {
  const { creditorName, accountNumber, requestReason, currentBalance } =
    notificationPayload;
  const isMobile = useResponsiveQuery({ from: 'xs', to: 'md' });
  const { t } = useTranslation();

  const removalReason = removalReasonMap(requestReason, t);

  return (
    <Composition
      paddingHorizontal={8}
      paddingVertical={4}
      gap={isMobile ? 3 : 8}
      templateCols={isMobile ? '1fr' : '300px 1fr'}
    >
      <CompactText>{t('alerts.accountRemovalRequest.creditor')}:</CompactText>
      <CompactText style={{ fontWeight: 'bold' }}>{creditorName}</CompactText>
      <CompactText>
        {t('alerts.accountRemovalRequest.accountEndingIn')}:
      </CompactText>
      <CompactText style={{ fontWeight: 'bold' }}>{accountNumber}</CompactText>
      <CompactText>
        {t('alerts.accountRemovalRequest.currentBalance')}:
      </CompactText>
      <CompactText style={{ fontWeight: 'bold' }}>
        <Currency value={currentBalance} />
      </CompactText>
      <CompactText>
        {t('alerts.accountRemovalRequest.removalReason')}:
      </CompactText>
      <CompactText style={{ fontWeight: 'bold' }}>
        {removalReason ?? 'N/A'}
      </CompactText>
    </Composition>
  );
}
