import { uploadToECM } from './uploadToECM';

function formatDate(date) {
  return date.toLocaleDateString('en-us', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric'
  });
}

// These are the legal document_type values that ECM knows about and will accept.
// Any other values, aside from 'customer_upload' will be rejected by ECM.
const ALLOWED_DOC_TYPES_FOR_ECM = [
  'summons_and_complaints',
  'power_of_attorney',
  'bankruptcy_document',
  'legal_services_contract',
  'waivers',
  'court_documents'
];

export const legalDocumentUpload = async (file) => {
  const { metadata } = file;

  const methodOfServiceIsMail = metadata.methodOfService === 'Mail';
  const formattedDate = metadata.dateOfService
    ? formatDate(metadata.dateOfService)
    : null;
  // Checking to make sure that ECM can handle the document_type contained in the file's metadata.
  // If not, we need to default it 'customer_upload'.
  const updatedDocType = ALLOWED_DOC_TYPES_FOR_ECM.includes(
    metadata.document_type
  )
    ? metadata.document_type
    : 'customer_upload';
  const finalMetadata =
    metadata.document_type === 'summons_and_complaints'
      ? {
          creditor: metadata.creditor,
          dateMailReceived: methodOfServiceIsMail ? formattedDate : null,
          litServiceDate: !methodOfServiceIsMail ? formattedDate : null,
          methodOfService: metadata.methodOfService,
          document_type: metadata.document_type,
          fdr_sf_debt_account_id: metadata.fdr_sf_debt_account_id
        }
      : {
          fdr_sf_debt_account_id: metadata.fdr_sf_debt_account_id,
          document_type: updatedDocType
        };

  try {
    const payload = {
      file,
      attributes: JSON.stringify(finalMetadata)
    };
    return await uploadToECM(payload);
  } catch (error) {
    throw error;
  }
};
