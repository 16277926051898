import React, { useContext, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Box } from 'atomic-layout';
import { ChevronThinRight } from '@styled-icons/entypo/ChevronThinRight';
import { Icon, Button, Link, LoadingButton, FormMessage } from '@ffn/ui';
import {
  Currency,
  DashDatePicker,
  DashForm,
  DashNumberFormat,
  siteDataContext
} from 'shared-components';
import { Formik, Field } from 'formik';
import { find } from 'ramda';
import {
  format,
  addBusinessDays,
  isAfter,
  isSameDay,
  startOfDay
} from 'date-fns/fp';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { createSelector } from 'reselect';
import { getLatestPrenoteDate } from 'shared-selectors/transactions';
import {
  getFutureDraftDates,
  getFutureDrafts
} from 'shared-selectors/draftFees';
import { getClient } from 'shared-selectors/enrolled-debt';
import {
  isNotNil,
  getEventExtension,
  getAllExcludedDates,
  shortDateWithLongYear,
  formatDateSafely
} from 'lib/utils';
import { CustomPanel } from '../CustomTabs';
import * as constants from '../../../../../../constants';
import { CheckmarkCircle } from '@ffn/icons';
import { draftModificationAdditional } from 'lib/api/draftModificationAdditional';
import { sendOneTimeGCSemail } from 'lib/api/generalCsRequest';
import { analyticsTrackEvent } from 'lib/utils/analytics';
import EFTAModal from '../../../../../../shared-components/EFTAModal';
import UnableToPerformRequest from '../UnableToPerformRequest';
import { USD } from 'lib/utils';
import { refreshNotifications } from 'lib/api';
import { getDay } from 'date-fns';
import {
  EFTAContainer,
  FieldsContainer,
  FormStepWrapper
} from './OneTimeDeposit.styles';
import { useBrand } from 'lib/hooks';
import { useTranslation } from 'lib/hooks';

const today = new Date();
const MAX_DEPOSIT = 10000;

const getDefaultDate = createSelector(
  [getClient, getFutureDraftDates, getLatestPrenoteDate],
  (client, futureDraftDates, latestPrenoteDate) => {
    if (client.spa === constants.STATEMENT_PROVIDER.GCS) {
      return addBusinessDays(7)(today);
    } else {
      let cftDefaultDate = addBusinessDays(3)(today);

      let matchDraftDate = find(isSameDay(cftDefaultDate), futureDraftDates);
      cftDefaultDate = matchDraftDate
        ? addBusinessDays(1, matchDraftDate)
        : cftDefaultDate;

      if (
        isNotNil(latestPrenoteDate) &&
        isAfter(cftDefaultDate, addBusinessDays(7, latestPrenoteDate))
      ) {
        return addBusinessDays(7, latestPrenoteDate);
      }

      return startOfDay(cftDefaultDate);
    }
  }
);

const getExcludeDates = createSelector(
  [getClient, getFutureDraftDates],
  (client, futureDraftDates) =>
    client.spa === constants.STATEMENT_PROVIDER.CFT ? futureDraftDates : []
);

const GetAllExcludeDates = () => {
  const excludeDates = useSelector(getExcludeDates);
  const config = useContext(siteDataContext);
  const federalHolidays = config.globalConfig['federal-holidays'];

  return getAllExcludedDates(excludeDates, federalHolidays);
};

function FormStep({
  defaultDate,
  isValid,
  next,
  dueDate,
  dmfReason,
  minimumDepositAmount,
  setFieldValue
}) {
  const [isOpen, setIsOpen] = useState(false);
  const allExcludedDates = GetAllExcludeDates();
  const isASOdeposit = dmfReason === 'Accelerated Settlement Opportunity';
  const isWeekday = (date) => {
    const day = getDay(date);
    return day !== 0 && day !== 6;
  };
  const { t } = useTranslation();

  return (
    <FormStepWrapper>
      <FieldsContainer>
        <div>
          <DashNumberFormat
            prefix={'$'}
            thousandSeparator
            allowNegative={false}
            id="amount"
            name="amount"
            label={`${t('changeDepositWizard.enterAmount')} (${USD.format(
              minimumDepositAmount
            )} - ${USD.format(MAX_DEPOSIT)})`}
          />
        </div>
        <div>
          <DashDatePicker
            id="depositDate"
            name="depositDate"
            label={t('changeDepositWizard.depositDate')}
            minDate={defaultDate}
            maxDate={
              isASOdeposit
                ? new Date(formatDateSafely('MM/dd/yy')(dueDate))
                : null
            }
            filterDate={isWeekday}
            excludeDates={allExcludedDates}
          />
        </div>
      </FieldsContainer>

      <EFTAContainer>
        <EFTAModal isOpen={isOpen} closeModal={() => setIsOpen(false)} />
        <label style={{ display: 'flex', alignItems: 'center' }}>
          <Box style={{ height: 18, width: 18, margin: '0px 8px 0px 0px' }}>
            <Field
              data-testid="eftaAgreeCheckbox"
              type="checkbox"
              name="toggle"
              style={{ height: 18, width: 18 }}
            />
          </Box>
          <span>
            {t('customerService.oneTimeDeposit.agreement0')}{' '}
            <Link
              data-testid="eftaAgreeLink"
              onClick={(event) => {
                event.preventDefault();
                setIsOpen(true);
              }}
            >
              {t('customerService.oneTimeDeposit.agreement1')}
            </Link>
            .
          </span>
        </label>
      </EFTAContainer>

      <Button
        data-testid="reviewAdditionalDeposit"
        disabled={!isValid}
        onClick={next}
        variant="primary"
        height={40}
        width="100%"
        rightEnhancer={<Icon icon={ChevronThinRight} size={14} />}
      >
        {t('customerService.oneTimeDeposit.agreement2')}
      </Button>
    </FormStepWrapper>
  );
}

function ConfirmStep({ amount, depositDate, isLoading }) {
  const { t } = useTranslation();

  return (
    <>
      <Box data-testid="oneTimeDepositConfirm" style={{ fontWeight: '400' }}>
        {t('customerService.confirmOneTimeDeposit.agreement0')}{' '}
        <strong>{t('customerService.confirmOneTimeDeposit.agreement1')}</strong>{' '}
        {t('customerService.confirmOneTimeDeposit.agreement2')}{' '}
        <strong>
          <Currency value={amount} />
        </strong>{' '}
        {t('customerService.confirmOneTimeDeposit.agreement3')}{' '}
        {format('M/d/yyyy')(depositDate)}
        {t('customerService.confirmOneTimeDeposit.agreement4')}{' '}
      </Box>
      <Box style={{ fontWeight: '400' }}>
        <strong>
          {t('customerService.confirmOneTimeDeposit.agreement5')}{' '}
          <Currency value={amount} />{' '}
        </strong>
        <br />
        <strong>
          {t('customerService.confirmOneTimeDeposit.agreement6')}{' '}
          {format('M/d/yyyy')(depositDate)}
        </strong>
      </Box>

      <LoadingButton
        isLoading={isLoading}
        disabled={isLoading}
        type="submit"
        variant="primary"
        height={40}
        width="100%"
        rightEnhancer={<Icon icon={ChevronThinRight} size={14} />}
      >
        {t('customerService.confirmOneTimeDeposit.agreement7')}
      </LoadingButton>
    </>
  );
}

export const FormError = ({ errorCode }) => {
  const brand = useBrand();
  const { t } = useTranslation();
  const messages = {
    NO_PRIVILEGES_CS_AGENT: t('customerService.oneTimeDeposit.message0'),
    ONE_TIME_DEPOSIT_ERROR: t('customerService.oneTimeDeposit.message1'),
    'Portal_DataUpdates.DraftSuspendedException':
      t('customerService.oneTimeDeposit.message2') +
      ' ' +
      brand('contact.customer-service.phone') +
      ' ' +
      t('customerService.oneTimeDeposit.toScheduleAdditionalDeposits'),
    INVALID_DRAFT_DATE: t('customerService.oneTimeDeposit.message3'),
    UNHANDLED_ERROR: t('customerService.oneTimeDeposit.message4')
  };

  console.log(errorCode);
  return messages[errorCode] ? messages[errorCode] : messages.UNHANDLED_ERROR;
};

function OneTimeForm({
  step,
  setStep,
  setSuccessData,
  spa,
  goToSelect,
  dueDate,
  minimumDepositAmount,
  dmfReason
}) {
  const isASOdeposit = dmfReason === 'Accelerated Settlement Opportunity';
  const defaultDateSelector = useSelector(getDefaultDate);
  const defaultDate = isASOdeposit
    ? new Date(formatDateSafely('MM/dd/yy')(new Date()))
    : defaultDateSelector;
  const userRecord = useSelector((state) => state.userRecord);
  const [error, setError] = useState();
  const location = useLocation();
  const allExcludedDates = GetAllExcludeDates();
  const MIN_DEPOSIT = isASOdeposit ? minimumDepositAmount : 1;
  const { t } = useTranslation();
  return (
    <Formik
      initialValues={{
        amount: 0,
        depositDate: defaultDate,
        toggle: false
      }}
      validationSchema={yup.object().shape({
        amount: yup
          .number(t('common.form.validationsAndErrors.mustBeANumber'))
          .min(
            MIN_DEPOSIT,
            t('common.form.validationsAndErrors.mustBeAtLeast') +
              ' ' +
              USD.format(MIN_DEPOSIT)
          )
          .max(
            MAX_DEPOSIT,
            t('common.form.validationsAndErrors.mustBe') +
              ' ' +
              USD.format(MAX_DEPOSIT) +
              ' ' +
              t('common.form.validationsAndErrors.orLess')
          )
          .required(t('common.form.validationsAndErrors.amountIsRequired'))
          .label(t('common.form.validationsAndErrors.amount')),
        depositDate: yup
          .date()
          .min(defaultDate)
          .notOneOf(
            allExcludedDates,
            t('common.form.validationsAndErrors.pleaseChooseAValidDepositDate')
          )
          .required(t('common.form.validationsAndErrors.depositDateRequired'))
          .label(t('common.form.validationsAndErrors.depositDate')),
        toggle: yup
          .boolean()
          .oneOf(
            [true],
            t('common.form.validationsAndErrors.mustAgreeToSomething')
          )
      })}
      validateOnMount
      onSubmit={async (values) => {
        try {
          analyticsTrackEvent(
            {
              category: 'csr',
              label: 'additional_deposit' + getEventExtension(location),
              action: 'confirm',
              value: values.amount
            },
            'Onetime Deposit Confirm'
          );
          if (spa === constants.STATEMENT_PROVIDER.GCS) {
            await sendOneTimeGCSemail({ ...values, user: userRecord });
          }
          const draftResponse = await draftModificationAdditional({
            values: {
              amount: Number(values.amount),
              depositDate: values.depositDate,
              dmfReason: dmfReason || 'Dashboard One Time Deposit'
            }
          });

          const { success, error_message } = draftResponse;

          if (success === false) {
            analyticsTrackEvent(
              {
                category: 'csr',
                label: 'additional_deposit' + getEventExtension(location),
                action: 'failure',
                value: values.amount
              },
              'Onetime Deposit Failure'
            );
            setError(error_message);
          } else {
            setSuccessData(values);
            setStep('success');
            analyticsTrackEvent(
              {
                category: 'csr',
                label: 'additional_deposit' + getEventExtension(location),
                action: 'success',
                value: values.amount
              },
              'Onetime Deposit Success'
            );
          }

          // Wait five seconds and then refresh notifications to clear any applicable alert(s)
          setTimeout(() => {
            refreshNotifications();
          }, 5000);
        } catch (err) {
          analyticsTrackEvent(
            {
              category: 'csr',
              label: 'additional_deposit' + getEventExtension(location),
              action: 'failure',
              value: values.amount
            },
            'Onetime Deposit Failure'
          );
          setError(err);
        }
      }}
    >
      {({
        values,
        isValid,
        isSubmitting,
        setFieldValue,
        setValues,
        initialValues
      }) => {
        return (
          <>
            <Box widthMd="100%">
              <DashForm
                id="one-time-deposit"
                gap={24}
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                {error && (
                  <FormMessage variant="error">
                    {error.length > 0 ? (
                      <div>{error}</div>
                    ) : (
                      <FormError errorCode={error.code || error.message} />
                    )}
                  </FormMessage>
                )}
                {step === 'form' && (
                  <FormStep
                    isValid={isValid}
                    defaultDate={defaultDate}
                    setFieldValue={setFieldValue}
                    dueDate={dueDate}
                    dmfReason={dmfReason}
                    minimumDepositAmount={MIN_DEPOSIT}
                    next={() => {
                      analyticsTrackEvent(
                        {
                          category: 'csr',
                          label:
                            'additional_deposit' + getEventExtension(location),
                          action: 'review',
                          value: values.amount
                        },
                        'Onetime Deposit Review'
                      );
                      setStep('confirm');
                    }}
                  />
                )}
                {step === 'confirm' && (
                  <ConfirmStep
                    isLoading={isSubmitting}
                    amount={values.amount}
                    depositDate={values.depositDate}
                  />
                )}
              </DashForm>
            </Box>
            <Box flex justifyContent="center" paddingTop={24}>
              {step === 'form' &&
                (/customer-service/i.test(location.pathname) ? (
                  <Link as={NavLink} to="..">
                    {t('common.back')}
                  </Link>
                ) : (
                  <Link
                    onClick={goToSelect}
                    style={{
                      color: isASOdeposit && 'grey',
                      cursor: isASOdeposit && 'not-allowed'
                    }}
                  >
                    {t('common.back')}
                  </Link>
                ))}
              {step === 'confirm' && (
                <Link
                  onClick={(evt) => {
                    if (isSubmitting) {
                      evt.preventDefault();
                    } else {
                      setStep('form');
                    }
                  }}
                >
                  {/* NEED TO REMOVE FOR ASO 1ST STEP */}
                  {t('common.back')}
                </Link>
              )}
              {step === 'form' && (
                <>
                  <Box paddingHorizontal={8}>|</Box>
                  <Link onClick={() => setValues({ ...initialValues })}>
                    {t('common.reset')}
                  </Link>
                </>
              )}
            </Box>
          </>
        );
      }}
    </Formik>
  );
}

export function OneTimeDeposit({
  goToSelect,
  close,
  dueDate,
  minimumDepositAmount,
  dmfReason
}) {
  const [step, setStep] = useState('form');
  const [successData, setSuccessData] = useState();
  const client = useSelector(getClient);
  const futureDrafts = useSelector(getFutureDrafts);
  const location = useLocation();
  const { t } = useTranslation();

  const headings = {
    form: t('changeDepositWizard.oneTimeDeposit'),
    confirm: t('changeDepositWizard.oneTimeDeposit'),
    success: (
      <Box flex alignItems="center">
        <Icon icon={CheckmarkCircle} size={24} color="secondaryGreen" />
        <Box marginLeft={8}>
          {t('customerService.oneTimeDeposit.niceGoing')}
        </Box>
      </Box>
    )
  };

  const headingComponentToUse = (() => {
    if (step === 'success') {
      return headings.success;
    } else {
      return headings[step];
    }
  })();

  return (
    <CustomPanel heading={headingComponentToUse} close={close}>
      {futureDrafts.length > 0 ? (
        step === 'success' ? (
          <Box flex flexDirection="row" style={{ gap: '24px' }}>
            {client.spa === constants.STATEMENT_PROVIDER.CFT && (
              <Box>
                {t('changeDepositWizard.yourOneTimeAdditionalDepositOf')}{' '}
                <Currency value={successData?.amount} />{' '}
                {t('changeDepositWizard.hasBeenScheduledFor')}{' '}
                {shortDateWithLongYear(successData?.depositDate)}
              </Box>
            )}
            {client.spa === constants.STATEMENT_PROVIDER.GCS && (
              <Box>
                {t('changeDepositWizard.yourOneTimeAdditionalDepositOfGSA')}{' '}
                <Currency value={successData?.amount} />{' '}
                {t('changeDepositWizard.hasBeenReceivedGSA')}
              </Box>
            )}
            {/** Present an OK, thanks button if the user is adjusting their deposit on the customer service page */}
            {/customer-service/i.test(location.pathname) ? (
              <NavLink
                to="/customer-service/deposits"
                style={{ textDecoration: 'none', width: '100%' }}
              >
                <Button
                  variant="tertiary"
                  height={40}
                  width="100%"
                  rightEnhancer={<Icon icon={ChevronThinRight} size={14} />}
                >
                  {t('changeDepositWizard.okThanks')}
                </Button>
              </NavLink>
            ) : null}
          </Box>
        ) : (
          <OneTimeForm
            goToSelect={goToSelect}
            spa={client.spa}
            step={step}
            setStep={setStep}
            setSuccessData={setSuccessData}
            minimumDepositAmount={minimumDepositAmount}
            dmfReason={dmfReason}
            dueDate={dueDate}
          />
        )
      ) : (
        <UnableToPerformRequest goToSelect={goToSelect} />
      )}
    </CustomPanel>
  );
}

export default OneTimeDeposit;
