import { createSelector } from 'reselect';

const getAccounts = (state) => state.models.suipDebtAccount ?? [];

// Logic pulled directly from 3.0 codebase
const suipDebtAccountsData = (rawDebtAccounts) => {
  const debtAccounts = [];
  const eligibleAccounts = [];
  const nonEligibleAccounts = [];

  for (let i = 0; i < rawDebtAccounts.length; i++) {
    const curAccount = rawDebtAccounts[i];
    if (curAccount.debtAccountStatus === 'Active') {
      debtAccounts.push(curAccount); // debts i want to enroll
    } else if (curAccount.verified === 'Yes') {
      eligibleAccounts.push(curAccount); // debts i do not want to enroll
    } else {
      nonEligibleAccounts.push(curAccount); // debts i cannot enroll
    }
  }

  return {
    debtAccounts,
    eligibleAccounts,
    nonEligibleAccounts
  };
};

export const getSuipDebtAccountsByType = createSelector(
  getAccounts,
  suipDebtAccountsData
);
