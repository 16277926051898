import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import * as EN from './translations/en-US';
import * as ES from './translations/es';

// We're reading the ?language={SOME VALUE} query parameter
// This is used to set a sensible default for a non-logged in user in the auto settlement auth pages
const languageParam = new URLSearchParams(window.location.search).get(
  'language'
);

i18n
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    resources: {
      en: {
        translation: { ...EN }
      },
      es: {
        translation: { ...ES }
      }
    },
    fallbackLng: 'en',
    lng: languageParam ?? '', // Defaulting to the language parameter in the URL, if it's passed
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
