import { axiosInstance as axios } from 'lib/axios';

import envConfig from 'envConfig';

export const validateBank = async (payload) => {
  try {
    const endpoint = `${envConfig.FIREBASE_FUNCTION_HOSTNAME}/bankValidate`;

    const response = await axios.post(endpoint, payload, {
      validateStatus: (status) => status === 200
    });

    return response.data;
  } catch (error) {
    const errObj = { ...error, type: 'validateBank' };
    throw errObj;
  }
};
