import { useEffect } from 'react';

import envConfig from 'envConfig';

export const GenesysScript = ({ id, name, authCode, language }) => {
  useEffect(() => {
    // Wait for the Genesys library to initialize and establish WebSocket connection
    if (typeof Genesys === 'undefined') {
      // Genesys initialization code
      (function (g, e, n, es, ys) {
        g['_genesysJs'] = e;
        g[e] =
          g[e] ||
          function () {
            (g[e].q = g[e].q || []).push(arguments);
          };
        g[e].t = 1 * new Date();
        g[e].c = es;
        ys = document.createElement('script');
        ys.async = 1;
        ys.src = n;
        ys.charset = 'utf-8';
        document.head.appendChild(ys);
        // Cleanup function for component will unmount
        return () => {
          // Remove the Genesys script when the component is unmounted
          document.head.removeChild(ys);
        };
      })(
        window,
        'Genesys',
        'https://apps.usw2.pure.cloud/genesys-bootstrap/genesys.min.js',
        {
          environment: envConfig.GENESYS_ENVIRONMENT,
          deploymentId: envConfig.GENESYS_DEPLOYMENT_ID,
          debug: envConfig.GENESYS_DEBUG_FLAG
        }
      );

      // eslint-disable-next-line no-undef
      Genesys('registerPlugin', 'AuthProvider', (AuthProvider) => {
        /* Register Command - mandatory */
        AuthProvider.registerCommand('getAuthCode', (e) => {
          e.resolve({
            authCode: authCode,
            redirectUri: envConfig.AUTH0_FDR_CALLBACK_URL,
            iss: envConfig.AUTH0_FDR_ISSUER
          });
        });
        AuthProvider.registerCommand('reAuthenticate', (e) => {
          e.resolve();
        });
        /* Subscribe to Auth plugin events */
        AuthProvider.subscribe('Auth.loggedOut', () => {
          // This event is published across the browser tabs/devices where the user is logged in.
        });

        // Tell Messenger that your plugin is ready (mandatory)
        AuthProvider.ready();
      });

      // Call the Genesys function after the delay
      setTimeout(() => {
        // eslint-disable-next-line no-undef
        Genesys('command', 'Database.set', {
          messaging: {
            customAttributes: {
              SF_URLPop: id,
              name: name,
              LanguageSkill: language
            }
          }
        });
      }, 100);
    }
  }, [id, name, authCode, language]);
  return null;
};
