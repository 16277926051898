import React from 'react';
import styled from 'styled-components';

// needs to match styling in /ui/Dropdown
const DropdownAnchorItem = styled.a`
  display: flex;
  align-items: center;
  line-height: 30px;
  padding: 12px 40px;
  font-size: 18px;
  color: ${(props) => props.theme.colors.secondaryBlueLight};
  font-weight: 700;
  text-decoration: none;
  transition: background 0.1s ease-in-out;
  &:hover {
    cursor: pointer;
    background: ${(props) => props.theme.colors.grayLight};
  }
`;

// must be a class to be accepted as a prop in DropdownItem
export class ReturnToMemberDashboardMenuItem extends React.Component {
  render() {
    const { to, children } = this.props;
    return <DropdownAnchorItem href={to}>{children}</DropdownAnchorItem>;
  }
}
