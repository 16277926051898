import { useSelector } from 'react-redux';
import { useLaunchDarkly } from './useLaunchDarkly';
import { getUserRecord } from 'shared-selectors/userRecord';

export function usePromoBanner() {
  const { flags, activeFlagNames } = useLaunchDarkly();
  const userAccount = useSelector(getUserRecord);

  const isFDR =
    userAccount?.lawFirmId === 1059 || userAccount?.lawFirmId === '1059';
  const featureFlagEnabled = flags[activeFlagNames.taxPromoBanner] === 'show';

  return { showPromoBanner: isFDR && featureFlagEnabled };
}
