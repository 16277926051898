import React from 'react';
import { Box } from 'atomic-layout';
import { SelectTable, Icon, Link } from '@ffn/ui';
import { useBrand } from 'lib/hooks';
import { CheckmarkCircle } from '@ffn/icons';
import { useTranslation } from 'lib/hooks';
import { useSelector } from 'react-redux';

export function Success({ sharedColumns, omittedAccounts, selectedAccounts }) {
  const { t } = useTranslation();
  const brand = useBrand();
  const userRecord = useSelector((state) => state.userRecord);

  const addedAccounts = omittedAccounts.filter((acct) => {
    return selectedAccounts.includes(acct.omittedAccountId);
  });
  const declinedAccounts = omittedAccounts.filter((acct) => {
    return !selectedAccounts.includes(acct.omittedAccountId);
  });

  const columns = React.useMemo(
    () => [
      sharedColumns.creditor,
      sharedColumns.accountNumber,
      sharedColumns.currentBalance
    ],
    [sharedColumns]
  );
  return (
    <>
      <Box
        flex
        alignItems="center"
        paddingBottom={0}
        style={{ fontWeight: 600 }}
      >
        <Icon icon={CheckmarkCircle} size={24} color="secondaryGreen" />
        <Box
          marginLeft={8}
          css={`
            color: ${({ theme }) => theme.colors.secondaryGreen};
          `}
        >
          {t('alerts.addAccountOpportunity.success')}
        </Box>
      </Box>
      <Box paddingBottom={12} paddingTop={0}>
        {addedAccounts && addedAccounts.length !== 0 && (
          <>
            <Box marginBottom={24}>
              <p>
                {t('alerts.addAccountOpportunity.weHaveReceivedYour')}{' '}
                {t('alerts.addAccountOpportunity.toCompleteTheEnrollment')}{' '}
                {t('alerts.addAccountOpportunity.toDocuSignInTheNextTwo')}{' '}
                {t('alerts.addAccountOpportunity.enrollmentOfTheAccountS')}{' '}
                {t(
                  'alerts.addAccountOpportunity.theAccountSOnYourEnrolledDebtPage'
                )}
              </p>
              <Box marginTop={8} data-testid="added-accounts-table">
                <SelectTable
                  columns={columns}
                  data={addedAccounts}
                  showHeaders={true}
                />
              </Box>
            </Box>
            <LegalDisclaimer
              isFDR={userRecord && userRecord?.app.toLowerCase() === 'fdr'}
            />
          </>
        )}
        {declinedAccounts && declinedAccounts.length !== 0 && (
          <>
            <hr />
            <Box>
              <p>
                {t('alerts.addAccountOpportunity.youHaveChosenNotToEnroll')}
              </p>
              <Box marginTop={8} data-testid="declined-accounts-table">
                <SelectTable
                  columns={columns}
                  data={declinedAccounts}
                  showHeaders={true}
                />
              </Box>
            </Box>
          </>
        )}
      </Box>
      <Box flex justifyContent="space-between" gap={16}>
        <Box flex flexDirection="column">
          <strong>{t('alerts.addAccountOpportunity.questions')} </strong>
          <Link
            data-testid="contact-customer-service-link"
            href={`tel:${brand('contact.customer-service.phone')}`}
          >
            {t('alerts.addAccountOpportunity.contactCustomerService')}
          </Link>
        </Box>
      </Box>
    </>
  );
}

// AS OF 01/2023, REQUIRED TO RENDER THIS FOR ALL FDR CLIENTS PER LEGAL
const LegalDisclaimer = ({ isFDR }) => {
  const { t } = useTranslation();
  return isFDR ? (
    <p data-testid="aao-success-disclaimer">
      {t(
        'alerts.addAccountOpportunity.byEnrollingTheDebtListedHereinIAgreeAnd'
      )}
    </p>
  ) : null;
};
