import React from 'react';
import styled from 'styled-components';
import { Box } from 'atomic-layout';
import { Icon } from '@ffn/ui';
import { CheckmarkCircle } from '@ffn/icons';
import { useTranslation } from 'lib/hooks';
const HeaderContainer = styled.div`
  color: ${(props) => props.theme.colors.secondaryGreen};
  background-color: white;
  padding: 16px;
  span {
    font-weight: bold;
    margin-left: 24px;
  }
`;

export const SuccessMessage = ({
  isFromAlertBox,
  scheduledTime,
  hasRescheduled
}) => {
  const { t } = useTranslation();

  return (
    <>
      {!isFromAlertBox ? (
        <HeaderContainer key={0}>
          <Icon icon={CheckmarkCircle} size={20}></Icon>
          {hasRescheduled ? (
            <span>
              {t('alerts.milestoneCalls.callSuccessfullyRescheduled')}
            </span>
          ) : (
            <span>{t('alerts.milestoneCalls.success')}</span>
          )}
        </HeaderContainer>
      ) : null}
      <Box paddingHorizontal={!isFromAlertBox ? 16 : 0}>
        {hasRescheduled ? (
          <>
            <p>
              {t('alerts.milestoneCalls.yourProgramUpdateCallHas')}{' '}
              {scheduledTime}.
            </p>
          </>
        ) : (
          <>
            <p>
              {t('alerts.milestoneCalls.youReAllSetWeLookForward')}{' '}
              {scheduledTime}.{' '}
              {t('alerts.milestoneCalls.beSureToUpdateYourCalendar')}{' '}
            </p>
            <p>
              {t('alerts.milestoneCalls.inTheMeantimeStayFocusedOn')}{' '}
              <a href="/notifications/alerts">
                {t('alerts.milestoneCalls.alerts')}
              </a>{' '}
              {t('alerts.milestoneCalls.onYourClientDashboard')}
            </p>
          </>
        )}
      </Box>
    </>
  );
};
