import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';

import { parseRichText } from '.';

export const parseBlogContent = (field) => {
  const contentNodes = [];
  let codeCollected = '';
  field.content.forEach((block, i) => {
    const { content } = block;
    if (content[0].marks && hasMark(content[0].marks, 'code')) {
      codeCollected += content[0].value + '\n';
    } else {
      if (codeCollected !== '') {
        contentNodes.push(
          <ReactMarkdown
            key={i}
            rehypePlugins={[rehypeRaw, rehypeSanitize]}
            remarkPlugins={[remarkGfm]}
          >
            {codeCollected}
          </ReactMarkdown>
        );
      }
      codeCollected = '';
      contentNodes.push(
        parseRichText({ nodeType: 'document', data: {}, content: [block] })
      );
    }
  });
  return contentNodes;
};

const hasMark = (marks, mark) => {
  const markList = marks.filter((markSearch) => markSearch.type === mark);
  return markList.length > 0;
};
