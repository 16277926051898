const { version } = require('../../../package.json');

export const DD_CONFIG_SITE = 'datadoghq.com';

export const APP_VERSION = version;

import envConfig from 'envConfig';

const {
  DD_SERVICE,
  DATADOG_APP_ENV: APP_ENV, // Dashboard uses TEALIUM_ENV for DEV, STG, PRD
  DATADOG_ACX_RUM_APP_ID,
  DATADOG_ACX_RUM_CLIENT_TOKEN,
  DATADOG_RUM_APP_ID,
  DATADOG_RUM_CLIENT_TOKEN,
  DD_RUM_ENABLED,
  DD_SESSION_REPLAY_SAMPLE_RATE
} = envConfig;

const DATADOG_RUM = {
  DATADOG_APPLICATION_ID: DATADOG_RUM_APP_ID,
  DATADOG_CLIENT_TOKEN: DATADOG_RUM_CLIENT_TOKEN
};

const DATADOG_ACX_RUM = {
  DATADOG_APPLICATION_ID: DATADOG_ACX_RUM_APP_ID,
  DATADOG_CLIENT_TOKEN: DATADOG_ACX_RUM_CLIENT_TOKEN
};

export const CLIENT_ID_APP_ID_MAP = {
  achieve: DATADOG_ACX_RUM,
  fdr: DATADOG_RUM,
  turnbull: DATADOG_RUM
};

export { DD_SERVICE, APP_ENV, DD_RUM_ENABLED, DD_SESSION_REPLAY_SAMPLE_RATE };
