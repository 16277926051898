import styled from 'styled-components';
import { query } from 'atomic-layout';

export const FormStepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FieldsContainer = styled.div`
  @media ${query({ from: 'sm' })} {
    display: flex;
    width: 100%;

    > div {
      &:first-child {
        margin-right: 25px;
      }
      width: 50%;
    }
  }
`;

export const EFTAContainer = styled.div`
  display: flex;
  margin-top: 12px;
  margin-bottom: 16px;
`;
