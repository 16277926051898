import React, { Suspense, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Composition } from 'atomic-layout';
import { Helmet } from 'react-helmet-async';
import 'styled-components/macro';
import { useQuery } from 'react-query';
import { useWebviewAuth } from '@achieve/cx-auth';

import {
  useAuth,
  useBrand,
  useDashboardType,
  useLaunchDarkly
} from 'lib/hooks';

import {
  ChangePasswordModal,
  EduWizard,
  FallBackLoader,
  FeatureFlag,
  GenesysScript,
  logger,
  StickyFooterBlock,
  UpscopeInit
} from 'shared-components';
import {
  AchieveFooter,
  ErrorFallbackRouter,
  Footer as FDRFooter,
  Nav
} from 'shared-pages/common';
import { ErrorBoundary } from 'react-error-boundary';
import {
  analyticsHotjarIdentify,
  analyticsTrackEvent
} from 'lib/utils/analytics';
import { getLawFirmId } from 'shared-selectors/userRecord';
import { getClientLanguage } from 'shared-selectors/client';
import { getLifecycleMarketingSegment } from 'shared-selectors/enrolled-debt';
import { Modal as NativeAppPromoModal } from 'shared-components/AppPromo/Modal';
import BrandSpecificRoutes, { FDRRoutes } from './routes';
import envConfig from 'envConfig';

const areas = `
  header
  main
  footer
`;

/**
 * Renders an iframe element used for authentication.
 * Retrieves a code parameter from the URL's query string and passes it to a callback function.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.authCodeFunction - The callback function to be called with the code parameter.
 * @returns {JSX.Element} - The rendered iframe element.
 */
function AuthIframe({ authCodeFunction }) {
  const redirect_uri = envConfig.GENESYS_REDIRECT_URL;
  const audience = envConfig.AUTH0_FDR_AUDIENCE;
  const login_url = envConfig.AUTH0_FDR_ISSUER;
  const client_id = envConfig.GENESYS_CLIENT_ID;
  const iframeSrc = `${login_url}authorize?client_id=${client_id}&scope=openid+email+name+profile+offline_access&audience=${audience}&redirect_uri=${redirect_uri}&prompt=none&response_type=code`;

  /**
   * Handles the iframe's load event.
   * Extracts the code parameter from the URL's query string and calls the authCodeFunction callback function.
   *
   * @param {Event} event - The load event object.
   */
  const handleLoad = (event) => {
    try {
      const params = new URLSearchParams(
        event.target.contentWindow.location.search
      );
      const code = params.get('code');
      if (code) {
        authCodeFunction(code);
      }
    } catch (error) {
      logger.error('Error from onload event:', error);
    }
  };

  /**
   * Handles the iframe's error event.
   *
   * @param {Event} event - The error event object.
   */
  const handleError = (event) => {
    logger.error('Error:', event.message);
  };

  return (
    <iframe
      src={iframeSrc}
      width={0}
      height={0}
      title="Auth iFrame"
      style={{ display: 'none' }}
      onLoad={handleLoad}
      onError={handleError}
    ></iframe>
  );
}

export function Dashboard() {
  const brand = useBrand();
  const { isAuthenticated } = useAuth();
  const { subDomain } = useDashboardType();
  const navigate = useNavigate();
  const { flags, activeFlagNames } = useLaunchDarkly();
  const lawFirmId = useSelector(getLawFirmId);
  const { isWebview } = useWebviewAuth();
  const lcmSegment = useSelector(getLifecycleMarketingSegment);
  const showUpscope =
    flags &&
    flags[activeFlagNames.loadUpscopeScript] === 'show' &&
    // eslint-disable-next-line eqeqeq
    lawFirmId != '3001'; // keep non type sensitive
  const showUpscopeAR =
    flags &&
    flags[activeFlagNames.arDashboardCobrowse] === 'show' &&
    // eslint-disable-next-line eqeqeq
    lawFirmId == '3001';

  const userRecord = useSelector((state) => state.userRecord);
  const { csClientId, fdrApplicantId, firstName, lastName } = userRecord;
  const clientName = `${firstName} ${lastName}`;
  const clientLangFromSf = useSelector(getClientLanguage);
  const [iFrameRendered, setIFrameRendered] = useState(true);
  const [code, setCode] = useState('');
  const isFDR = subDomain === 'fdr';
  const iframeAuth0Code = new URLSearchParams(window.location.search).get(
    'code'
  );

  // Not assigning any variables as this is not a client facing call
  // and the retry logic is handled within useQuery and analyticsHotjarIdentify
  useQuery(
    ['hotjarIdentifySignIn', fdrApplicantId, lawFirmId, lcmSegment],
    () => analyticsHotjarIdentify(fdrApplicantId, { lawFirmId, lcmSegment }),
    {
      enabled: !!fdrApplicantId && !!lawFirmId && !!lcmSegment,
      staleTime: Infinity,
      retry: 1
    }
  );

  let canRenderGenesysScript;
  if (isAuthenticated && csClientId && isFDR && code && clientLangFromSf) {
    canRenderGenesysScript = true;
  } else {
    canRenderGenesysScript = false;
  }
  useEffect(() => {
    if (code) {
      setIFrameRendered(false);
    }
  }, [code]);

  const errorBoundaryErrorHandler = (error) => {
    analyticsTrackEvent(
      { category: 'render', action: 'render', label: 'error' },
      `Render Error Caught by Error Boundary: ${error}`
    );
  };

  // Define the routes and components we want to render if Dashboard
  // is being loaded as WebView via the native app
  if (isWebview) {
    return (
      <Composition
        areas={areas}
        templateCols="1fr"
        templateRows="1fr"
        height="100vh"
      >
        {({ Main }) => (
          <div
            css={`
              background-color: ${(props) =>
                props.theme.colors.backgroundColor};
              height: 100%;
            `}
          >
            <ErrorBoundary
              FallbackComponent={ErrorFallbackRouter}
              onError={errorBoundaryErrorHandler}
              onReset={() => {
                navigate('/page-error');
              }}
            >
              <Main height="100%">
                <Suspense fallback={<FallBackLoader isPage />}>
                  <FDRRoutes />
                </Suspense>
                {iFrameRendered && isFDR && (
                  <AuthIframe authCodeFunction={setCode} />
                )}
              </Main>
            </ErrorBoundary>
          </div>
        )}
      </Composition>
    );
  }

  // Regular web app rendering
  return (
    <Composition
      areas={areas}
      templateCols="1fr"
      templateRows="auto 1fr auto"
      height="100vh"
    >
      {({ Header, Main, Footer }) => (
        <>
          <Helmet>
            <title>{brand('business-name.long')} Dashboard</title>
          </Helmet>
          <Header>
            <Nav />
            {canRenderGenesysScript && (
              <GenesysScript
                id={csClientId}
                name={clientName}
                authCode={code}
                language={clientLangFromSf}
              />
            )}
            {showUpscope || showUpscopeAR ? <UpscopeInit /> : null}
          </Header>
          <div
            css={`
              background-color: ${(props) =>
                props.theme.colors.backgroundColor};
              height: 100%;
            `}
          >
            <ErrorBoundary
              FallbackComponent={ErrorFallbackRouter}
              onError={errorBoundaryErrorHandler}
              onReset={() => {
                navigate('/page-error');
              }}
            >
              <Main height="100%">
                <Suspense fallback={<FallBackLoader isPage />}>
                  <BrandSpecificRoutes subDomain={subDomain} />
                </Suspense>
                {!iframeAuth0Code && iFrameRendered && isFDR && (
                  <AuthIframe authCodeFunction={setCode} />
                )}
              </Main>
            </ErrorBoundary>
            {isAuthenticated && <EduWizard />}
          </div>
          <FeatureFlag flagKey="footer.footer">
            <Footer>
              {subDomain === 'achieve' ? <AchieveFooter /> : <FDRFooter />}
            </Footer>
          </FeatureFlag>
          <ChangePasswordModal />
          <NativeAppPromoModal />
          <StickyFooterBlock />
        </>
      )}
    </Composition>
  );
}
