import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronThinLeft } from '@styled-icons/entypo/ChevronThinLeft';
import { Box } from 'atomic-layout';
import { Icon } from '@ffn/ui';
import 'styled-components/macro';
import { useTranslation } from 'lib/hooks';

export function UtilityButtons({ resetForm, resetDocumentVault }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClick = () => {
    if (resetDocumentVault) {
      return resetDocumentVault();
    } else return navigate('/customer-service/document-upload');
  };

  return (
    <Box flex justifyContent="center">
      <div
        onClick={handleClick}
        css={`
          color: ${(props) => props.theme.colors.secondaryBlue};
          text-decoration: none;
          &:hover {
            cursor: pointer;
          }
        `}
        data-testid="back-button"
      >
        <Icon
          icon={ChevronThinLeft}
          size={12}
          css={`
            margin-right: 4px;
          `}
        />
        {t('common.back')}
      </div>
      <span
        css={`
          padding: 0 8px;
        `}
      >
        |
      </span>
      <span
        css={`
          margin: 0;
          display: inline;
          &:hover {
            cursor: pointer;
          }
        `}
        data-testid="reset-button"
        onClick={resetDocumentVault ? resetDocumentVault : resetForm}
      >
        {t('common.reset')}
      </span>
    </Box>
  );
}

export default UtilityButtons;
