import React, { useState, useContext, createContext } from 'react';
import {
  format,
  parseISO,
  isBefore,
  isSameDay,
  isAfter,
  addDays,
  addWeeks,
  addMonths,
  subHours,
  differenceInDays,
  subBusinessDays,
  differenceInCalendarMonths,
  differenceInHours
} from 'date-fns/fp';
import { analyticsTrackEvent } from 'lib/utils/analytics';
import i18next from 'i18next';

const changeDepositContext = createContext();

export function ProvideChangeDeposit({ children }) {
  const changeDepositOptions = useProvideChangeDeposit();
  return (
    <changeDepositContext.Provider value={changeDepositOptions}>
      {children}
    </changeDepositContext.Provider>
  );
}

export function useChangeDeposit() {
  return useContext(changeDepositContext);
}

function useProvideChangeDeposit() {
  const [selectedDeposit, setSelectedDeposit] = useState(null);
  const [dfliUpdated, setDfliUpdated] = useState(null);
  const [newDeposit, setNewDeposit] = useState(null);
  const [firstBreakageDate, setFirstBreakageDate] = useState(null);
  const [atRiskSettlements, setAtRiskSettlements] = useState({});
  const [optionValues, setOptionValues] = useState(null);
  const [variant, setVariant] = useState(null);
  const [optionsChoice, setOptionsChoice] = useState('review');
  const [optionNewDeposit, setOptionNewDeposit] = useState(null);
  const [additionalDeposit, setAdditionalDeposit] = useState(null);
  const [dfd, setDfd] = useState(null);
  const [canModify, setCanModify] = useState(null);
  const [lastSteps, setLastSteps] = useState([]);

  function previousStep() {
    const newLastSteps = lastSteps;
    const step = newLastSteps.pop();
    setLastSteps(newLastSteps);
    return step;
  }

  async function selectDeposit(deposit) {
    try {
      setSelectedDeposit(deposit);
    } catch (e) {
      setSelectedDeposit(null);
      throw e;
    }
  }

  async function updateDfli(updatedDfli) {
    try {
      setDfliUpdated(updatedDfli);
    } catch (e) {
      setDfliUpdated(null);
      throw e;
    }
  }

  async function dfliWithChanges({ allDraftFees, newDeposit }) {
    setNewDeposit(newDeposit);
    setOptionNewDeposit(newDeposit);
    const { depositAmount, date, cancel, id } = newDeposit;
    return {
      ...allDraftFees,
      futureDrafts: allDraftFees.futureDrafts.map(
        ({ draftFeeWrapper, ...restOfDraft }) => {
          if (id === draftFeeWrapper.id) {
            return {
              ...restOfDraft,
              isChanged: true,
              draftFeeWrapper: {
                ...draftFeeWrapper,
                overrideDraftDate: depositAmount <= 0 || cancel ? null : date,
                overrideDebitAmount:
                  depositAmount <= 0 || cancel
                    ? 0
                    : Number(Number(depositAmount).toFixed(2)),
                ...(cancel && {
                  deferral: true
                }),
                spaActive: !cancel
              }
            };
          }
          return { ...restOfDraft, draftFeeWrapper };
        }
      )
    };
  }

  async function dfliCalculation({
    dfliResponse,
    settlementsById,
    accountsById
  }) {
    try {
      if (
        dfliResponse !== undefined &&
        dfliResponse.draftFeeLineItems !== null &&
        dfliResponse.draftFeeLineItems.length > 0
      ) {
        const negativeItems = [];
        let currentlyNegative = false;
        let goneNegative = false;
        let tmpNegative = [];
        for (let i = 0; i < dfliResponse.draftFeeLineItems.length; i++) {
          const curItem = dfliResponse.draftFeeLineItems[i];
          if (curItem.Forecasted_Account_Balance__c < 0) {
            goneNegative = true;
          } else {
            goneNegative = false;
          }

          if (goneNegative) {
            currentlyNegative = true;
            tmpNegative.push(curItem);
          } else if (!goneNegative && currentlyNegative) {
            currentlyNegative = false;
            negativeItems.push(tmpNegative);
            tmpNegative = [];
          }
        }

        let largestAmount = 0;
        let largestAmountList = [];

        for (let i = 0; i < negativeItems.length; i++) {
          const curNegList = negativeItems[i];
          let listTotal = 0;
          for (let x = 0; x < curNegList.length; x++) {
            const curNegItem = curNegList[x];
            if (['Payment', 'Fee'].includes(curNegItem.Transaction_Type__c)) {
              if (x === 0) {
                listTotal += Math.abs(curNegItem.Forecasted_Account_Balance__c);
              } else {
                listTotal += Number(curNegItem.Transaction_Amount__c);
              }
            }
          }

          if (largestAmount < listTotal) {
            largestAmount = listTotal;
            largestAmountList = negativeItems[i];
          }
        }

        const atRiskSettlements = {};
        for (let i = 0; i < largestAmountList.length; i++) {
          const curNegItem = largestAmountList[i];
          if (curNegItem.Transaction_Type__c === 'Payment') {
            const settlementId = curNegItem.Settlement__c;
            if (atRiskSettlements[settlementId] === undefined) {
              const settlement = settlementsById[settlementId];
              const account = accountsById[settlement.account];
              const debtReductionAmount =
                Number(account.beginningBalance) -
                Number(settlement.settlementAmount);
              atRiskSettlements[settlementId] = {
                breakageDate: parseISO(curNegItem.Transaction_Clear_Date__c),
                deficitAmount:
                  Object.keys(atRiskSettlements).length === 0
                    ? Math.abs(curNegItem.Forecasted_Account_Balance__c)
                    : Number(curNegItem.Transaction_Amount__c),
                creditor: account.originalCreditor,
                debtReduction: debtReductionAmount
              };
            } else {
              atRiskSettlements[settlementId].deficitAmount += Number(
                curNegItem.Transaction_Amount__c
              );
            }
          } else if (curNegItem.Transaction_Type__c === 'Fee') {
            if (atRiskSettlements['fees'] === undefined) {
              atRiskSettlements['fees'] = {
                breakageDate: parseISO(curNegItem.Transaction_Clear_Date__c),
                deficitAmount:
                  Object.keys(atRiskSettlements).length === 0
                    ? Math.abs(curNegItem.Forecasted_Account_Balance__c)
                    : Number(curNegItem.Transaction_Amount__c)
              };
            } else {
              atRiskSettlements['fees'].deficitAmount += Number(
                curNegItem.Transaction_Amount__c
              );
            }
          }
        }

        const firstBreakageDate = negativeItems.length
          ? parseISO(largestAmountList[0].Transaction_Clear_Date__c)
          : null;

        setFirstBreakageDate(firstBreakageDate);
        setAtRiskSettlements(atRiskSettlements);

        return {
          firstBreakageDate,
          atRiskSettlements
        };
      }
    } catch (e) {
      console.log(e);
    }
  }

  function calculateOptions(
    dfliResponse,
    firstBreakageDate,
    atRiskSettlements
  ) {
    const { t } = i18next;
    const { depositAmount, cancel, date } = newDeposit;
    let atRiskSettlementsAmount = 0;
    const settlementsAtRisk = [];
    for (const [key, value] of Object.entries(atRiskSettlements)) {
      settlementsAtRisk.push(key);
      atRiskSettlementsAmount += value.deficitAmount;
    }
    const newDepositDate = parseISO(date);
    const selectedDepositDate = parseISO(selectedDeposit.date);
    const isPositiveDateChange =
      isSameDay(newDepositDate, firstBreakageDate) ||
      isBefore(newDepositDate, firstBreakageDate);
    const minimumSaveAmount =
      (firstBreakageDate !== null && !isPositiveDateChange) || cancel === true
        ? atRiskSettlementsAmount
        : atRiskSettlementsAmount + depositAmount;
    const optionVals = {
      optionAdditional: {
        deposit: {
          amount:
            firstBreakageDate !== null && !isPositiveDateChange
              ? selectedDeposit.depositAmount - atRiskSettlementsAmount
              : depositAmount,
          date: newDepositDate
        },
        additional: {
          amount:
            firstBreakageDate !== null && !isPositiveDateChange
              ? atRiskSettlementsAmount
              : cancel === true
                ? selectedDeposit.depositAmount
                : selectedDeposit.depositAmount - depositAmount,
          date:
            firstBreakageDate !== null &&
            differenceInDays(
              subBusinessDays(4, firstBreakageDate),
              selectedDepositDate
            ) < 30
              ? subBusinessDays(4, firstBreakageDate)
              : addDays(30, selectedDepositDate)
        }
      },
      optionNoAdditional: {
        amount:
          depositAmount < minimumSaveAmount || cancel === true
            ? minimumSaveAmount
            : depositAmount,
        date: isPositiveDateChange ? newDepositDate : firstBreakageDate
      },
      optionContinue: {
        amount: depositAmount,
        date: newDepositDate
      },
      minimumSaveAmount,
      additionalAmount:
        cancel === true
          ? selectedDeposit.depositAmount
          : selectedDeposit.depositAmount - depositAmount,
      depositInstead:
        atRiskSettlements !== undefined
          ? atRiskSettlementsAmount + (cancel === true ? 0 : depositAmount)
          : 0,
      failDateDays:
        firstBreakageDate !== null
          ? differenceInDays(firstBreakageDate, new Date())
          : 0,
      isAmountGreaterThanOriginal:
        depositAmount >= selectedDeposit.depositAmount,
      isAmountGreaterThanSave: depositAmount >= minimumSaveAmount,
      isPositiveDateSelection: isPositiveDateChange,
      latestAdditionalDepositDate: addDays(30, selectedDepositDate),
      modificationPreposition:
        cancel === true
          ? t('changeDepositWizard.of')
          : t('changeDepositWizard.to'),
      modificationVerb:
        cancel === true
          ? t('changeDepositWizard.cancelling')
          : t('changeDepositWizard.changing'),
      monthsSoonerPlural: Math.abs(dfliResponse.monthsSooner) === 1 ? '' : 's',
      monthsSooner: dfliResponse.monthsSooner,
      firstBreakageDate
    };
    setOptionValues(optionVals);
    return optionVals;
  }

  function calculateDraftChangeDebtFreeDate(
    monthlyDraftsFeeAmount,
    draftType,
    programBeginningDate,
    fdrDebtFreeDate,
    draftChanged,
    newAmount,
    additionalDeposit
  ) {
    const additionalDepositAmount =
      additionalDeposit !== null && additionalDeposit !== undefined
        ? additionalDeposit.amount
        : 0;
    const deficitAmount =
      draftChanged.depositAmount - newAmount - additionalDepositAmount;
    let additionalPaymentsNeeded = Math.ceil(
      deficitAmount / monthlyDraftsFeeAmount
    );
    const programStartDate = parseISO(programBeginningDate);
    const draftTypeLower = draftType.toLowerCase(); // from client record (monthly, split-monthly, biweekly)
    let origDebtFreeDate = parseISO(fdrDebtFreeDate);
    const oldDiff = differenceInCalendarMonths(
      programStartDate,
      origDebtFreeDate
    );
    let newDebtFreeDate = origDebtFreeDate;
    switch (draftTypeLower) {
      case 'monthly':
        newDebtFreeDate = addMonths(additionalPaymentsNeeded, origDebtFreeDate);
        break;
      case 'split':
        newDebtFreeDate = addMonths(
          additionalPaymentsNeeded / 2,
          origDebtFreeDate
        );
        break;
      case 'biweekly':
        newDebtFreeDate = addWeeks(
          additionalPaymentsNeeded * 2,
          origDebtFreeDate
        );
        break;
      default:
        return {
          newDebtFreeDate,
          percentage: 0,
          monthsSooner: 0
        };
    }
    const newDiff = differenceInCalendarMonths(
      programStartDate,
      newDebtFreeDate
    );
    const monthsSoonerAmount = Number(oldDiff - newDiff);
    const returnDfd = {
      newDebtFreeDate,
      percentage: Number(((100 * newDiff) / oldDiff).toFixed(2)),
      monthsSooner:
        monthsSoonerAmount > 0
          ? Math.ceil(monthsSoonerAmount)
          : Math.floor(monthsSoonerAmount),
      monthsSoonerPlural: Math.abs(monthsSoonerAmount) === 1 ? '' : 's'
    };
    setDfd(returnDfd);
    return returnDfd;
  }

  function calculateGradDate({
    monthlyDraftsFeeAmount,
    draftType,
    programBeginningDate,
    fdrDebtFreeDate,
    draftChanged,
    newDeposit,
    additionalDeposit
  }) {
    const additionalDepositAmount =
      additionalDeposit !== null && additionalDeposit !== undefined
        ? additionalDeposit.amount
        : 0;

    const newAmount =
      newDeposit.cancel === true
        ? 0
        : Number(
            Number(newDeposit.depositAmount) + Number(additionalDepositAmount)
          ).toFixed(2);

    const deficitAmount =
      draftChanged.depositAmount - newAmount - additionalDepositAmount;
    const additionalPaymentsNeeded = Math.ceil(
      deficitAmount / monthlyDraftsFeeAmount
    );
    const programStartDate = parseISO(programBeginningDate);
    const clientDraftType = draftType.toLowerCase(); // from client record (monthly, split-monthly, biweekly)
    const origDebtFreeDate = parseISO(fdrDebtFreeDate);
    const oldDiff = differenceInCalendarMonths(
      programStartDate,
      origDebtFreeDate
    );
    let newDebtFreeDate = origDebtFreeDate;
    switch (clientDraftType) {
      case 'monthly':
        newDebtFreeDate = addMonths(additionalPaymentsNeeded, origDebtFreeDate);
        break;
      case 'split':
        newDebtFreeDate = addMonths(
          additionalPaymentsNeeded / 2,
          origDebtFreeDate
        );
        break;
      case 'biweekly':
        newDebtFreeDate = addWeeks(
          additionalPaymentsNeeded * 2,
          origDebtFreeDate
        );
        break;
      default:
        return {
          newDebtFreeDate,
          percentage: 0,
          monthsSooner: 0
        };
    }

    const newDiff = differenceInCalendarMonths(
      programStartDate,
      newDebtFreeDate
    );
    const monthsSoonerAmount = Number(oldDiff - newDiff);
    return {
      newDebtFreeDate,
      percentage: Number(((100 * newDiff) / oldDiff).toFixed(2)),
      monthsSooner:
        monthsSoonerAmount > 0
          ? Math.ceil(monthsSoonerAmount)
          : Math.floor(monthsSoonerAmount)
    };
  }

  function prepareChangeSubmission({
    allDraftFees,
    newDeposit,
    additionalDeposit,
    amountInstead,
    selectedDeposit,
    settlementsAtRisk,
    isSaveAmountChosen
  }) {
    let effectiveDateChanged = null;
    const newDepositDate =
      typeof newDeposit.date === 'string'
        ? parseISO(newDeposit.date)
        : newDeposit.date;
    let newDraftFees = { futureDrafts: [] };
    for (let i = 0; i < allDraftFees.futureDrafts.length; i++) {
      const curDraft = allDraftFees.futureDrafts[i];
      if (curDraft.draftFeeWrapper.id === newDeposit.id) {
        newDraftFees.futureDrafts[i] = {
          ...curDraft,
          isChanged: true,
          draftFeeWrapper: {
            ...curDraft.draftFeeWrapper,
            overrideDebitAmount: Number(newDeposit.depositAmount).toFixed(2),
            overrideDraftDate: format('yyyy-MM-dd', newDepositDate)
          }
        };

        if (!newDeposit.cancel === true) {
          if (
            effectiveDateChanged === null ||
            isBefore(newDepositDate, effectiveDateChanged)
          ) {
            effectiveDateChanged = newDepositDate;
          }
        }

        if (
          newDeposit.cancel === true &&
          amountInstead !== null &&
          amountInstead !== undefined
        ) {
          newDraftFees.futureDrafts[i].draftFeeWrapper.overrideDebitAmount =
            Number(amountInstead).toFixed(2);
          newDraftFees.futureDrafts[i].draftFeeWrapper.deferral = false;
        } else if (newDeposit.cancel === true) {
          newDraftFees.futureDrafts[i] = {
            ...newDraftFees.futureDrafts[i],
            draftFeeWrapper: {
              ...newDraftFees.futureDrafts[i].draftFeeWrapper,
              deferral: true,
              spaActive: false,
              overrideDebitAmount: 0,
              overrideDraftDate: null
            }
          };
        }
        newDraftFees.futureDrafts[i].draftFeeWrapper.overrideDebitAmount =
          Number(
            newDraftFees.futureDrafts[i].draftFeeWrapper.overrideDebitAmount
          );
      } else {
        newDraftFees.futureDrafts[i] = curDraft;
      }
    }

    if (additionalDeposit !== null) {
      const draftTemplate = {
        projectedForecasteBalance: null,
        netForecasteBalance: null,
        isChanged: false,
        impactedSettlementIds: null,
        draftFeeWrapper: {
          transactionStatus: 'Scheduled',
          spaActive: true,
          settlementClientApprovalStatus: null,
          remark: '',
          overrideDraftDate: null,
          overrideDebitAmount: null,
          includeInDfli: true,
          id: null,
          draftsFeeDate: null,
          draftFeeType: 'Draft',
          deferral: false,
          debitAmount: 0
        }
      };

      draftTemplate.isChanged = true;
      draftTemplate.draftFeeWrapper.debitAmount = additionalDeposit.amount;
      draftTemplate.draftFeeWrapper.draftsFeeDate = format(
        'yyyy-MM-dd',
        additionalDeposit.date
      );
      draftTemplate.draftFeeWrapper.remark = 'Additional Draft';
      newDraftFees.futureDrafts.push(draftTemplate);
    }

    const modificationType = getChangeType({
      selectedDeposit,
      newDeposit,
      settlementsAtRisk,
      additionalDeposit,
      isSaveAmountChosen
    });

    if (effectiveDateChanged !== null) {
      newDraftFees.effectiveDate1 = format('yyyy-MM-dd', effectiveDateChanged);
    }

    newDraftFees.depositModificationType = modificationType;
    newDraftFees.depositModificationReason = newDeposit.dmfReason;
    if (
      newDeposit.impactedByCovid19 !== null &&
      newDeposit.impactedByCovid19 !== undefined
    ) {
      newDraftFees.impactedByCovid19 = newDeposit.impactedByCovid19;
    }
    return newDraftFees;
  }

  function getChangeType({
    selectedDeposit,
    newDeposit,
    settlementsAtRisk,
    additionalDeposit,
    isSaveAmountChosen
  }) {
    const newDepositDate =
      typeof newDeposit.date === 'string'
        ? parseISO(newDeposit.date)
        : newDeposit.date;
    const selectedDepositDate =
      typeof selectedDeposit.date === 'string'
        ? parseISO(selectedDeposit.date)
        : selectedDeposit.date;
    const isImpact =
      settlementsAtRisk !== undefined && settlementsAtRisk.length > 0;
    const hasMakeupDeposit =
      additionalDeposit !== null && additionalDeposit !== undefined;
    const isCancelledDeposit =
      newDeposit !== undefined ? newDeposit.cancel === true : false;
    const isNegativeDateChange =
      selectedDeposit !== undefined && newDeposit !== undefined
        ? isBefore(selectedDepositDate, newDepositDate)
        : false;
    const isPositiveDateChange =
      selectedDeposit !== undefined && newDeposit !== undefined
        ? isAfter(selectedDepositDate, newDepositDate)
        : false;
    const isNoDateChange =
      selectedDeposit !== undefined && newDeposit !== undefined
        ? isSameDay(selectedDepositDate, newDepositDate)
        : true;

    const isNegativeAmountChange =
      selectedDeposit !== undefined && newDeposit !== undefined
        ? Number(selectedDeposit.depositAmount) >
          Number(newDeposit.depositAmount)
        : false;
    const isPositiveAmountChange =
      selectedDeposit !== undefined && newDeposit !== undefined
        ? Number(selectedDeposit.depositAmount) <
          Number(newDeposit.depositAmount)
        : false;
    const isNoAmountChange =
      selectedDeposit !== undefined && newDeposit !== undefined
        ? Number(selectedDeposit.depositAmount) ===
          Number(newDeposit.depositAmount)
        : true;

    let modificationType = '';

    if (isImpact) {
      if (hasMakeupDeposit || isSaveAmountChosen) {
        modificationType = 'Neutral';
      } else {
        modificationType = 'Negative';
      }
    } else {
      if (hasMakeupDeposit) {
        if (isNegativeDateChange || isCancelledDeposit) {
          modificationType = 'Neutral';
        } else if (isNoDateChange || isPositiveDateChange) {
          if (isNoAmountChange || isNegativeAmountChange) {
            modificationType = 'Neutral';
          } else if (isPositiveAmountChange) {
            modificationType = 'Positive';
          }
        }
      } else {
        if (isNegativeAmountChange || isCancelledDeposit) {
          modificationType = 'Negative';
        } else if (isPositiveAmountChange) {
          modificationType = 'Positive';
        } else if (isNoAmountChange) {
          modificationType = 'Neutral';
        }
      }
    }

    return modificationType;
  }

  function canModifyDeposits(client, alertTasks, cases) {
    let response = {
      canModify: true,
      reason: ''
    };

    if (/(Phase 3|4)/i.test(client.cplusLeadPhase)) {
      response = {
        canModify: false,
        reason: 'CPLUS_CLIENT'
      };
    }

    if (client.spa === 'GCS' || client.spaActive !== true) {
      response = {
        canModify: false,
        reason: 'GCS_OR_INACTIVE_SPA'
      };
    }

    if (client.estimatedDebtFreeDate === null) {
      response = {
        canModify: false,
        reason: 'FDR_DEBT_FREE_DATE_NULL'
      };
    }

    if (client.spaCurrentBalance < 0) {
      response = {
        canModify: false,
        reason: 'SPA_BALANCE_NEGATIVE'
      };
    }

    const minAmount =
      client !== undefined && client.draftType === 'Monthly' ? 200 : 100;
    if (client.monthlyDraftsFeeAmount < minAmount) {
      response = {
        canModify: false,
        reason: 'DRAFT_FEE_TOO_LOW'
      };
    }

    if (client.firstDraftStatus !== 'Cleared') {
      response = {
        canModify: false,
        reason: 'FIRST_DRAFT_NOT_CLEARED'
      };
    }

    for (let i = 0; i < cases.length; i++) {
      if (
        (cases[i].subject.indexOf('Draft Modification') > -1 &&
          differenceInHours(
            parseISO(cases[i].createdDate),
            subHours(24, new Date())
          ) < 24) ||
        (cases[i].subject.indexOf('Enrollment Adjustment') > -1 &&
          !/closed|recalled|completed/i.test(cases[i].status))
      ) {
        response = {
          canModify: false,
          reason: 'EXISTING_CASE',
          date: parseISO(cases[i].createdDate)
        };
      }
    }

    if (alertTasks !== undefined) {
      for (let i = 0; i < alertTasks.length; i++) {
        if (
          ['DA Suspended', 'Settlement Conflict'].includes(
            alertTasks[i].subject
          ) &&
          !alertTasks[i].status === 'Completed'
        ) {
          response = {
            canModify: false,
            reason: 'DA_SUSPENDED'
          };
        }
      }
    }
    return response;
  }

  function dateFromString(str) {
    if (typeof str === 'string') {
      return parseISO(str);
    } else {
      return str;
    }
  }

  function compareDates(dateString1, dateString2) {
    const date1 = dateFromString(dateString1);
    const date2 = dateFromString(dateString2);
    const diff = date1.getTime() - date2.getTime();
    return Math.abs(diff) && diff / Math.abs(diff);
  }

  function compareAmounts(amt1, amt2) {
    const amtDiff = amt1 - amt2;
    return Math.abs(amtDiff) && amtDiff / Math.abs(amtDiff);
  }

  function track(type) {
    const trackingAmtMap = {
      1: '_pa', // Positive Amount Change
      [-1]: '_na', // Negative Amount Change
      0: '_xa' // NO Amount Change
    };

    const trackingOptionMap = {
      additional: '_ro',
      increase_amount: '_mo',
      review: '_oo'
    };

    const trackingDateMap = {
      [-1]: '_pd', // Positive Date Change
      1: '_nd', // Negative Date Change
      0: '_xd' // NO Date Change
    };

    const trackingImpactMap = {
      NON_BREAKING: '_xi', // NO Impact
      REVIEW_POSITIVE: '_xi', // NO Impact
      NO_IMPACT: '_xi', // NO Impact
      SHORT_TERM: '_si', // Short-Term Impact
      LONG_TERM: '_li' // Long-Term Impact
    };

    const category = 'csr';
    const label = 'single_deposit_change';
    let action;

    switch (type) {
      case 'SELECT':
        action = 'select';
        break;
      case 'FORM_SHOWN':
        action = 'select_deposit';
        break;
      default:
        const date =
          trackingDateMap[compareDates(newDeposit.date, selectedDeposit.date)];
        const amount =
          trackingAmtMap[
            compareAmounts(
              newDeposit.depositAmount,
              selectedDeposit.depositAmount
            )
          ];
        const cancel = newDeposit.cancel ? '_cancel' : null;
        action = `${type}${cancel || amount + date}${
          trackingImpactMap[variant]
        }${trackingOptionMap[optionsChoice] || '_no'}`;
        break;
    }

    analyticsTrackEvent(
      {
        category,
        action,
        label
      },
      `Single Deposit Change - ${action}`
    );
  }

  return {
    selectedDeposit,
    selectDeposit,
    newDeposit,
    dfliWithChanges,
    dfliUpdated,
    updateDfli,
    dfliCalculation,
    calculateOptions,
    firstBreakageDate,
    setFirstBreakageDate,
    atRiskSettlements,
    setAtRiskSettlements,
    optionValues,
    setOptionValues,
    calculateDraftChangeDebtFreeDate,
    variant,
    setVariant,
    optionsChoice,
    setOptionsChoice,
    optionNewDeposit,
    setOptionNewDeposit,
    additionalDeposit,
    setAdditionalDeposit,
    dfd,
    calculateGradDate,
    prepareChangeSubmission,
    canModifyDeposits,
    canModify,
    setCanModify,
    track,
    lastSteps,
    setLastSteps,
    previousStep
  };
}
