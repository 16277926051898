import { axiosInstance as axios } from 'lib/axios';

import envConfig from 'envConfig';

export const updateClientInfo = async ({
  mailingStreet,
  mailingState,
  mailingPostalCode,
  mailingCity,
  phone,
  mobilePhone,
  clientEmail2,
  settlementAuthSMS,
  transactionalSMS,
  marketingSMS
}) => {
  const apiUrl = `${envConfig.FIREBASE_FUNCTION_HOSTNAME}/updateClientInfo`;

  try {
    const response = await axios.put(
      apiUrl,
      {
        mailingStreet,
        mailingState,
        mailingPostalCode,
        mailingCity,
        phone,
        mobilePhone,
        clientEmail2: clientEmail2?.toLowerCase() || '',
        settlementAuthSMS,
        transactionalSMS,
        marketingSMS
      },
      {
        validateStatus: (status) => status >= 200 && status < 500
      }
    );
    return response;
  } catch (e) {
    throw e;
  }
};

export default updateClientInfo;
