import React, { useState } from 'react';
import * as Yup from 'yup';
import { Composition } from 'atomic-layout';
import { Formik, Form } from 'formik';
import styled from 'styled-components';
import 'styled-components/macro';
import { Button, Loader } from '@ffn/ui';
import { ArrowRight } from '@ffn/icons';

import { CustomPanel } from '../CustomTabs/index.js';
import Dropzone from '../DocumentUpload/shared-components/Dropzone.js';
import {
  DashDatePicker,
  DashSelect,
  DashInput
} from 'shared-components/DashFields';
import {
  AttachedFiles,
  SuccessHeading,
  ErrorHeading
} from '../DocumentUpload/shared-components';

import { onSubmit } from '../DocumentUpload/submitHandlers.js';
import { useSelector } from 'react-redux';
import { analyticsTrackEvent } from 'lib/utils/analytics.js';
import { useTranslation } from 'lib/hooks';

const DashContainer = styled.div`
  margin-bottom: 4px;
`;

const FormWrapper = styled.div`
  input,
  select {
    width: 50%;
  }

  input {
    margin-bottom: 8px;
  }

  #accountType {
    background: #fff;
  }

  @media (max-width: 991px) {
    input,
    select {
      width: 100%;
    }

    div {
      margin-bottom: 4px;
    }
  }
`;

const areas = `
    Description
    UploadFiles
    AddFileMetadata
  `;

export function EnrollNewDebt() {
  const { fdrApplicantId, lawFirmId, firstName, lastName, email, csClientId } =
    useSelector((state) => state.userRecord);
  const userInfo = {
    fdrApplicantId,
    lawFirmId,
    fullName: `${firstName} ${lastName}`,
    email,
    csClientId
  };

  const { t } = useTranslation();

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const handleSuccess = ({ actions, analyticsTrackEvent }) => {
    setSuccess(true);
    actions.setSubmitting(false);
    actions.resetForm();
    _handleSuccessEvent(analyticsTrackEvent);
  };

  const handleFailure = ({ e, analyticsTrackEvent }) => {
    const message = e.message || 'Submission failed!';
    _handleFailureEvent(analyticsTrackEvent);
    setError(message);
  };

  const resetErrorSuccessState = () => {
    setError(null);
    setSuccess(null);
  };

  const _handleSuccessEvent = (analyticsTrackEvent) => {
    analyticsTrackEvent(
      {
        category: 'document',
        action: 'success',
        label: 'document_upload'
      },
      'Enroll new debt success'
    );
  };

  const _handleFailureEvent = (analyticsTrackEvent) => {
    analyticsTrackEvent(
      {
        category: 'document',
        action: 'failure',
        label: 'document_upload'
      },
      'Enroll new debt failure'
    );
  };

  const handleSubmit = async (values, actions) => {
    setError(null);
    try {
      analyticsTrackEvent(
        {
          category: 'document',
          action: 'submitted',
          label: 'document_upload'
        },
        'Enroll new debt submitted and pending'
      );
      actions.setSubmitting(true);
      await onSubmit(values, actions, userInfo, true);
      handleSuccess({ actions, analyticsTrackEvent });
    } catch (e) {
      console.error(e);
      handleFailure({ e, analyticsTrackEvent });
    }
  };

  const enrollNewDebtYupSchema = Yup.object({
    creditorName: Yup.string()
      .strict()
      .trim(t('common.form.validationsAndErrors.noSpacesAtBeginningOrEnding'))
      .required(
        t('enrollNewDebt.creditorName') +
          ' ' +
          t('common.form.validationsAndErrors.isARequiredField')
      )
      .label(t('enrollNewDebt.creditorName')),
    currentBalance: Yup.string()
      .strict()
      .trim(t('common.form.validationsAndErrors.noSpacesAtBeginningOrEnding'))
      .matches(
        /^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{2})?$/,
        t('common.form.validationsAndErrors.mustBeANumber')
      )
      .required(
        t('enrollNewDebt.currentBalance') +
          ' ' +
          t('common.form.validationsAndErrors.isARequiredField')
      )
      .label(t('enrollNewDebt.currentBalance')),
    accountNumber: Yup.string()
      .strict()
      .trim(t('common.form.validationsAndErrors.noSpacesAtBeginningOrEnding'))
      .matches(/^\d+$/, t('common.form.validationsAndErrors.mustBeANumber'))
      .required(
        t('enrollNewDebt.accountNumber') +
          ' ' +
          t('common.form.validationsAndErrors.isARequiredField')
      )
      .label(t('enrollNewDebt.accountNumber')),
    lastStatementDate: Yup.date()
      .required(
        t('enrollNewDebt.lastStatementDate') +
          ' ' +
          t('common.form.validationsAndErrors.isARequiredField')
      )
      .label(t('enrollNewDebt.lastStatementDate')),
    lastPaymentDate: Yup.date()
      .required(
        t('enrollNewDebt.lastPaymentDate') +
          ' ' +
          t('common.form.validationsAndErrors.isARequiredField')
      )
      .label(t('enrollNewDebt.lastPaymentDate')),
    accountType: Yup.string()
      .oneOf([
        'credit',
        'business-debt',
        'credit-union',
        'federal-credit-union',
        'deficiency-for-mortgage',
        'auto',
        'education',
        'medical',
        'law-firm',
        'unsecured-loan',
        'deficieny-other',
        'unknown'
      ])
      .required(
        t('enrollNewDebt.accountType') +
          ' ' +
          t('common.form.validationsAndErrors.isARequiredField')
      )
      .label(t('enrollNewDebt.accountType')),
    files: Yup.array().required(
      t('enrollNewDebt.accountStatementS') +
        ' ' +
        t('common.form.validationsAndErrors.isARequiredField')
    )
  });

  return (
    <div data-testid="enroll-new-debt">
      <Formik
        initialValues={{
          creditorName: '',
          currentBalance: '',
          accountNumber: '',
          lastStatementDate: '',
          lastPaymentDate: '',
          accountType: '',
          files: []
        }}
        validationSchema={enrollNewDebtYupSchema}
        onSubmit={handleSubmit}
      >
        {({
          values: { files, creditorName },
          isSubmitting,
          setFieldValue,
          resetForm,
          handleSubmit
        }) => {
          const resetFormAndErrorSuccessState = () => {
            resetForm();
            resetErrorSuccessState();
          };
          const isSuccessfulUpload =
            files.length &&
            files.every(({ status }) => status === 'submitted') &&
            !error;
          return (
            <CustomPanel
              heading={
                success ? (
                  <SuccessHeading
                    message={t('enrollNewDebt.yourRequestHasBeenSubmitted')}
                  />
                ) : error ? (
                  <ErrorHeading error={error.message} />
                ) : (
                  t('enrollNewDebt.enrollNewDebt')
                )
              }
            >
              <FormWrapper>
                <DashContainer>
                  <DashInput
                    id="creditorName"
                    name="creditorName"
                    label={t('enrollNewDebt.creditorName')}
                    className="form-input"
                    disabled={isSubmitting || isSuccessfulUpload}
                  />
                </DashContainer>
                <DashContainer>
                  <DashInput
                    id="currentBalance"
                    name="currentBalance"
                    label={t('enrollNewDebt.currentBalance')}
                    className="form-input"
                    disabled={isSubmitting || isSuccessfulUpload}
                  />
                </DashContainer>
                <DashContainer>
                  <DashInput
                    id="accountNumber"
                    name="accountNumber"
                    label={t('enrollNewDebt.accountNumber')}
                    className="form-input"
                    disabled={isSubmitting || isSuccessfulUpload}
                  />
                </DashContainer>
                <DashContainer>
                  <DashDatePicker
                    id="lastStatementDate"
                    name="lastStatementDate"
                    label={t('enrollNewDebt.lastStatementDate')}
                    className="form-input"
                    maxDate={new Date()}
                    excludeOutOfBoundsTimes
                    disabled={isSubmitting || isSuccessfulUpload}
                  />
                </DashContainer>
                <DashContainer>
                  <DashDatePicker
                    id="lastPaymentDate"
                    name="lastPaymentDate"
                    label={t('enrollNewDebt.lastPaymentDate')}
                    className="form-input"
                    maxDate={new Date()}
                    excludeOutOfBoundsTimes
                    disabled={isSubmitting || isSuccessfulUpload}
                  />
                </DashContainer>
                <DashContainer>
                  <DashSelect
                    id="accountType"
                    name="accountType"
                    label={t('enrollNewDebt.accountType')}
                    className="form-input"
                    disabled={isSubmitting || isSuccessfulUpload}
                  >
                    <option defaultValue="Select Account Type" hidden>
                      {t('enrollNewDebt.selectAccountType')}
                    </option>
                    <option value="credit">{t('enrollNewDebt.credit')}</option>
                    <option value="business-debt">
                      {t('enrollNewDebt.businessDebt')}
                    </option>
                    <option value="credit-union">
                      {t('enrollNewDebt.creditUnion')}
                    </option>
                    <option
                      value="federal-credit-union
                    "
                    >
                      {t('enrollNewDebt.federalCreditUnion')}
                    </option>
                    <option
                      value="deficiency-for-mortgage
                    "
                    >
                      {t('enrollNewDebt.deficiencyForMortgage')}
                    </option>
                    <option value="auto">{t('enrollNewDebt.auto')}</option>
                    <option value="education">
                      {t('enrollNewDebt.education')}
                    </option>
                    <option value="medical">
                      {t('enrollNewDebt.medical')}
                    </option>
                    <option value="law-firm">
                      {t('enrollNewDebt.lawFirm')}
                    </option>
                    <option value="unsecured-loan">
                      {t('enrollNewDebt.unsecuredLoan')}
                    </option>
                    <option value="deficieny-other">
                      {t('enrollNewDebt.deficiencyOther')}
                    </option>
                    <option value="unknown">
                      {t('enrollNewDebt.unknown')}
                    </option>
                  </DashSelect>
                </DashContainer>
              </FormWrapper>
              <Composition
                as={Form}
                areas={isSuccessfulUpload ? 'SuccessWindow' : areas}
                gap={8}
                autoComplete="off"
              >
                {({
                  Description,
                  UploadFiles,
                  AddFileMetadata,
                  SuccessWindow
                }) => {
                  return (
                    <div>
                      <Description style={{ padding: '10px 0' }}>
                        {t('enrollNewDebt.pleaseProvideAStatement')}
                      </Description>
                      <UploadFiles>
                        <Dropzone
                          document_type={'statement_of_account'}
                          doctypeTitle={t('enrollNewDebt.accountStatementS')}
                          setUploadedFiles={(acceptedFiles) =>
                            setFieldValue('files', files.concat(acceptedFiles))
                          }
                          css={'background-color: none;'}
                          files={files}
                        />
                      </UploadFiles>
                      <AddFileMetadata>
                        <AttachedFiles
                          uploadedFiles={files}
                          disclaimer={false}
                        />
                      </AddFileMetadata>
                    </div>
                  );
                }}
              </Composition>
              <DashContainer>
                <DashInput
                  id="files"
                  name="files"
                  style={{ display: 'none' }}
                />
              </DashContainer>
              <Button
                data-testid="enroll-new-debt-button"
                variant="primary"
                rightEnhancer={<ArrowRight size={10} css="margin-left: 6px;" />}
                marginTop={4}
                padding={12}
                type="submit"
                onClick={
                  isSuccessfulUpload
                    ? resetFormAndErrorSuccessState
                    : handleSubmit
                }
                disabled={isSubmitting}
              >
                {isSubmitting && <Loader />}{' '}
                {isSuccessfulUpload && !isSubmitting
                  ? `${t('enrollNewDebt.enrollAnotherDebt')}`
                  : `${t('enrollNewDebt.enrollNewDebt')}`}
              </Button>
            </CustomPanel>
          );
        }}
      </Formik>
    </div>
  );
}

export default EnrollNewDebt;
