export const stg = {
  FIREBASE_API_KEY: 'AIzaSyA0WcB6j1uxVFEKns9rdp2V8FmhYw_OY7M',
  FIREBASE_AUTH_DOMAIN: 'fdr-dashboard-stg.firebaseapp.com',
  FIREBASE_DATABASE_URL: 'https://fdr-dashboard-stg.firebaseio.com',
  FIREBASE_PROJECT_ID: 'fdr-dashboard-stg',
  FIREBASE_STORAGE_BUCKET: 'fdr-dashboard-stg.appspot.com',
  FIREBASE_MESSAGING_SENDER_ID: '242684244089',
  FIREBASE_FUNCTION_HOSTNAME:
    'https://us-central1-fdr-dashboard-stg.cloudfunctions.net',
  TEALIUM_ENV: 'stg',
  FFN_CX_API_URL: 'https://ffn-cx-api.stg.fdrgcp.com/api',
  AR_CX_API_URL: 'https://achieve.stg.ffngcp.com/dashboard/resolution/api',
  FDR_CX_API_URL: 'https://fdr.stg.fdrgcp.com/dashboard/api',
  ARP_CX_API_URL: 'https://turnbull-stg.programdashboard.com/api',
  ACHIEVE_ROOT_URL: 'https://achieve.stg.ffngcp.com',
  FDR_ROOT_URL: 'https://fdr.stg.fdrgcp.com',
  TURNBULL_ROOT_URL: 'https://turnbull-stg.programdashboard.com',
  AUTH0_FDR_DOMAIN: 'login-fdr.stg.fdrgcp.com',
  AUTH0_FDR_CLIENT_ID: 'sJLBKYUAEJYJJkE968H1E4m7RpdV2CQG',
  AUTH0_FDR_CALLBACK_URL: 'https://fdr.stg.fdrgcp.com/dashboard',
  AUTH0_FDR_AUDIENCE: 'https://auth.stg.ffngcp.com',
  AUTH0_FDR_ISSUER: 'https://login-fdr.stg.fdrgcp.com/',
  AUTH0_TURNBULL_DOMAIN: 'login-turnbull-stg.programdashboard.com',
  AUTH0_TURNBULL_CLIENT_ID: 'j5RRqKO9TTPUi8a3mDWQFXKohjPDDdiJ',
  AUTH0_TURNBULL_CALLBACK_URL: 'https://turnbull-stg.programdashboard.com/',
  AUTH0_TURNBULL_AUDIENCE: 'https://auth.stg.ffngcp.com',
  AUTH0_AR_DOMAIN: 'login-achieve-stg.ffngcp.com',
  AUTH0_AR_CLIENT_ID: 'zC1AdWEQrE5nrEUkxJO7FZN5irDUGTKS',
  AUTH0_AR_CALLBACK_URL: 'https://achieve.stg.ffngcp.com/dashboard/resolution',
  AUTH0_AR_AUDIENCE: 'https://auth.stg.ffngcp.com',
  UPSCOPE_PUBLIC_API_KEY: 'WhMqt6nFqZ',
  DATADOG_ACX_RUM_APP_ID: 'c51586c0-7b29-4f43-ac5f-b251dd061bfe',
  DATADOG_ACX_RUM_CLIENT_TOKEN: 'pub59d6ffd2be890b820fdb75473968e124',
  DATADOG_RUM_APP_ID: '01c9b8c0-b004-46d4-91d0-321b5dbc6fff',
  DATADOG_RUM_CLIENT_TOKEN: 'pub689ecf0ceb47a1ef5def4dfc2f1bac0b',
  DATADOG_APP_ENV: 'stg',
  DD_SERVICE: 'cx-fdr-client-dashboard',
  DD_SESSION_REPLAY_SAMPLE_RATE: '10',
  DD_RUM_ENABLED: 'true',
  GENESYS_ENVIRONMENT: 'prod-usw2',
  GENESYS_DEPLOYMENT_ID: '85b79203-40eb-48d9-95de-5285892a8115',
  GENESYS_REDIRECT_URL: 'https://fdr.stg.fdrgcp.com/dashboard',
  GENESYS_CLIENT_ID: 'olYiFJedzUmJ4vGumA3hgA4e5FeHRhRf',
  GENESYS_DEBUG_FLAG: 'true',
  ACHIEVE_GOOGLE_RECAPTCHA_SITE_KEY: '6LeJAbwpAAAAADrLP_R2JqDk1KdDjRu7iN0Zg0qL',
  FDR_GOOGLE_RECAPTCHA_SITE_KEY: '6LeJAbwpAAAAADrLP_R2JqDk1KdDjRu7iN0Zg0qL',
  TURNBULL_GOOGLE_RECAPTCHA_SITE_KEY:
    '6LeJAbwpAAAAADrLP_R2JqDk1KdDjRu7iN0Zg0qL',
  EVENT_HORIZON_DEBUG: 'true'
};
