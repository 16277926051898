import { axiosInstance as axios } from 'lib/axios';

import envConfig from 'envConfig';

export const draftModificationChange = async ({ allFeesData, dmfReason }) => {
  try {
    const endpoint = `${envConfig.FIREBASE_FUNCTION_HOSTNAME}/draftModification`;

    const sfObj = {
      draftModificationType: 'change',
      allFeesData
    };

    if (dmfReason) {
      sfObj.dmfReason = dmfReason;
    }

    const response = await axios.post(endpoint, sfObj, {
      validateStatus: (status) => status === 200
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      if (error.response.status === 404) {
        const error404 = new Error(error.response.data.error_message);
        error404.code =
          error.response.data.error_code ?? 'DRAFT_MODIFICATION_GENERAL_ERROR';
        throw error404;
      } else {
        throw new Error('DATA_INSERTS_ERROR');
      }
    } else {
      throw error;
    }
  }
};
