import React from 'react';
import { Composition } from 'atomic-layout';
import { useFormikContext } from 'formik';
import { Heading, Text, Icon } from '@ffn/ui';
import { Loader } from '@ffn/ui';
import { FieldArray } from 'formik';
import {
  MicrosoftWord,
  Excel,
  Pdf,
  HelpfulDoc,
  Trash,
  CheckmarkCircle,
  SystemError
} from '@ffn/icons';
import 'styled-components/macro';
import { useTranslation } from 'lib/hooks';
import i18next from 'i18next';

export function AttachedFiles({
  MetadataForm,
  uploadedFiles,
  disclaimer = true,
  disclaimerText = i18next.t('docUpload.attachedFilesDisclaimerText')
}) {
  const { isSubmitting } = useFormikContext();
  const { t } = useTranslation();

  return (
    <>
      {uploadedFiles.length ? (
        <>
          {disclaimer && (
            <>
              <Heading as="h2">
                {t('docUpload.additionalInformationRequired')}
              </Heading>
              <p>{disclaimerText}</p>
            </>
          )}
          <ul
            css={`
              list-style: none;
              padding: 0;
            `}
          >
            <Composition gap={16}>
              <FieldArray
                name="files"
                render={(arrayHelpers) => (
                  <>
                    {uploadedFiles.length &&
                      uploadedFiles.map((obj, index) => (
                        <li key={index}>
                          <Composition
                            templateRows="auto 1fr"
                            widthXs="100%"
                            widthLg="85%"
                          >
                            <CardHeading
                              filename={obj.document.path}
                              fileStatus={obj.status}
                              removeFile={() => arrayHelpers.remove(index)}
                            />
                            {MetadataForm ? (
                              <MetadataForm
                                currentFile={obj}
                                fileIndex={index}
                              />
                            ) : (
                              <Text
                                fileStatus={obj.status}
                                isSubmitting={isSubmitting}
                                css={`
                                  font-size: 13px;
                                  margin: 0px;
                                  padding: 0px 16px 16px;
                                  background-color: ${(props) =>
                                    props.theme.colors.plain};
                                  color: ${({
                                    fileStatus,
                                    isSubmitting,
                                    theme
                                  }) =>
                                    fileStatus === 'rejected' && !isSubmitting
                                      ? theme.colors.secondaryRed
                                      : fileStatus === 'submitted'
                                        ? theme.colors.secondaryGreen
                                        : theme.colors.primaryGray};
                                `}
                              >
                                <em>
                                  {obj.status === 'submitted'
                                    ? t('docUpload.successfullyUploaded')
                                    : obj.status === 'rejected' && !isSubmitting
                                      ? t('docUpload.failedToUpload')
                                      : t('docUpload.pendingUpload')}
                                </em>
                              </Text>
                            )}
                          </Composition>
                        </li>
                      ))}
                  </>
                )}
              />
            </Composition>
          </ul>
          <Text
            css={`
              font-size: 12px;
              margin: 0px;
            `}
          >
            {t('docUpload.128MBFileSizeLimit')}
          </Text>
        </>
      ) : (
        <Text
          css={`
            font-size: 12px;
            margin: 0px;
          `}
        >
          {t('docUpload.128MBFileSizeLimit')}
        </Text>
      )}
    </>
  );
}

function CardHeading({ filename, fileStatus, removeFile }) {
  const { isSubmitting } = useFormikContext();
  return (
    <Composition
      templateCols="24px 1fr 24px"
      autoFlow="column"
      gap={12}
      alignItems="center"
      padding="16px 16px"
      css={`
        background-color: white;
      `}
    >
      <Icon
        icon={
          filename.includes('.doc')
            ? MicrosoftWord
            : filename.includes('.pdf')
              ? Pdf
              : filename.includes('.xls')
                ? Excel
                : HelpfulDoc
        }
        size={24}
      />
      <Text
        css={`
          margin: 0;
        `}
      >
        {filename}
      </Text>
      {fileStatus === 'submitted' ? (
        <Icon
          icon={CheckmarkCircle}
          size={24}
          onClick={removeFile}
          css={`
            color: ${(props) => props.theme.colors.secondaryGreen};
            &:hover {
              cursor: pointer;
            }
          `}
        />
      ) : isSubmitting ? (
        <Loader
          css={`
            color: ${(props) => props.theme.colors.primaryBlue};
          `}
          size={24}
        />
      ) : fileStatus === 'rejected' || fileStatus === 'Failed SF update' ? (
        <Icon
          icon={SystemError}
          size={24}
          css={`
            color: ${(props) => props.theme.colors.secondaryRed};
          `}
        />
      ) : (
        <Icon
          data-testid="remove-file-icon"
          icon={Trash}
          size={24}
          onClick={removeFile}
          css={`
            &:hover {
              cursor: pointer;
            }
          `}
        />
      )}
    </Composition>
  );
}

export default AttachedFiles;
