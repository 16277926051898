import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useQuery } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { useWebviewAuth } from '@achieve/cx-auth';
import { extractFdrApplicantId, extractIdBundle } from 'lib/utils/auth0';
import { updateAuthHeader } from 'lib/axios';
import { updateFirestoreTimestamp } from 'lib/api/updateFirestoreUserRecord';
import { getAppSubDomain, logger } from 'shared-components';
import { BUSINESS_ID_MAP } from 'constants';

const LOGGING_PREFIX = 'useAuth ::';
const AUTH0_LOGIN_REQUIRED_ERROR = 'Login required';
// Number of retry attempts for token retrieval in case of errors other than 'Login required' before redirecting to login.
const TOKEN_RETRY_LIMIT = 2;

const AuthContext = createContext();
/**
 * AuthProvider manages user authentication state and exposes it via context.
 *
 * Key functionalities:
 * 1. Retrieves and caches the Auth0 access token, updating the axios library authorization header.
 * 2. Tracks and stores authenticated user details in sessionStorage.
 * 3. Provides an extended `logout` function for Auth0 and Genesys.
 * 4. Updates the 'lastUserLogin' timestamp in the user-record Firestore document.
 *
 * Other auth architecture:
 * - RouteGuard enforces route access and triggers getAccessTokenSilently call
 * - TokenRefresh manages token refresh include a custom callback
 * - Auth0Provider provides the auth0 context
 */
export const AuthProvider = ({ children }) => {
  const subdomain = getAppSubDomain();
  const businessId = BUSINESS_ID_MAP[subdomain] || '';

  // isAuthenticatedWithToken is set to true after getAccessTokenSilently retrieves a token
  const [isAuthenticatedWithToken, setIsAuthenticatedWithToken] =
    useState(false);

  const {
    isLoading: isLoadingWebviewToken,
    isWebview,
    accessToken: webviewAccessToken,
    isAuthenticated: isWebviewAuthenticated
  } = useWebviewAuth();
  const [fdrApplicantId, setFdrApplicantId] = useState(null);
  const [authenticationError, setAuthenticationError] = useState(null);

  const [validateAuthForProtectedRoute, setValidateAuthForProtectedRoute] =
    useState(false);

  // Memoize validateUserForProtectedRoutes to maintain a stable reference and prevent issues in useEffect dependencies. (RouteGuard)
  const validateUserForProtectedRoutes = useCallback(
    () => setValidateAuthForProtectedRoute(true),
    []
  );

  const auth0 = useAuth0();

  /**
   * IMPORTANT NOTE
   * AuthO's isAuthenticated indicates if the user has an active Autho session (e.g., via a cookie).
   * However, even if isAuthenticated is true, the app may not have retrieved an access token yet.
   * isAuthenticatedWithToken tracks whether the app has obtained the access token, which is necessary for API calls.
   * We export isAuthenticated (used by useAuth consumers) as isAuthenticatedWithToken to ensure
   * components rely on the presence of the token.
   */
  const { getAccessTokenSilently, isAuthenticated, isLoading, user, logout } =
    auth0;

  // function to sign user out of genesys and auth0
  const handleLogout = useCallback(
    (returnToPath = '') => {
      // Ensure returnToPath is a string. If handleLogout is passed directly to an onClick handler,
      // it might receive an event object instead of a string.
      if (typeof returnToPath !== 'string') {
        returnToPath = '';
      }
      // Determine the URL to redirect to after logging out.
      const returnTo =
        returnToPath && `${window.location.origin}${returnToPath}`;
      // If Genesys is available in the global scope, execute its logout command.
      if (typeof Genesys === 'function') {
        // eslint-disable-next-line no-undef
        Genesys('command', 'Auth.infoout');
      }

      return logout({
        returnTo
      });
    },
    [logout]
  );

  const getAccessToken = async () => {
    if (isWebviewAuthenticated) {
      return Promise.resolve(webviewAccessToken);
    } else {
      return await getAccessTokenSilently();
    }
  };

  /**
   * Retry helper function for token retrieval. No retries
   * for login required error and only 2 retries for other errors.
   */
  const shouldRetryTokenFetch = (count, error) => {
    if (error?.message === AUTH0_LOGIN_REQUIRED_ERROR) {
      return false;
    }
    // the lib logger may be unavailable
    logger.error(
      `${LOGGING_PREFIX} Token retrieval failed :: ${
        error.message || 'unknown error'
      }`
    );
    if (count < TOKEN_RETRY_LIMIT) {
      logger.info(`${LOGGING_PREFIX} Retry attempt ${count + 1}.`);
      return true;
    }
    return false;
  };

  const needsToGetAuth0Token = () => {
    if (isWebview) {
      return (
        !isLoadingWebviewToken &&
        validateAuthForProtectedRoute &&
        !isAuthenticatedWithToken
      );
    } else {
      return (
        !isLoading && validateAuthForProtectedRoute && !isAuthenticatedWithToken
      );
    }
  };

  /**
   * Fetches the Auth0 access token with retry logic.
   * - This should only run if:
   *    - a user is accessing a protected route (enabled by RouteGuard
   *      calling validateUserForProtectedRoutes)
   *    - useAuth0 (or useWebviewToken) is not in a loading state
   *    - the browser does not already have a token
   * - Retries up to 2 times with linear backoff for network issues.
   * - Skips retries for 'Login required' errors and redirects.
   * - On success: sets the token and updates the auth header.
   * - On failure: sets authentication error (which is consumed in RouteGuard)
   */
  useQuery(
    'auth0Token',
    async () => {
      return await getAccessToken();
    },
    {
      enabled: needsToGetAuth0Token(),
      retry: shouldRetryTokenFetch,
      retryDelay: (retryAttempt) => 500 + retryAttempt * 500,
      onSuccess: (auth0Token) => {
        setIsAuthenticatedWithToken(true);
        updateAuthHeader(auth0Token);
        setValidateAuthForProtectedRoute(false);
      },
      onError: (error) => setAuthenticationError(error)
    }
  );

  /**
   * - logs when a user authenticates
   * - places the auth0_user_id and the profile_id in sessionStorage
   * - the values are use in reporting
   */
  useEffect(() => {
    if (isAuthenticated && user) {
      logger.info(`${LOGGING_PREFIX} User is authenticated`);
      const idBundle = extractIdBundle(user);
      const stringifiedData = JSON.stringify(idBundle);
      sessionStorage.setItem('idBundle', stringifiedData);
    }

    return () => {
      sessionStorage.removeItem('idBundle');
    };
  }, [isAuthenticated, user]);

  /**
   * email and fdrApplicantId extracted and exposed for any use case where
   * the user record is not available (eg: a data issue related to the
   * user record or bootstrap). Utilized in reporting
   */
  const email = user?.email;

  useEffect(() => {
    if (user && businessId) {
      setFdrApplicantId(extractFdrApplicantId(user, businessId));
    }
  }, [user, businessId]);

  // updates the 'lastUserLogin' in firestore user-record document
  useQuery('updateFirestoreTimestamp', updateFirestoreTimestamp, {
    enabled: isAuthenticatedWithToken,
    retry: 2
  });

  /**
   * Note: We expose ‘isAuthenticated’ as ‘isAuthenticatedWithToken’ to ensure that components
   * relying on ‘useAuth’ can confirm both the user’s authentication status and the availability
   * of a valid access token.
   * See IMPORTANT NOTE at the Auth0 declaration for more details.
   */
  const value = useMemo(
    () => ({
      email,
      authenticationError,
      validateUserForProtectedRoutes,
      fdrApplicantId,
      isAuthenticated: isAuthenticatedWithToken,
      isLoading,
      user,
      logout: handleLogout
    }),
    [
      email,
      authenticationError,
      fdrApplicantId,
      validateUserForProtectedRoutes,
      handleLogout,
      isAuthenticatedWithToken,
      isLoading,
      user
    ]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
