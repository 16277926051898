import React, { useState, useContext, createContext } from 'react';
import { useSelector } from 'react-redux';
import { getLawFirmId } from 'shared-selectors/userRecord';
import { blogCategories } from 'lib/api';

const blogContext = createContext();
export function ProvideBlog({ children }) {
  const blogOptions = useProvideBlog();
  return (
    <blogContext.Provider value={blogOptions}>{children}</blogContext.Provider>
  );
}

export function useBlog() {
  return useContext(blogContext);
}

function useProvideBlog() {
  const [categories, setCategories] = useState(null);
  const lawFirmId = useSelector(getLawFirmId);

  async function getCategories() {
    if (lawFirmId && !categories) {
      const listing = await blogCategories({
        lawFirmId
      });
      setCategories(listing);
    }
  }

  return {
    getCategories,
    categories
  };
}
