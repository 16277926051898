import React, { useState } from 'react';
import 'styled-components/macro';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Icon, LoadingButton, FormMessage } from '@ffn/ui';
import { ChevronThinRight } from '@styled-icons/entypo/ChevronThinRight';
import { DashForm, DashInput } from 'shared-components';

import { alertResolve, refreshNotifications } from 'lib/api';
import { analyticsTrackEvent } from 'lib/utils/analytics';
import { useTranslation } from 'lib/hooks';

const simpleAccountNumberRegex = /^[\d-]{1,19}$/g;

export function AccountNumberChangeForm({ accountId, successCallback }) {
  const { t } = useTranslation();
  const [error, setError] = useState(null);

  const errorMessages = {
    DUPLICATE_ACCOUNT_NUMBER: t(
      'alerts.accountNumberVerification.weAreUnableToMake'
    ),
    INVALID_ACCOUNT_NUMBER: t(
      'alerts.accountNumberVerification.invalidAccountNumber'
    ),
    UNHANDLED_ERROR: t('alerts.accountNumberVerification.somethingWentWrong')
  };

  const handleError = (err) => {
    analyticsTrackEvent(
      {
        category: 'alert',
        action: 'failure',
        label: 'ani'
      },
      'Account Number Change Failure'
    );

    setError({ message: err.error_code ? err.error_code : err.message });
  };
  return (
    <>
      {error ? (
        <FormMessage variant="error">
          {errorMessages[error.message] || errorMessages.UNHANDLED_ERROR}
        </FormMessage>
      ) : null}
      <Formik
        initialValues={{
          accountNumber: '',
          confirmAccountNumber: ''
        }}
        validationSchema={yup.object().shape({
          accountNumber: yup
            .string()
            .matches(
              simpleAccountNumberRegex,
              t('alerts.accountNumberVerification.accountNumberMustBeNumeric')
            )
            .required(
              t('alerts.accountNumberVerification.accountNumberIsRequired')
            ),
          confirmAccountNumber: yup
            .string()
            .matches(
              simpleAccountNumberRegex,
              t('alerts.accountNumberVerification.accountNumberMustBeNumeric')
            )
            .oneOf(
              [yup.ref('accountNumber')],
              t('alerts.accountNumberVerification.accountNumbersDoNotMatch')
            )
            .required(
              t(
                'alerts.accountNumberVerification.confirmAccountNumberIsRequired'
              )
            )
        })}
        onSubmit={async (values) => {
          const payload = {
            alertType: 'alert_ani',
            account_id: accountId,
            account_number: values.accountNumber
          };

          setError(null);

          try {
            /**
             * Expected response will look like this:
              {
                  "success_message": null,
                  "success": false,
                  "sessionid": null,
                  "recordid": null,
                  "error_type": null,
                  "error_message": "Invalid Account Number",
                  "error_code": "INVALID_ACCOUNT_NUMBER"
              }
             * And we need to check the `success` field to see if the request actually succeeded.
            */
            const res = await alertResolve(payload);

            if (res.success) {
              // Wait 7.5 seconds and then refresh notifications data in Firestore, which
              // should clear any notifications that prompted the user to take this action.
              setTimeout(() => {
                refreshNotifications(['accounts']);
              }, 7500);

              analyticsTrackEvent(
                {
                  category: 'alert',
                  action: 'success',
                  label: 'ani'
                },
                'Account Number Change Success'
              );

              successCallback();
            } else {
              handleError(res);
            }
          } catch (error) {
            console.error(error);
            handleError(error);
          }
        }}
      >
        {({ values, isValid, isSubmitting }) => (
          <DashForm>
            <DashInput
              id="accountNumber"
              name="accountNumber"
              label={t('alerts.accountNumberVerification.updatedAccountNumber')}
              value={values.accountNumber}
              data-testid="updatedAccountNumberInput"
            />
            <DashInput
              id="confirmAccountNumber"
              name="confirmAccountNumber"
              label={t(
                'alerts.accountNumberVerification.reEnterUpdatedAccountNumber'
              )}
              value={values.confirmAccountNumber}
              data-testid="updatedAccountNumberConfirmationInput"
            />
            <LoadingButton
              type="submit"
              variant="primary"
              height={40}
              width="100%"
              rightEnhancer={<Icon icon={ChevronThinRight} size={14} />}
              isLoading={isSubmitting}
              disabled={!isValid || isSubmitting}
            >
              {t('alerts.accountNumberVerification.update')}
            </LoadingButton>
          </DashForm>
        )}
      </Formik>
    </>
  );
}

export default AccountNumberChangeForm;
