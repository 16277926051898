import { axiosInstance as axios } from 'lib/axios';

import envConfig from 'envConfig';

export const statements = async ({ provider, period, id }) => {
  const apiUrl = `${envConfig.FIREBASE_FUNCTION_HOSTNAME}/statements/${provider}?period=${period}&id=${id}`;

  try {
    const response = await axios.get(apiUrl, {
      validateStatus: (status) => status >= 200 && status < 500,
      responseType: 'blob'
    });

    const blob = new Blob([response.data], {
      type: 'application/pdf'
    });
    return blob;
  } catch (e) {
    throw e;
  }
};

export default statements;
