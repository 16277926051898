import React, { useState } from 'react';
import { useFormikContext, Field } from 'formik';
import { Box, Composition } from 'atomic-layout';
import { getNextEffectiveDate } from 'shared-selectors/omittedAccounts';
import { useSelector } from 'react-redux';
import { SelectTable, FormInput, Link, Icon, LoadingButton } from '@ffn/ui';
import { Currency } from 'shared-components';
import EFTAModal from 'shared-components/EFTAModal';
import { format } from 'date-fns/fp';
import { ChevronThinRight } from '@styled-icons/entypo/ChevronThinRight';
import { useBrand } from 'lib/hooks';
import { getTestIds } from 'lib/utils/dataTestIds';
import { useTranslation } from 'lib/hooks';

const testIds = getTestIds('add_account_opportunity');

export function Review({
  client,
  sharedColumns,
  omittedAccounts,
  alertId,
  setStep,
  setError,
  selectedAccounts,
  unSelectedReasons
}) {
  const { t } = useTranslation();
  const { isSubmitting, submitForm, values } = useFormikContext();
  const nextDraftDate = useSelector(getNextEffectiveDate);
  const userRecord = useSelector((state) => state.userRecord);
  const brand = useBrand();
  const [eftaModalOpen, setEftaModalOpen] = useState(false);
  const addedAccounts = omittedAccounts.filter((acct) => {
    return selectedAccounts.includes(acct.omittedAccountId);
  });
  const declinedAccounts = omittedAccounts.filter((acct) => {
    return !selectedAccounts.includes(acct.omittedAccountId);
  });
  const creditor = sharedColumns.originalCreditor
    ? sharedColumns.originalCreditor
    : sharedColumns.creditor;
  const addAccountsColumns = React.useMemo(
    () => [
      creditor,
      sharedColumns.accountNumber,
      sharedColumns.currentBalance,
      sharedColumns.addedAccountDraftsIncrease
    ],
    [sharedColumns, creditor]
  );
  const declinedAccountsColumns = React.useMemo(
    () => [creditor, sharedColumns.accountNumber, sharedColumns.currentBalance],
    [sharedColumns, creditor]
  );

  const addedAccountsDraftsIncrease =
    addedAccounts !== undefined
      ? addedAccounts.map((draft) => {
          return {
            increase: draft.addedAccountDraftsIncrease
          };
        })
      : [];
  const draftIncreaseAmount = addedAccountsDraftsIncrease.reduce(
    (sum, draftFee) => sum + parseFloat(draftFee.increase),
    0
  );
  const newDepositAmount = draftIncreaseAmount + client.monthlyDraftsFeeAmount;
  const draftType = client.draftType || '';

  return (
    <>
      <p>{t('alerts.addAccountOpportunity.toCompleteThisRequest')}</p>
      <Box paddingVertical={12}>
        {addedAccounts && addedAccounts.length !== 0 && (
          <Box marginBottom={24} data-testid="added-accounts-table">
            <strong>
              {t('alerts.addAccountOpportunity.theFollowingAccountS')}:
            </strong>
            <Box marginTop={8}>
              <SelectTable
                columns={addAccountsColumns}
                data={addedAccounts}
                showHeaders={true}
              />
            </Box>
          </Box>
        )}
        {declinedAccounts && declinedAccounts.length !== 0 && (
          <Box data-testid="declined-accounts-table">
            <strong>
              {t('alerts.addAccountOpportunity.youHaveChosenNot')}:
            </strong>
            <Box marginTop={8}>
              <SelectTable
                columns={declinedAccountsColumns}
                data={declinedAccounts}
                showHeaders={true}
              />
            </Box>
          </Box>
        )}
      </Box>
      {addedAccounts && addedAccounts.length !== 0 && (
        <Box>
          <p>{t('alerts.addAccountOpportunity.inOrderToEnrollNewAccounts')}:</p>
          <Box paddingVertical={16} style={{ fontWeight: 600 }}>
            <Composition widthMd="40%" gap={16}>
              <Currency
                displayType="input"
                customInput={FormInput}
                readOnly
                label={t('alerts.addAccountOpportunity.newDepositAmount')}
                value={newDepositAmount}
              />
              <FormInput
                readOnly
                label={t('alerts.addAccountOpportunity.effectiveDate')}
                value={format('MM/dd/yy', nextDraftDate)}
              />
              <FormInput
                readOnly
                label={t('alerts.addAccountOpportunity.depositFrequency')}
                value={t('onboarding.content.bankingInfo', draftType)}
              />
            </Composition>
          </Box>
          <LegalDisclaimer
            isFDR={userRecord && userRecord?.app.toLowerCase() === 'fdr'}
          />
          <label
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              marginTop: '4px'
            }}
          >
            <Box
              style={{
                height: 18,
                width: 18,
                margin: '0px 16px 0px 0px'
              }}
            >
              <Field
                data-testid={testIds.ach_checkbox}
                type="checkbox"
                name="authorize"
                style={{ height: 18, width: 18 }}
              />
            </Box>
            <span
              css={`
                font-size: 13px;
                font-weight: 400;
              `}
            >
              {t('alerts.addAccountOpportunity.iAuthorize')}{' '}
              {brand('business-name.legal')}{' '}
              {t(
                'alerts.addAccountOpportunity.toScheduleOngoingAchDebtFromMyAccount'
              )}
            </span>
          </label>
          <label
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              marginButtom: '4px'
            }}
          >
            <Box
              style={{
                height: 18,
                width: 18,
                margin: '0px 16px 0px 0px'
              }}
            >
              <Field
                data-testid={testIds.efta_checkbox}
                type="checkbox"
                name="EFTA"
                style={{ height: 18, width: 18 }}
              />
            </Box>
            <span
              css={`
                font-size: 13px;
                font-weight: 400;
              `}
            >
              {t('alerts.addAccountOpportunity.iHaveReadAndAgreeToThe')}{' '}
              <Link
                onClick={(event) => {
                  event.preventDefault();
                  setEftaModalOpen(true);
                }}
              >
                {t(
                  'alerts.addAccountOpportunity.electronicFundsTransferAuthorization'
                )}
              </Link>
            </span>
          </label>
        </Box>
      )}
      <EFTAModal
        isOpen={eftaModalOpen}
        closeModal={() => setEftaModalOpen(false)}
      />

      <Box flex justifyContent="space-between" paddingVertical={12} gap={16}>
        <Box flex flexDirection="column">
          <strong>{t('alerts.addAccountOpportunity.questions')}</strong>
          <Link href={`tel:${brand('contact.customer-service.phone')}`}>
            {t('alerts.addAccountOpportunity.contactCustomerService')}
          </Link>
        </Box>
        <Box flex alignItems="center" gap={8}>
          <Link
            data-testid={testIds.cancel_complete}
            onClick={(evt) => {
              if (isSubmitting) {
                evt.preventDefault();
              } else {
                setStep('form');
              }
            }}
          >
            {t('alerts.addAccountOpportunity.cancel')}
          </Link>
          <LoadingButton
            data-testid={testIds.confirm_complete}
            isLoading={isSubmitting}
            disabled={isSubmitting}
            variant="primary"
            type="button"
            onClick={() => {
              if (
                selectedAccounts.length > 0 &&
                (values.EFTA === false || values.authorize === false)
              ) {
                setError(
                  t(
                    'alerts.addAccountOpportunity.pleaseCheckTheAchAuthorization'
                  )
                );
              } else {
                submitForm();
              }
            }}
            height={40}
            rightEnhancer={<Icon icon={ChevronThinRight} size={14} />}
          >
            {t('alerts.addAccountOpportunity.confirm')}
          </LoadingButton>
        </Box>
      </Box>
    </>
  );
}

// AS OF 01/2023, REQUIRED TO RENDER THIS FOR ALL FDR CLIENTS PER LEGAL
const LegalDisclaimer = ({ isFDR }) => {
  const { t } = useTranslation();
  return isFDR ? (
    <p data-testid="aao-review-disclaimer">
      {t(
        'alerts.addAccountOpportunity.noticeTheseDebtsMayHaveComeFromOneOfTwoSources'
      )}
    </p>
  ) : null;
};
